import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import StandardBox from '../../../components/StandardBox';

export default function AzureLicense() {
  const { id } = useParams();
  console.log(id);
  return (
    <Grid>
      <StandardBox title="License X" />
    </Grid>
  );
}
