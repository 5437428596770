import { Box, InputLabel } from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';
import StyledSwitch from '../../../../buttons/StyledSwitch';

export default function SwitchField({ keyName, value, setModifiedItem, labelName, sxedit }) {
  return (
    <Box sx={sxedit}>
      <InputLabel htmlFor="input">{labelName || keyName}</InputLabel>
      <StyledSwitch
        data-testid={`Switch${keyName}`}
        checked={!!value}
        onChange={(e, newValue) => {
          setModifiedItem(newValue, keyName);
        }}
      />
    </Box>
  );
}

SwitchField.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  setModifiedItem: PropTypes.func,
  labelName: PropTypes.string,
  sxedit: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

SwitchField.defaultProps = {
  keyName: '',
  value: false,
  setModifiedItem: () => {},
  labelName: '',
  sxedit: null
};
