import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Mobile from './Mobile';
import Desktop from './Desktop';

export default function StandardTable({ rows, columns, refColumn }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid sx={{ margin: '0px 10px 10px 10px' }}>
      {!isMobile ? (
        <Mobile rows={rows} columns={columns} refColumn={refColumn} />
      ) : (
        <Desktop rows={rows} columns={columns} refColumn={refColumn} />
      )}
    </Grid>
  );
}

StandardTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]))
  ).isRequired,
  refColumn: PropTypes.string.isRequired
};

StandardTable.defaultProps = {
  rows: []
};
