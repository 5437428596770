import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';
import DataList from '../../../../components/datalist/DataList';
import { formatColumnsForTable } from '../../../../components/datalist/ComponentsInTable';
import CustomizedPagination from '../../../../components/datalist/TablePagination';
import { styleToolbar } from '../../../../assets/style/views/UserPages';

export default function PopUpHistory({ title, action, items, open, handleClose }) {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);

  const columnOptions = [
    { name: 'codename', display: true },
    { name: 'desc', display: true, filter: true },
    { name: 'id', display: false }
  ];
  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];
    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map((rowIndex) => {
      return items[rowIndex === null ? 0 : rowIndex]?.id;
    });
    setTableSelectedRows(tempRows);
  };
  useEffect(() => {
    setSelectedIndexRows([]);
    setTableSelectedRows([]);
  }, [open]);

  return (
    <Dialog open={open} fullWidth data-testid="PopUpHistory">
      <DialogTitle>{title}</DialogTitle>
      <form>
        <DialogContent>
          <Grid
            item
            md={10}
            sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '2%' }}
          >
            <CustomizedPagination
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={styleToolbar}
            />
          </Grid>
          <DataList
            columns={formatColumnsForTable(['codename', 'desc'], items, columnOptions, t)}
            rows={items}
            page={page}
            rowsPerPage={rowsPerPage}
            disableCheckBox={false}
            setTableSelectedRows={setTableSelectedRowsRealData}
            selectedIndexRows={selectedIndexRows}
            isDisplayable={!!items}
            searchTerm=""
            setTotalItems={setTotalItems}
          />
          <Typography sx={{ margin: '5px' }}>
            {`${selectedIndexRows.length} ${t('selectedPermissions')}`}
          </Typography>
        </DialogContent>
      </form>
      <DialogActions>
        <CustomizedButton
          variant="contained"
          datatestid="cancelAction"
          onClick={() => {
            handleClose();
          }}
          text={t('cancel')}
        />
        <CustomizedButton
          variant="contained"
          datatestid="confirmAction"
          type="submit"
          onClick={() => {
            action(tableSelectedRows);
            handleClose();
          }}
          text={t('validate')}
          disabled={!selectedIndexRows.length}
        />
      </DialogActions>
    </Dialog>
  );
}

PopUpHistory.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]))
  ),
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

PopUpHistory.defaultProps = {
  items: []
};
