export const styleHeader = (theme, isPositionFixed) => ({
  position: isPositionFixed ? 'fixed' : 'relative',
  zIndex: '1',
  width: '-webkit-fill-available',
  flexWrap: 'wrap',
  padding: { xs: '15px', sm: '30px', md: '45px' },
  paddingTop: { xs: '10px', sm: '17.5px', md: '25px' },
  paddingBottom: { xs: '10px', sm: '20px', md: '35px' },
  borderBottom: `3px solid ${theme.palette.colorSecondaryLight}`,
  background: theme.palette.colorMainLight
});

export const styleHeaderTitleAndButton = {
  direction: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start'
};

export const styleTypographyHeader = (theme) => ({
  font: 'Nunito',
  fontStyle: 'normal',
  fontWeight: '400',
  color: theme.palette.colorBlack,
  fontSize: { xs: '12px', sm: '14px', md: '16px' }
});

export const styleTypographyHeaderBigger = (theme) => ({
  font: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '600',
  color: theme.palette.colorSoftBlack,
  fontSize: { xs: '20px', sm: '22px', md: '24px' }
});

export const styleHeaderSearchAndRefresh = {
  direction: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: { xs: '2px', sm: '12px', md: '17px' }
};
