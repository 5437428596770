import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import {
  deleteProvider,
  getAllProviders,
  getProviderDetails,
  selectProviders,
  selectSelectedProvider,
  updateProvider,
  getAllProvidersNames,
  providersNames
} from '../../../../redux/slices/Providers';
import PopupDeleteProvider from './PopupDeleteProvider';

import errorRedirection from '../../../../utils/errorRedirection';
import PopupAddProvider from './PopupAddProvider';
import BackOfficeDesktop from '../../../../components/BackOffice/BackOfficeDesktop';

import providersAttributes from '../../../../utils/fieldListProviders';

const formatProviders = (providers) => {
  if (providers?.length) {
    return providers.map(({ id, providerName: { name } }) => {
      return {
        id,
        name,
        onClick: async (dispatch) => {
          await dispatch(getProviderDetails(id));
        }
      };
    });
  }
  return [];
};

const formatFieldList = (selectedProviderAuth, selectedProviderName) => {
  return providersAttributes[selectedProviderName]?.fields?.map((providerField) => {
    return {
      keyName: providerField.fieldname,
      type: providerField.hideView ? 'password' : providerField.type,
      value: selectedProviderAuth?.[providerField.fieldname]
    };
  });
};

export default function Providers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const providers = formatProviders(useSelector(selectProviders));
  const providersNamesSelector = useSelector(providersNames);

  const selectedProviderRedux = useSelector(selectSelectedProvider);

  const [popupDelete, setPopupPopupDelete] = useState(false);
  const [popupAdd, setPopupAdd] = useState(false);

  const [modifiedProviderAuth, setModifiedProviderAuth] = useState({});

  const isItemDisplayable = Object.keys(modifiedProviderAuth).length;

  const isItemModified =
    selectedProviderRedux?.providerAuth === JSON.stringify(modifiedProviderAuth);

  const setNewProviderAuth = (newValue, keyName) => {
    setModifiedProviderAuth({
      ...modifiedProviderAuth,
      [keyName]: newValue
    });
  };

  const dispatchGetProviders = async () => {
    try {
      await dispatch(getAllProviders()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const dispatchGetProvidersNames = async () => {
    await dispatch(getAllProvidersNames());
  };

  const handleDeletePopup = () => setPopupPopupDelete(true);
  const handleAddPopup = () => setPopupAdd(true);
  const dispatchDeleteProvider = async (id) => {
    await dispatch(deleteProvider(id));
  };

  const dispatchUpdateProvider = async (id, providerNameId, providerAuth) => {
    await dispatch(
      updateProvider({
        id,
        body: { providerNameId, providerAuth: JSON.stringify(providerAuth) }
      })
    );
  };
  const handleClickCancelButton = () => {
    setModifiedProviderAuth(JSON.parse(selectedProviderRedux?.providerAuth));
  };

  const handleClickUpdateButton = () => {
    dispatchUpdateProvider(
      selectedProviderRedux.id,
      selectedProviderRedux?.providerName?.id,
      modifiedProviderAuth
    );
  };

  useEffect(() => {
    dispatchGetProviders();
    dispatchGetProvidersNames();
  }, [dispatch]);

  useEffect(() => {
    if (selectedProviderRedux?.providerAuth) {
      setModifiedProviderAuth(JSON.parse(selectedProviderRedux?.providerAuth));
    } else {
      setModifiedProviderAuth({});
    }
  }, [selectedProviderRedux]);

  return (
    <Grid>
      <PopupDeleteProvider
        openPop={popupDelete}
        onClose={() => setPopupPopupDelete(false)}
        onClickValidate={() => dispatchDeleteProvider(selectedProviderRedux?.id)}
        providerName={selectedProviderRedux?.providerName?.name}
        data-testid="popupDeleteAzureGroup"
      />
      <PopupAddProvider
        openPop={popupAdd}
        onClose={() => setPopupAdd(false)}
        providersNames={providersNamesSelector}
        dispatchGetProviders={dispatchGetProviders}
      />
      <BackOfficeDesktop
        isItemDisplayable={!!isItemDisplayable}
        listItems={providers}
        selectedItemId={selectedProviderRedux?.id}
        fieldList={formatFieldList(modifiedProviderAuth, selectedProviderRedux?.providerName?.name)}
        selectedItemName={selectedProviderRedux?.providerName?.name}
        setModifiedItem={setNewProviderAuth}
        isItemModified={isItemModified}
        handleAddPopup={handleAddPopup}
        handleClickCancelButton={handleClickCancelButton}
        handleClickUpdateButton={handleClickUpdateButton}
        handleDeletePopup={handleDeletePopup}
        titleBodyName={t('manageProviders')}
        titleHeader={t('Providers')}
        nameButton={t('addProvider')}
      />
    </Grid>
  );
}
