export const styleColumnText = (theme) => ({
  fontSize: '20px',
  color: theme.palette.colorSoftBlack,
  fontWeight: 'bold',
  width: '200px',
  textAlign: 'left'
});

export const styleRowText = (theme) => ({
  fontSize: '20px',
  color: theme.palette.colorSoftBlack,
  borderBottom: 'none',
  width: '200px',
  textAlign: 'left'
});

export const styleRowHeaderTab = (theme) => ({
  backgroundColor: theme.palette.colorMainLight
});

export const styleRowBodyTab = (theme) => ({
  backgroundColor: theme.palette.colorMainLight
});

export const styleButtonPreview = (theme) => ({
  width: { xs: '160px', md: '150px' },
  height: '42px',
  color: theme.palette.colorMainLight,
  backgroundColor: theme.palette.colorMainSelected,
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.colorMainSelected,
    transform: 'scale(0.95, 0.95)'
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    background: theme.palette.colorMain
  }
});

export const styleRowGrid = {
  margin: '5px 0px 5px 0px',
  padding: '5px 5px 0px 30px'
};

export const styleEllipsis = {
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const styleInformationNameText = (theme) => ({
  color: theme.palette.colorSoftBlack
});
