import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import AzureGroupsService from '../../services/azureGroups/AzureGroupsService';
import AzureGroupsSuperAdminService from '../../services/superAdmin/AzureGroupsSuperAdminService';

export const initialState = {
  azureGroups: [],
  selectedAzureGroup: {},
  isLoading: false,
  updatedAt: 're',
  status: 'fulfilled',
  domains: [],
  azureGroupsGraph: [],
  azureGroupsUsers: []
};

export const getAllAzureGroups = createAsyncThunk('azureGroups/list', async () => {
  const res = await AzureGroupsService.getAzureGroups();
  return res.data;
});

export const getAzureGroupsDetails = createAsyncThunk('azureGroups/id', async (id) => {
  const res = await AzureGroupsService.getAzureGroupById(id);
  return res.data;
});

export const updateAzureGroups = createAsyncThunk('azureGroups/update/id', async (payload) => {
  const res = await AzureGroupsService.updateAzureGroups(payload);
  return res.data;
});
export const deleteAzureGroup = createAsyncThunk('azureGroups/delete', async (payload) => {
  const res = await AzureGroupsService.deleteAzureGroups(payload);
  return res.data;
});
export const addAzureGroup = createAsyncThunk('azureGroups/add', async (payload) => {
  const res = await AzureGroupsService.addAzureGroups(payload);
  return res.data;
});

export const getDomains = createAsyncThunk('azureGroups/getDomains', async () => {
  const res = await AzureGroupsService.getDomains();
  return res.data;
});

export const getAzureGroupsGraph = createAsyncThunk('azureGroups/get/list', async () => {
  const res = await AzureGroupsService.getAzureGroupsGraph();
  return res.data;
});

export const assignAzureGroupsUser = createAsyncThunk(
  'assignAzureGroupsUser',
  async ({ groups, users }) => {
    const res = await AzureGroupsSuperAdminService.assignAzureGroupsUsers(groups, users);
    return res.data;
  }
);

export const removeAzureGroupsUser = createAsyncThunk(
  'removeAzureGroupsUser',
  async ({ groups, users }) => {
    const res = await AzureGroupsSuperAdminService.removeAzureGroupsUsers(groups, users);
    return res.data;
  }
);

export const azureGroupsSlice = createSlice({
  name: 'azureGroups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAzureGroups.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAllAzureGroups.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.azureGroups = action.payload.azureGroups.sort((a, b) => a.id - b.id);
      })
      .addCase(getAllAzureGroups.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(getAzureGroupsDetails.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAzureGroupsDetails.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedAzureGroup = {
          ...action.payload.azureGroups,
          domains: action.payload.azureGroups?.azureGroup
            ?.map((el) => ({ id: el?.azureDomain?.id, name: el?.azureDomain?.name }))
            .sort((a, b) => a.id - b.id),
          azureGroupPermFrom: action.payload.azureGroups?.azureGroupPermFrom?.map((el) => ({
            id: el.azureGroupIdPermTo,
            name: state.azureGroups
              .filter((azureGroup) => azureGroup.id === el.azureGroupIdPermTo)
              .map(({ name }) => name)[0]
          }))
        };
      })
      .addCase(getAzureGroupsDetails.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(updateAzureGroups.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(updateAzureGroups.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const tempAzureGroups = state.azureGroups;
        state.azureGroups = tempAzureGroups.map((azureGroup) => {
          if (azureGroup.id === action.meta.arg?.id) {
            return { ...azureGroup, name: action.meta.arg?.name };
          }
          return azureGroup;
        });
        state.selectedAzureGroup = action.meta.arg;
      })
      .addCase(updateAzureGroups.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(deleteAzureGroup.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(deleteAzureGroup.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedAzureGroup = {};
        const tempAzureGroups = [...state.azureGroups].filter(
          (azureGroup) => azureGroup.id !== action.meta.arg
        );
        state.azureGroups = tempAzureGroups;
      })
      .addCase(deleteAzureGroup.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(addAzureGroup.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(addAzureGroup.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.azureGroups.push(action.payload.createdAzureGroup);
        state.selectedAzureGroup = {
          ...action.payload.createdAzureGroup,
          azureGroupPermFrom: [],
          domains: []
        };
      })
      .addCase(addAzureGroup.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(getDomains.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getDomains.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.domains = action.payload.domains;
      })
      .addCase(getDomains.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(getAzureGroupsGraph.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAzureGroupsGraph.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.azureGroupsGraph = action.payload.azureGroupsGraph;
      })
      .addCase(getAzureGroupsGraph.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const selectAzureGroups = (state) => state?.azureGroups?.azureGroups;

export const selectAzureGroupsGraph = (state) => state?.azureGroups?.azureGroupsGraph;
export const selectAzureGroupsGraphIsLoading = (state) =>
  state?.azureGroups?.azureGroupsGraph?.isLoading;

export const selectedAzureGroup = (state) => state.azureGroups?.selectedAzureGroup;

export const selectDomains = (state) => state.azureGroups?.domains;

export default azureGroupsSlice.reducer;
