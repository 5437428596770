import { Autocomplete, Box, Button, InputAdornment, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StyledSwitch from '../../../../buttons/StyledSwitch';

export default function ObjectField({ keyName, value, setModifiedItem }) {
  return (
    <Box sx={{ marginBottom: '25px' }} key={keyName}>
      <InputLabel htmlFor="input">{keyName}</InputLabel>
      <Box
        sx={(theme) => ({
          marginBottom: '25px',
          border: `3px solid ${theme.palette.colorMainLightHover}`,
          padding: '5px',
          borderRadius: '8px'
        })}
      >
        {Object.keys(value).length &&
          Object.keys(value).map((objectKey) => {
            if (typeof value[objectKey] === 'boolean')
              return (
                <Box key={keyName + objectKey}>
                  <InputLabel htmlFor="input">{objectKey}</InputLabel>
                  <StyledSwitch
                    checked={value[objectKey]}
                    onChange={(e, newValue) => {
                      setModifiedItem({ ...value, [objectKey]: newValue }, keyName);
                    }}
                  />
                </Box>
              );
            if (typeof value[objectKey] === 'object' && Array.isArray(value[objectKey])) {
              const [newElement, setNewElement] = useState('');

              return (
                <Box key={keyName + objectKey}>
                  <InputLabel htmlFor="input">{objectKey}</InputLabel>{' '}
                  <Autocomplete
                    multiple
                    fullWidth
                    value={value[objectKey] || ''}
                    options={value[objectKey]}
                    filterOptions={() => []}
                    onInputChange={(e) => {
                      setNewElement(e?.target?.value);
                    }}
                    onChange={(e, newValue) => {
                      setModifiedItem({ ...value, [objectKey]: newValue }, keyName);
                    }}
                    noOptionsText={
                      <Box>
                        <Button
                          disabled={
                            !newElement ||
                            newElement.length < 1 ||
                            value[objectKey].includes(newElement)
                          }
                          onClick={() => {
                            const newList = [...value[objectKey]];
                            newList.push(newElement);
                            setModifiedItem({ ...value, [objectKey]: newList }, keyName);
                          }}
                        >
                          Add New
                        </Button>
                      </Box>
                    }
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        variant="standard"
                      />
                    )}
                  />
                </Box>
              );
            }
            return (
              <Box key={keyName + objectKey}>
                <InputLabel htmlFor="input">{objectKey}</InputLabel>
                <TextField
                  required
                  name="password"
                  autoComplete="password"
                  value={value[objectKey]}
                  fullWidth
                  onChange={(e) => {
                    // eslint-disable-next-line no-restricted-globals
                    if (objectKey === 'sizeLimit' && !isNaN(Number(e.target.value))) {
                      setModifiedItem(
                        {
                          ...value,
                          [objectKey]: Number(e.target.value)
                        },
                        keyName
                      );
                    }
                    if (objectKey !== 'sizeLimit') {
                      setModifiedItem(
                        {
                          ...value,
                          [objectKey]: e.target.value
                        },
                        keyName
                      );
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon className="loginIcon" position="start" />
                      </InputAdornment>
                    )
                  }}
                  autoFocus
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}

ObjectField.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  setModifiedItem: PropTypes.func
};

ObjectField.defaultProps = {
  keyName: '',
  value: '',
  setModifiedItem: () => {}
};
