import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  findBoondResources,
  selectBoondResources,
  selectTotalResources,
  selectBoondResourcesIsLoading,
  selectColumns,
  selectIsTableLoaded,
  resetSelectedResource,
  refreshBoondResourcesList,
  selectBoondResourceUpdateTime
} from '../../../redux/slices/BoondManagerResources';

import ItemsList from '../ItemsList';
import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';

export default function BoondManagerResources() {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const dispatch = useDispatch();

  const updatedTime = useSelector(selectBoondResourceUpdateTime);
  const boondResourceColumns = useSelector(selectColumns);
  const boondResources = useSelector(selectBoondResources);
  const resourcesListIsLoading = useSelector(selectBoondResourcesIsLoading);
  const areBoondResourcesLoaded = useSelector(selectIsTableLoaded);

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map(
      (rowIndex) => boondResources[rowIndex === null ? 0 : rowIndex]?.id
    );
    setTableSelectedRows(tempRows);
  };

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  const handleRefresh = async () => {
    setPage(0);
    resetSelectedRows();
    dispatch(refreshBoondResourcesList());
  };

  useEffect(() => {
    dispatch(findBoondResources());
    return function cleanup() {
      dispatch(resetSelectedResource());
    };
  }, [dispatch]);
  const total = useSelector(selectTotalResources);
  useEffect(() => {
    setTotalItems(total);
  }, [total]);

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const columnOptions = [
    {
      name: 'firstName',
      type: 'LinkInTable',
      linkOptions: { linkColumn: 'firstName', linkTo: 'id' },
      display: true
    },
    { name: 'lastName', display: true, filter: true },
    { name: 'corporateMail', display: true, filter: true }
  ];

  const optionsKeys = {
    titleNameKey: 'firstName',
    secondaryTitleKey: 'corporateMail',
    statusKey: 'active'
  };

  const formatedColumns = formatColumnsForTable(
    boondResourceColumns,
    boondResources,
    columnOptions,
    t
  );

  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    buttons: [],
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: boondResources
    },

    loading: {
      itemsListIsLoading: resourcesListIsLoading,
      isTableDisplayable: areBoondResourcesLoaded
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date: updatedTime,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('boondResources'),
      bigTitleText: t('manageUsers'),
      selectedItemsText: t('selectedUsers'),
      tagId: 'BoondResources'
    },
    searchBarPlaceOlder: t('searchUser'),
    isDisableCheckBox: true
  };

  return (
    <Grid>
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
