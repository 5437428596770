import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsersAdminService from '../../services/admin/UsersAdminService';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';

export const initialState = {
  actions: [],
  updateAt: '',
  totalItems: 0,
  isLoading: false,
  status: 'fulfilled',
  columns: [
    'idDetails',
    'fromUserData',
    'providerName',
    'permissionName',
    'statusName',
    'createdAt',
    'deliveredAt'
  ],
  selectedAction: {}
};

export const requestAllActionsPagination = createAsyncThunk(
  'users/actions/list',
  async (payload) => {
    const res = await UsersAdminService.requestActionsList(payload.page, payload.rowsPerPage);
    return res.data;
  }
);

export const requestSearchedActionsPagination = createAsyncThunk(
  'users/actions/search',
  async (payload) => {
    const res = await UsersAdminService.requestSearchedActions(
      payload.searchTerm,
      payload.page,
      payload.rowsPerPage,
      payload.filterRequest
    );
    return res.data;
  }
);

export const getActionDetails = createAsyncThunk('users/actions', async (payload) => {
  const res = await UsersAdminService.requestActionsDetails(payload);
  return res.data;
});

export const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    resetActionsState: () => {
      return initialState;
    },
    setActionsListUpdateAt: (state, action) => {
      const dateFormatted = getDateTimeFormatted(Number(action.payload));
      state.updateAt = dateFormatted;
      state.isLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestAllActionsPagination.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(requestAllActionsPagination.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.actions = action.payload.actions?.map((actio) => ({
          ...actio,
          idDetails: String(actio.id),
          fromUserData: actio.fromUserData?.email,
          providerName: actio.auth_permission?.permissionProvider?.name,
          permissionName: actio.auth_permission?.codename,
          statusName: actio.status?.name,
          createdAt: actio.createdAt?.slice(0, 23)?.replace('T', ' '),
          deliveredAt: actio.deliveredAt?.slice(0, 23)?.replace('T', ' ')
        }));
        state.totalItems = action.payload.totalItems;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(requestAllActionsPagination.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(requestSearchedActionsPagination.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(requestSearchedActionsPagination.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.actions = action.payload.actions?.map((actio) => ({
          ...actio,
          idDetails: String(actio.id),
          fromUserData: actio.fromUserData?.email,
          providerName: actio.auth_permission?.permissionProvider?.name,
          permissionName: actio.auth_permission?.codename,
          statusName: actio.status?.name,
          createdAt: actio.createdAt?.slice(0, 23)?.replace('T', ' '),
          deliveredAt: actio.deliveredAt?.slice(0, 23)?.replace('T', ' ')
        }));
        state.totalItems = action.payload.totalItems;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(requestSearchedActionsPagination.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(getActionDetails.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(getActionDetails.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getActionDetails.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedAction = {
          ...action.payload.actions,
          fromUserData: action.payload.actions.fromUserData?.email,
          createdAt: action.payload.actions.createdAt?.slice(0, 23)?.replace('T', ' '),
          deliveredAt: action.payload.actions.deliveredAt?.slice(0, 23)?.replace('T', ' ')
        };
      });
  }
});

export const { resetActionsState, setActionsListUpdateAt } = actionsSlice.actions;
export const selectActions = (state) => state.actions.actions;
export const selectIsLoading = (state) => state.actions.isLoading;
export const selectUpdateAt = (state) => state.actions.updateAt;
export const selectTotalItems = (state) => state.actions.totalItems;
export const actionsColumns = (state) => state.actions.columns;
export const selectedAction = (state) => state.actions.selectedAction;

export const selectIsTableDisplayable = (state) => {
  if (state.actions.columns.length <= 0) {
    return false;
  }
  return true;
};

export default actionsSlice.reducer;
