export const styleMainContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItem: 'stretch'
};

export const styleHeader = (theme, isMobile) => ({
  boxSizing: 'border-box',
  borderBottom: `0.5px solid ${theme.palette.colorGray}`,
  backgroundColor: isMobile ? theme.palette.colorGray : theme.palette.colorMainLight,
  padding: '2em 2em 1em',
  width: '100%'
});

export const styleTypographyProfile = {
  fontSize: '16px',
  borderRadius: '5px',
  fontWeight: '400',
  fontStyle: 'normal',
  height: '40px'
};

export const styleTitle = (theme) => ({
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '29px',
  color: theme.palette.colorSoftBlack,
  paddingBottom: '1.5em'
});

export const styleListContainer = (isMobile, isChatOpen) => ({
  height: '100%',
  overflow: 'auto',
  width: isMobile ? '100%' : '400px',
  display: isMobile && isChatOpen ? 'none' : 'flex'
});
