import React from 'react';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function TagChips({ DN, azureId }) {
  const { t } = useTranslation();

  let label = '';

  if (DN === 'undefined') {
    label = t('AdnoExist');
  } else if (azureId === 'undefined') {
    label = t('AzurenoExist');
  }

  return label ? (
    <Chip
      label={label}
      color="error"
      sx={{ marginLeft: '40px', height: '50px', width: '200px' }}
      data-testid="Tagvisuel"
    />
  ) : null;
}

TagChips.propTypes = {
  DN: PropTypes.string.isRequired,
  azureId: PropTypes.string.isRequired
};
