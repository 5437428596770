import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getPermissions, getPhoto, getProfile } from '../../redux/slices/userConnected';
import errorRedirection from '../../utils/errorRedirection';

export default function RedirectionRoute({ isLogin }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dispatchGetProfile = async () => {
    try {
      await dispatch(getProfile()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const dispatchGetPermissions = async () => {
    try {
      await dispatch(getPermissions()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };
  useEffect(() => {
    if (isLogin) {
      dispatchGetProfile();
      dispatchGetPermissions();
      dispatch(getPhoto());
    }
  }, [isLogin, dispatch]);

  return <> </>;
}

RedirectionRoute.propTypes = {
  isLogin: PropTypes.bool
};

RedirectionRoute.defaultProps = {
  isLogin: false
};
