import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AppListService from '../../services/appList/AppListService';

export const initialState = {
  appLists: [],
  status: 'idle',
  isLoading: false
};
export const getAllAppList = createAsyncThunk('allAppList', async () => {
  const res = await AppListService.getAllAppList();
  return res.data;
});

export const createAppList = createAsyncThunk('createApplist', async (payload) => {
  const res = await AppListService.postCreateAppList(payload);
  return res.data;
});
export const deleteAppList = createAsyncThunk('deleteAppList', async (IdList) => {
  const res = await AppListService.deleteAppList(IdList);
  return res.data;
});
export const updateAppList = createAsyncThunk('updateAppList', async ({ id, formData }) => {
  const res = await AppListService.updateApp(id, formData);
  return res.data;
});
export const appListSlice = createSlice({
  name: 'appLists',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAppList.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAllAppList.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.appLists = action.payload.appList;
      })
      .addCase(getAllAppList.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(deleteAppList.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(deleteAppList.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const tempAppList = state.appLists.filter(
          (appList) => !action.meta.arg.appListIdList.includes(appList.id)
        );
        state.appLists = tempAppList;
      })
      .addCase(deleteAppList.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(createAppList.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(createAppList.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.appLists.push(action.payload.newAppList);
      })
      .addCase(createAppList.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(updateAppList.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(updateAppList.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const newApp = {
          id: action.meta.arg.id,
          authGroup: JSON.parse(action.meta.arg.formData.get('authGroup')),
          isPublic: JSON.parse(action.meta.arg.formData.get('isPublic')),
          name: action.meta.arg.formData.get('name'),
          link: action.meta.arg.formData.get('link'),
          urlId: action.payload.newFileID
            ? action.payload.newFileID
            : action.meta.arg.formData.get('urlId')
        };

        const tempAppList = [...state.appLists];
        const newAppList = tempAppList.map((app) => {
          if (app.id !== action.meta.arg.id) {
            return app;
          }
          return newApp;
        });
        state.appLists = newAppList;
      })
      .addCase(updateAppList.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const selectAppListByGroupIdAndConnected = (roleIdFilter, isConnected) => (state) => {
  if (roleIdFilter === 0 && isConnected === 0) {
    return state.appLists.appLists;
  }
  let temp = state.appLists.appLists;
  if (roleIdFilter !== 0) {
    temp = temp.filter((appList) => {
      const authGroupFilter = appList.authGroup.filter(({ id }) => id === roleIdFilter);
      return authGroupFilter.length > 0;
    });
  }
  if (isConnected !== 0) {
    temp = temp.filter(({ isPublic }) => isPublic === !isConnected);
  }
  return temp;
};
export const selectAppListById = (id) => (state) => {
  return state.appLists.appLists.find((appList) => appList.id === id);
};

export default appListSlice.reducer;
