import { Box, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import PropTypes from 'prop-types';

export default function EditField({ keyName, value, setModifiedItem, sxedit, labelName, isError }) {
  return (
    <Box sx={sxedit}>
      <InputLabel htmlFor="input">{labelName || keyName}</InputLabel>
      <TextField
        required
        data-testid={keyName}
        id={keyName}
        name="classicField"
        value={value}
        fullWidth
        onChange={(e) => {
          setModifiedItem(e.target.value, keyName);
        }}
        error={isError}
        autoFocus
      />
    </Box>
  );
}

EditField.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  setModifiedItem: PropTypes.func,
  sxedit: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  labelName: PropTypes.string
};

EditField.defaultProps = {
  keyName: '',
  value: '',
  setModifiedItem: () => {},
  sxedit: { marginBottom: '25px' },
  isError: false,
  labelName: ''
};
