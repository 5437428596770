import React, { useState } from 'react';
import { TreeView } from '@mui/lab';
import PropTypes from 'prop-types';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import BrowseOUElem from './BrowseOUElem';

export default function BrowseOU({
  AD,
  setSelectedOU,
  setSelectedDNUser,
  isSelectDNUser,
  isForForm
}) {
  const [expanded, setExpanded] = useState([]);

  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={expanded}
      onNodeSelect={(event, value) => {
        setSelectedOU(value);
      }}
      sx={{ height: '100vh', flexGrow: 1, overflowY: 'auto' }}
    >
      {AD.map((elem) => (
        <div key={elem.DN}>
          <BrowseOUElem
            elem={elem}
            AD={AD}
            setExpanded={setExpanded}
            expanded={expanded}
            isSelectDNUser={isSelectDNUser}
            setSelectedDNUser={setSelectedDNUser}
            isForForm={isForForm}
          />
        </div>
      ))}
    </TreeView>
  );
}

BrowseOU.propTypes = {
  AD: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
  ).isRequired,
  setSelectedOU: PropTypes.func,
  isSelectDNUser: PropTypes.bool,
  setSelectedDNUser: PropTypes.func,
  isForForm: PropTypes.bool
};

BrowseOU.defaultProps = {
  setSelectedDNUser: () => {
    return null;
  },
  setSelectedOU: () => {
    return null;
  },
  isSelectDNUser: false,
  isForForm: false
};
