import * as React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import {
  styleAzureButton,
  styleAzureText,
  styleImageAzureAd
} from '../../assets/style/components/buttons/AzureButton';
import microsoftLogo from '../../assets/images/logo_Microsoft.png';

export default function AzureButton({ onClick, text }) {
  return (
    <Button onClick={onClick} variant="outlined" sx={styleAzureButton}>
      <Grid container item xs={3} md={2} direction="column">
        <img src={microsoftLogo} alt="azureLogo" style={styleImageAzureAd} />
      </Grid>
      <Grid container direction="row">
        <Grid item xs={9} md={10}>
          <Typography sx={styleAzureText}>{text}</Typography>
        </Grid>
      </Grid>
    </Button>
  );
}

AzureButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};
