import * as React from 'react';
import Button from '@mui/material/Button';
import { Link, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styleBtn from '../../assets/style/components/buttons/CustomizedButton';

export default function CustomizedButton({
  path,
  onClick,
  text,
  setTarget,
  datatestid,
  styleLink,
  styleButton,
  disabled,
  type
}) {
  const theme = useTheme();
  return !path ? (
    <Button
      onClick={onClick}
      type={type}
      data-testid={datatestid}
      sx={styleButton(theme)}
      disabled={disabled}
    >
      {text}
    </Button>
  ) : (
    <Link
      component={RouterLink}
      to={path}
      target={setTarget}
      data-testid={datatestid}
      sx={styleLink}
    >
      {onClick ? (
        <Button
          onClick={onClick}
          data-testid={datatestid}
          sx={styleButton(theme)}
          disabled={disabled}
        >
          {text}
        </Button>
      ) : (
        text
      )}
    </Link>
  );
}

CustomizedButton.propTypes = {
  disabled: PropTypes.bool,
  path: PropTypes.string,
  setTarget: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  datatestid: PropTypes.string.isRequired,
  styleLink: PropTypes.oneOfType([PropTypes.shape({ display: PropTypes.string }), PropTypes.func]),
  styleButton: PropTypes.oneOfType([
    PropTypes.shape({ display: PropTypes.string }),
    PropTypes.func
  ]),
  type: PropTypes.string
};

CustomizedButton.defaultProps = {
  disabled: false,
  path: undefined,
  setTarget: '_self',
  onClick: undefined,
  styleLink: styleBtn,
  styleButton: styleBtn,
  type: 'button'
};
