import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  TableBody,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  Paper,
  useTheme,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  styleColumnUsers,
  styleTableHead,
  styleSelect,
  styleNameStyle
} from '../../assets/style/views/UserPages';
import { selectedAzureUsers } from '../../redux/slices/AzureUsersList';
import LicenseNotifList from './LicenseNotif';

export default function LicenseNotifCollapse({ licenseNotificationSorted }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const selectedAzureUser = useSelector(selectedAzureUsers);
  const [expanded, setExpanded] = useState(false);
  const [userDisplay, setuserDisplay] = useState(3);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleUserDisplayChange = (nbNotif) => {
    setuserDisplay(nbNotif);
  };

  const [expandedbis, setExpandedbis] = useState(false);

  const handleChangebis = (panel) => (event, isExpanded) => {
    setExpandedbis(isExpanded ? panel : false);
  };

  const GetUserName = ({ id }) => {
    const index = selectedAzureUser.findIndex((object) => {
      return object.id === id;
    });
    if (index !== -1) {
      return selectedAzureUser[index].displayName;
    }
    return t('noName');
  };

  return (
    <Grid container direction="column">
      <Accordion
        expanded={expandedbis === 'panel2'}
        onChange={handleChangebis('panel2')}
        sx={{ width: '100%' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography data-testid={`userName${licenseNotificationSorted.id}`}>
            <GetUserName id={licenseNotificationSorted.id} />
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container direction="column">
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
              sx={{ width: '100%' }}
            >
              <AccordionSummary
                onClick={() => {
                  handleUserDisplayChange(
                    userDisplay === 3 ? licenseNotificationSorted.licenseNotifList.length : 3
                  );
                }}
                expandIcon={
                  licenseNotificationSorted.licenseNotifList &&
                  licenseNotificationSorted.licenseNotifList.length > 3 && <ExpandMoreIcon />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <TableContainer component={Paper} sx={styleSelect}>
                  <Table aria-label="table products">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={(styleColumnUsers, styleTableHead(theme))}>
                          <Typography gutterBottom>{t('notification')}</Typography>
                        </TableCell>
                        <TableCell
                          sx={[(styleColumnUsers, styleTableHead(theme)), { width: '300px' }]}
                        >
                          <Typography gutterBottom>{t('Date')}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {licenseNotificationSorted && (
                        <TableRow>
                          <TableCell colSpan={2} sx={styleNameStyle}>
                            <TableContainer>
                              <Table aria-label="table products">
                                <TableBody>
                                  {licenseNotificationSorted && (
                                    <LicenseNotifList
                                      userDisplay={userDisplay}
                                      licenseNotificationSorted={licenseNotificationSorted}
                                    />
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionSummary>
            </Accordion>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

LicenseNotifCollapse.propTypes = {
  licenseNotificationSorted: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
        )
      )
    ])
  )
};

LicenseNotifCollapse.defaultProps = {
  licenseNotificationSorted: {
    id: '',
    licenseNotifList: []
  }
};
