export const styleChatContainer = (theme, isMobile, isChatOpen) => ({
  flex: '4',
  height: isMobile ? 'calc( 100vh - 230px)' : 'calc( 100vh - 260px)',
  backgroundColor: theme.palette.colorSecondaryLight,
  background: theme.palette.colorSecondaryLight,
  display: isMobile && !isChatOpen ? 'none' : 'flex',
  flexDirection: 'column'
});

export const styleChatArea = {
  flex: '8',
  overflowY: 'auto'
};

export const styleMessageArea = {
  maxHeight: '125px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  flex: '2'
};

export const stylePadding20 = {
  padding: '20px'
};

export const styleMessageGrid = {
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '10px'
};

export const styleReturnButton = {
  left: '20px'
};

export const styleChatHeader = {
  fontWeight: 'bold',
  fontSize: '20px'
};

export const styleMessageItem = (name) => ({
  display: 'flex',
  justifyContent: name === 'sent' ? 'flex-end' : 'flex-start'
});

export const styleMessageText = (theme) => ({
  color: theme.palette.colorSoftBlack
});

export const styleMessageName = (theme) => ({
  color: theme.palette.colorBlack,
  fontWeight: 'bold'
});

export const styleMessage = (theme, name) => ({
  padding: '15px 15px 15px 15px',
  borderRadius: '5px',
  width: '40%',
  minWidth: '150px',
  overflowWrap: 'break-word',
  textAlign: name === 'sent' ? 'right' : 'left',
  background: name === 'sent' ? theme.palette.colorMainLight : theme.palette.colorMainLightHover,
  border: name === 'sent' ? `1px solid ${theme.palette.colorSecondaryLight}` : 'none'
});

export const styleMessageAvatar = (theme) => ({
  border: `2px solid ${theme.palette.colorMainLightHover}`,
  marginRight: '1em',
  marginLeft: '3em'
});

export const styleSMSIcon = (theme) => ({
  marginLeft: '1%',
  color: theme.palette.colorMain
});

export const styleMessageInput = (theme) => ({
  borderRadius: '25px',
  width: '90%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '25px'
  },
  background: theme.palette.colorMainLight
});

export const styleSendMessageButton = (theme) => ({
  minWidth: '50px',
  minHeight: '50px',
  maxWidth: '50px',
  maxHeight: '50px',
  marginLeft: '10px',
  backgroundColor: theme.palette.colorMainSelected,
  borderRadius: '0.4rem'
});
