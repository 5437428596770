import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsersAdminService from '../../services/admin/UsersAdminService';

export const initialState = {
  users: [],
  page: 0,
  rowsPerPage: 10,
  totalPages: 0,
  totalItems: 0,
  isLoading: false,
  status: 'fulfilled'
};

export const requestAllUsersPagination = createAsyncThunk('users/list', async (payload) => {
  const res = await UsersAdminService.requestUserList(payload.page, payload.rowsPerPage);
  return res.data;
});

export const requestSearchedUsersPagination = createAsyncThunk('users/search', async (payload) => {
  const res = await UsersAdminService.requestSearchedUsers(
    payload.searchString,
    payload.page,
    payload.rowsPerPage
  );
  return res.data;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserListState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestAllUsersPagination.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(requestAllUsersPagination.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.users = action.payload.users;
        state.totalItems = action.payload.totalItems;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(requestAllUsersPagination.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      // Cases for searched user
      .addCase(requestSearchedUsersPagination.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(requestSearchedUsersPagination.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.users = action.payload.users;
        state.totalItems = action.payload.totalItems;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(requestSearchedUsersPagination.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const { resetUserListState } = usersSlice.actions;
export const selectUsers = (state) => state.users.users;
export const selectTotalItems = (state) => state.users.totalItems;

export default usersSlice.reducer;
