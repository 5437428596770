import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  styleStandardBoxGrid,
  styleHeaderWrapper,
  styleHeaderTitle,
  styleHeaderButtonsWrapper
} from '../assets/style/components/StandardBox';
import CustomizedButton from './buttons/CustomizedButton';

export default function StandardBox({ title, titleLevel, buttons, ChildComponent }) {
  const { t } = useTranslation();

  return (
    <Grid sx={styleStandardBoxGrid} data-testid={`${title}Box`}>
      <Grid sx={styleHeaderWrapper}>
        <Typography variant="h4" component={titleLevel} sx={styleHeaderTitle}>
          {t(`${title}`)}
        </Typography>
        <Grid sx={styleHeaderButtonsWrapper}>
          {!buttons.length > 0
            ? null
            : buttons.map((button) =>
                button.hasPermissions ? (
                  <Grid key={button.datatestid}>
                    <CustomizedButton
                      disabled={button?.disabled}
                      text={button.text}
                      datatestid={button?.datatestid}
                      onClick={button?.onClick}
                    />
                  </Grid>
                ) : null
              )}
        </Grid>
      </Grid>
      {ChildComponent}
    </Grid>
  );
}

StandardBox.propTypes = {
  title: PropTypes.string,
  titleLevel: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.bool]))
  ),
  ChildComponent: PropTypes.element
};
StandardBox.defaultProps = {
  title: '',
  buttons: [],
  ChildComponent: null,
  titleLevel: 'h2'
};
