import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StandardBox from '../StandardBox';
import { selectedUser } from '../../redux/slices/AzureUsersList';
import AdGroupList from '../adUserGroups/AdGroupList';
import AzureGroupsPopup from '../popup/AzureUsersGroupsPopup';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';

export default function UserAzureGroupDescription() {
  const { t } = useTranslation();

  const user = useSelector(selectedUser);
  const permissions = useSelector(selectPermissions);

  const [usersGroupsPopup, setUsersGroupsPopup] = useState(false);
  const [isRemoveGroups, setIsRemoveGroups] = useState(false);

  const listButton = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_AZURE_GROUPS_TO_USER'),
      datatestid: 'AzureAssignGroups',
      onClick: () => {
        setIsRemoveGroups(false);
        setUsersGroupsPopup(true);
      },
      text: t('assignAzureGroups')
    },
    {
      hasPermissions: hasPermissions(permissions, 'SET_REMOVE_AZURE_GROUPS_TO_USER'),
      datatestid: 'AzureRemoveGroups',
      onClick: () => {
        setIsRemoveGroups(true);
        setUsersGroupsPopup(true);
      },
      text: t('removeAzureGroups')
    }
  ];

  return (
    <Grid>
      <StandardBox
        title="azureGroups"
        buttons={listButton}
        ChildComponent={
          <AdGroupList
            selectedGroups={user.memberOf?.map((displayName) => ({ name: displayName }))}
          />
        }
      />
      <AzureGroupsPopup
        numberOfUsers={1}
        selectedUsers={[user.id]}
        isRemove={isRemoveGroups}
        popup={usersGroupsPopup}
        closePopup={() => setUsersGroupsPopup(false)}
      />
    </Grid>
  );
}
