import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@emotion/react';
import { styleIconChevron } from '../../assets/style/components/layout/SideBar';

export default function DisplayChevronSideBar({
  isOpenSideBar,
  handleDrawerClose,
  handleDrawerOpen,
  openSubMenuFromPath
}) {
  const theme = useTheme();
  return isOpenSideBar ? (
    <IconButton aria-label="close drawer" onClick={handleDrawerClose} sx={styleIconChevron(theme)}>
      <ChevronLeftIcon />
    </IconButton>
  ) : (
    <IconButton
      aria-label="open drawer"
      data-testid="ButtonOpenDrawerSideBar"
      onClick={() => {
        handleDrawerOpen();
        openSubMenuFromPath();
      }}
      sx={styleIconChevron(theme)}
    >
      <ChevronRightIcon />
    </IconButton>
  );
}

DisplayChevronSideBar.propTypes = {
  isOpenSideBar: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  openSubMenuFromPath: PropTypes.func.isRequired
};
