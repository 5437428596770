import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './slices/index';

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 }, // TODO SIP-705
      serializableCheck: { warnAfter: 128 } // TODO SIP-705
    })
});
