import React from 'react';
import PropTypes from 'prop-types';
import PasswordField from './Field/PasswordField';
import EditField from './Field/EditField';
import AutoCompleteField from './Field/AutoCompleteField';
import ObjectField from './Field/ObjectField';
import StaticTextField from './Field/StaticTextField';
import SwitchField from './Field/SwitchField';
import ImageField from './Field/ImageField';
import ClassicField from './Field/ClassicField';
import ArrayField from './Field/ArrayField';
import NumberField from './Field/NumberField';
// eslint-disable-next-line import/no-cycle
import EmptyObjectField from './Field/EmptyObjectField';

const fields = {
  password: PasswordField,
  edit: EditField,
  autoComplete: AutoCompleteField,
  isObject: ObjectField,
  staticText: StaticTextField,
  isBoolean: SwitchField,
  image: ImageField,
  classicField: ClassicField,
  arrayField: ArrayField,
  numberField: NumberField,
  emptyObjectField: EmptyObjectField
};
export default function Field({
  keyName,
  value,
  type,
  setModifiedItem,
  file,
  setFile,
  labelName,
  isError,
  isRequired,
  isSubmit,
  sxedit,
  childFieldList
}) {
  const FieldType = fields[type];

  if (FieldType)
    return (
      <FieldType
        keyName={keyName}
        value={value}
        setModifiedItem={setModifiedItem}
        isError={isError && isError(keyName, isRequired, isSubmit)}
        file={file}
        setFile={setFile}
        labelName={labelName}
        sxedit={sxedit}
        isSubmit={isSubmit}
        childFieldList={childFieldList}
      />
    );
  return null;
}

Field.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  type: PropTypes.string,
  setModifiedItem: PropTypes.func,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isRequired: PropTypes.bool,
  isSubmit: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFile: PropTypes.func,
  labelName: PropTypes.string,
  sxedit: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // eslint-disable-next-line react/forbid-prop-types
  childFieldList: PropTypes.array
};

Field.defaultProps = {
  keyName: '',
  value: '',
  type: '',
  setModifiedItem: () => {},
  isError: false,
  isRequired: false,
  isSubmit: false,
  file: null,
  setFile: () => {},
  labelName: '',
  sxedit: { marginBottom: '25px' },
  childFieldList: []
};
