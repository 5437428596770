const formattedAzureUserField = (user) => {
  const userFormatted = { ...user };

  Object.keys(userFormatted).forEach((key) => {
    if (!userFormatted[key]) userFormatted[key] = null;

    switch (key) {
      case 'givenName':
        userFormatted[key] = userFormatted[key].replace(/\s/g, '');
        userFormatted[key] =
          userFormatted[key][0].toUpperCase() + userFormatted[key].slice(1).toLowerCase();
        break;
      case 'surname':
        userFormatted[key] = userFormatted[key].replace(/\s/g, '').toUpperCase();
        break;
      case 'businessPhones':
        if (
          typeof userFormatted[key] !== 'string' &&
          userFormatted[key] &&
          userFormatted[key].length > 0
        ) {
          userFormatted.businessPhones = userFormatted.businessPhones.shift();
        }
        break;
      default:
        break;
    }
  });
  if ('givenName' in userFormatted && 'surname' in userFormatted) {
    userFormatted.mailNickname = `${userFormatted.givenName}.${userFormatted.surname}`
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f' ]/g, '');
    userFormatted.displayName = `${userFormatted.givenName} ${userFormatted.surname}`;
    if ('userPrincipalName' in userFormatted) {
      userFormatted.userPrincipalName = `${userFormatted.mailNickname}@${
        userFormatted.userPrincipalName.split('@')[1]
      }`;
      userFormatted.mail = userFormatted.userPrincipalName;
    }
  }

  if (!('accountEnabled' in userFormatted)) userFormatted.accountEnabled = true;

  return userFormatted;
};

export default formattedAzureUserField;
