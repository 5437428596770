import React from 'react';
import Paper from '@mui/material/Paper';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  styleColumnText,
  styleRowBodyTab,
  styleRowHeaderTab,
  styleRowText
} from '../../assets/style/components/StandardTable';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';
import identifier from '../../utils/keySelector';

export default function Desktop({ rows, columns, refColumn }) {
  const { t } = useTranslation();

  const alignItemsInTable = (index, arrayLength) => {
    if (index === 0) return 'left';
    if (index === arrayLength - 1) return 'right';
    return 'center';
  };

  const handleComponent = (component, id) => {
    if (React.isValidElement(component)) {
      return React.cloneElement(component, { onClick: () => component.props.onClick(id) });
    }
    return null;
  };
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
      <Table
        sx={{
          minWidth: 650
        }}
        aria-label="simple table"
      >
        <TableHead sx={styleRowHeaderTab}>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                align={`${alignItemsInTable(index, columns.length)}`}
                sx={styleColumnText}
                key={column.name}
              >
                {t(`${column.name}`).toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={styleRowBodyTab}>
          {rows.map((row, rowIndex) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              key={row[refColumn]}
            >
              {columns.map((column, index) => (
                <TableCell
                  align={`${alignItemsInTable(index, columns.length)}`}
                  key={`${identifier(row)}${column.key}`}
                  data-testid={`cell${column.key}${rowIndex}`}
                  sx={styleRowText}
                >
                  {column.type === 'string' ? row[column.key] : null}
                  {column.type === 'component'
                    ? handleComponent(column.component, identifier(row))
                    : null}
                  {column.type === 'date' ? getDateTimeFormatted(row[column.key]) : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

Desktop.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element]))
  ).isRequired,
  refColumn: PropTypes.string.isRequired
};
