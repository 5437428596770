import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestAllNotifsPagination } from './licenseNotification';
import LicensesService from '../../services/licenses/LicensesService';
import { regexpEscaping } from '../../utils/regex';

export const initialState = {
  azureLicenses: [],
  isLoading: false,
  isRequestLoading: false,
  status: 'fulfilled'
};

export const getLicensesAzure = createAsyncThunk('getLicensesAzure', async () => {
  const res = await LicensesService.findAllLicenses();
  return res.data;
});

export const setLicensesAzure = createAsyncThunk('setLicensesAzure', async (body) => {
  const res = await LicensesService.assignLicenses(body);
  return res.data;
});

export const deleteLicensesAzure = createAsyncThunk('deleteLicensesAzure', async (body) => {
  const res = await LicensesService.removeLicenses(body);
  return res.data;
});

export const azureLicensesSlice = createSlice({
  name: 'azureLicenses',
  initialState,
  reducers: {
    resetAzureLicenseState: () => {
      return initialState;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getLicensesAzure.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getLicensesAzure.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.azureLicenses = action.payload.licenses;
      })
      .addCase(getLicensesAzure.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder.addCase(setLicensesAzure.fulfilled, (state) => {
      state.isRequestLoading = true;
    });
    builder.addCase(requestAllNotifsPagination.fulfilled, (state) => {
      state.isRequestLoading = false;
    });
  }
});

export const { resetAzureLicenseState } = azureLicensesSlice.actions;

export const selectLicensesIsLoading = (state) => state.azureLicenses.isLoading;

export const selectAzureLicenses = (searchTerm) => (state) => {
  if (state.azureLicenses.azureLicenses.length > 0 && searchTerm.length > 2) {
    const escapedString = regexpEscaping(searchTerm);
    const regexp = new RegExp(`${escapedString}`, 'gi');
    return state.azureLicenses.azureLicenses.filter(
      (license) => license.displayName && license.displayName.match(regexp)
    );
  }
  return state.azureLicenses.azureLicenses;
};

export const selectAzureLicensesAvailableByAppliesTo =
  (numberOfUsers, appliesTo, capabilityStatus, searchTerm) => (state) => {
    if (state.azureLicenses.azureLicenses.length > 0 && searchTerm.length > 2) {
      const escapedString = regexpEscaping(searchTerm);
      const regexp = new RegExp(`${escapedString}`, 'gi');
      return state.azureLicenses.azureLicenses.filter(
        (license) =>
          license.prepaidUnitsAvailable >= numberOfUsers &&
          license.appliesTo === appliesTo &&
          license.capabilityStatus === capabilityStatus &&
          license.displayName &&
          license.displayName.match(regexp)
      );
    }
    return state.azureLicenses.azureLicenses.length > 0
      ? state.azureLicenses.azureLicenses.filter(
          (license) =>
            license.prepaidUnitsAvailable >= numberOfUsers &&
            license.appliesTo === appliesTo &&
            license.capabilityStatus === capabilityStatus
        )
      : state.azureLicenses.azureLicenses;
  };

export const selectAssignAzureLicenses = (arrayLicensesSkuId) => (state) => {
  return state.azureLicenses.azureLicenses.filter((license) =>
    arrayLicensesSkuId.includes(license.skuId)
  );
};

export const selectIsTableDisplayable = (state) => {
  if (state.azureLicenses.azureLicenses.length <= 0) {
    return false;
  }
  return true;
};

export const isRequestLoading = (state) => state.azureLicenses.isRequestLoading;

export default azureLicensesSlice.reducer;
