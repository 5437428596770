export const styleAppBar = (theme, isMobile) => ({
  display: isMobile ? undefined : 'none',
  position: 'sticky',
  zIndex: '2',
  background: theme.palette.colorMainGradient,
  height: '60px'
});

export const styleDrawerWrapper = {
  display: 'flex',
  position: 'relative',
  zIndex: '1'
};

export const styleDrawer = (theme) => ({
  background: theme.palette.colorMainGradient,
  height: '100%'
});

export const styleMain = (theme, isOpenSideBar) => ({
  width: {
    xs: '100%',
    sm: `calc(100% - ${isOpenSideBar ? '240px' : '120px'})`
  },
  background: theme.palette.colorMainLight,
  position: { sx: 'relative', sm: 'static' },
  zIndex: { sx: '1' },
  height: { xs: 'calc(100vh - 60px)', sm: '100vh' }
});

export const styleMobileSideBar = {
  display: { xs: 'flex', sm: 'none' },
  position: 'relative',
  zIndex: '1'
};

export const styleDesktopSideBar = {
  display: { xs: 'none', sm: 'flex' },
  position: 'relative',
  zIndex: '1'
};
