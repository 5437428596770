import { io } from 'socket.io-client';

const URL =
  window.location.protocol === 'https:'
    ? `https://${window.location.hostname}/`
    : 'http://127.0.0.1:3500/';

const socket = io(URL, {
  autoConnect: false,
  path: '/api/socket.io/'
});

export default socket;
