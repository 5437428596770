import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ParametersService from '../../services/parameters/ParametersService';

export const initialState = {
  parameters: [],
  selectedParameter: {},
  status: 'idle',
  isLoading: false
};
export const getAllParameters = createAsyncThunk('allParameters', async () => {
  const res = await ParametersService.getAllParameters();
  return res.data;
});

export const updateParameterById = createAsyncThunk(
  'updateParameterById',
  async ({ id, formData }) => {
    const res = await ParametersService.updateParameterById(id, formData);
    return res.data;
  }
);

export const parametersSlice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    resetParameters: () => {
      return initialState;
    },
    resetSelectedParameter: (state) => {
      state.selectedParameter = {};
    },
    selectParameter: (state, action) => {
      [...state.parameters].map((element) => {
        if (element.id === action?.payload) {
          state.selectedParameter = element;
          return true;
        }
        return false;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllParameters.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAllParameters.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.parameters = action.payload.parameters;
      })
      .addCase(getAllParameters.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(updateParameterById.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(updateParameterById.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const newParameter = {
          id: action.meta.arg.id,
          type: action.meta.arg.formData.get('type'),
          key: action.meta.arg.formData.get('key'),
          value: action.meta.arg.formData.get('value')
        };

        const tempParameterList = [...state.parameters];
        const newParameterList = tempParameterList.map((parameter) => {
          if (parameter.id !== action.meta.arg.id) {
            return parameter;
          }
          return newParameter;
        });
        state.parameters = newParameterList;
        state.selectedParameter = newParameter;
      })
      .addCase(updateParameterById.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const { resetParameters, resetSelectedParameter, selectParameter } = parametersSlice.actions;

export const selectParameters = (state) => state?.parameters?.parameters;
export const selectSelectedParameter = (state) => state?.parameters?.selectedParameter;

export default parametersSlice.reducer;
