import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Box, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styleBox, styleTitle } from '../../assets/style/views/Profile';

export default function AzureLicensesList({ azureLicenses }) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Grid container sx={{ ...styleBox(theme), width: '100%' }}>
      <Typography variant="h4" sx={styleTitle}>
        {t('licensesAzureAd')}
      </Typography>
      <List
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 16px',
            height: '38px',
            border: `2px solid ${theme.palette.colorSoftBlack}`,
            borderRadius: '8px',
            color: theme.palette.colorSoftBlack
          }
        }}
      >
        {azureLicenses?.map((license) => (
          <Box key={license?.id} sx={{ margin: '5px' }}>
            <ListItem>
              <ListItemText primary={license?.productDisplayName} />
            </ListItem>
          </Box>
        ))}
      </List>
    </Grid>
  );
}

AzureLicensesList.propTypes = {
  azureLicenses: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.string,
        PropTypes.string,
        PropTypes.string
      ])
    )
  )
};
AzureLicensesList.defaultProps = {
  azureLicenses: []
};
