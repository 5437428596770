import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomizedButton from '../buttons/CustomizedButton';
import {
  stylePadding,
  styleFullWidth,
  stylePaddingRight
} from '../../assets/style/components/popup/AzureLicensesPopup';
import AdGroupsTab from './AdGroupsTab';

export default function AdSetGroups({ closePopup, button, setGroups, numberOfUsers }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [selectedGroups, setSelectedGroups] = useState([]);

  return (
    <Grid sx={stylePadding}>
      <Grid display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="h4" sx={stylePaddingRight}>
          {t('selectADGroups')}
        </Typography>
      </Grid>
      <Grid>
        <AdGroupsTab
          page={page}
          setPage={setPage}
          setCheckBoxArray={setSelectedGroups}
          checkboxKey="name"
          numberOfUsers={numberOfUsers}
          activeCheckBox
        />

        <Grid display="flex" justifyContent="space-around" sx={styleFullWidth}>
          <CustomizedButton
            onClick={closePopup}
            text={t('cancel')}
            datatestid="CancelAssignGroups"
          />
          <Grid display="flex">
            {button.hasPermissions ? (
              <Grid key={button.datatestid}>
                <CustomizedButton
                  disabled={button?.disabled}
                  text={button.text}
                  datatestid={button?.datatestid}
                  onClick={() => {
                    setGroups(selectedGroups);
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

AdSetGroups.propTypes = {
  button: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])).isRequired,
  closePopup: PropTypes.func.isRequired,
  setGroups: PropTypes.func.isRequired,
  numberOfUsers: PropTypes.number.isRequired
};
