import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';
import frImg from '../../assets/images/lngs/fr.png';
import enImg from '../../assets/images/lngs/en.png';

import { styleImageLang } from '../../assets/style/components/layout/Header';
import { styleSelect } from '../../assets/style/views/UserPages';

const lngs = {
  en: { nativeName: 'English', label: 'EN', img: enImg, datatestid: 'select-lng-item-en' },
  fr: { nativeName: 'French', label: 'FR', img: frImg, datatestid: 'select-lng-item-fr' }
};

export default function LanguageSelect() {
  const { i18n } = useTranslation();
  const [lang, setLng] = useState(
    Object.prototype.hasOwnProperty.call(lngs, localStorage.getItem('Lang'))
      ? localStorage.getItem('Lang')
      : i18n.resolvedLanguage
  );

  const handleChange = (event) => {
    setLng(event.target.value);
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('Lang', event.target.value);
  };

  return (
    <Select
      id="select-lng"
      value={lang}
      onChange={handleChange}
      sx={styleSelect}
      data-testid="select-lng"
    >
      {Object.keys(lngs).map((lng) => (
        <MenuItem value={lng} key={lngs[lng].label} data-testid={lngs[lng].datatestid}>
          {lngs[lng].nativeName}
          <img src={lngs[lng].img} alt={lngs[lng].label} style={styleImageLang} />
        </MenuItem>
      ))}
    </Select>
  );
}
