import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import DataListUserMobile from '../../components/datalist/DataListUserMobile';
import {
  styleTypographyTableMobileUsers,
  styleTypographyTableButtonMobileUsers,
  styleActionRefreshIconButtonMobileUsers,
  styleMobilePaginationUsers,
  styleActionIconMobileUsersLoading
} from '../../assets/style/views/UserPages';
import HeaderMobileList from '../../components/HeaderMobileList';
import { regexpEscaping } from '../../utils/regex';
import CustomizedPagination from '../../components/datalist/TablePagination';

export default function ItemsMobile({ config }) {
  const { t } = useTranslation();

  const { handleSearchTerm } = config.actions;
  const { handleRefresh } = config.actions;

  const { buttons, isDisableCheckBox, hideRefresh } = config;

  const { tableSelectedRows } = config.data;
  const { setTableSelectedRows } = config.data;
  const { setTableSelectedRowsRealData } = config.data;
  const { linkOptions } = config;
  const { items } = config.data;

  const { isTableDisplayable, itemsListIsLoading } = config.loading;

  const { optionsKeys } = config.tables;
  const { date } = config.tables;
  const { page } = config.tables;
  const { setPage } = config.tables;
  const { totalItems } = config.tables;
  const { handleChangePage } = config.tables;
  const { handleChangeRowsPerPage } = config.tables;
  const { rowsPerPage } = config.tables;
  const { selectedIndexRows } = config.tables;

  const { setSearchTerm, searchTerm } = config.search;

  const { titleText, bigTitleText } = config.text;
  const { isPartialData } = config;

  const isOpenSideBar = localStorage.getItem('isOpenSideBar');
  const [showSearchBarMobile, setShowSearchBarMobile] = useState(false);
  const selectedRowsCount = tableSelectedRows.length;
  const [allRowsAreSelected, setAllRowsAreSelected] = useState(false);

  const filterItems = (elements, search) => {
    if (search?.length > 1) {
      const escapedString = regexpEscaping(search);
      const regexp = new RegExp(`${escapedString}`, 'gi');

      const newItems = elements.filter(
        (item) => item[optionsKeys.titleNameKey] && item[optionsKeys.titleNameKey].match(regexp)
      );
      return newItems;
    }
    return elements;
  };
  const filteredItems = filterItems(items, searchTerm);

  const selectAllRows = () => {
    const newRowsSelected = allRowsAreSelected
      ? []
      : Array.from({ length: items.length }, (_, idx) => (idx === 0 ? null : idx));
    setTableSelectedRowsRealData(newRowsSelected);
    setAllRowsAreSelected(!allRowsAreSelected);
  };

  const theme = useTheme();
  const isMobile = true;
  const totalItemsMobile = totalItems || filteredItems.length;

  return (
    <Grid
      container
      sx={{
        contain: 'inline-size',
        height: { xs: 'calc(100vh - 60px)', sm: '100vh' },
        width: '100%'
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <HeaderMobileList
        title={bigTitleText}
        searchTitle={titleText}
        showSearchBarMobile={showSearchBarMobile}
        setShowSearchBarMobile={setShowSearchBarMobile}
        setSearchTerm={setSearchTerm}
        isOpenSideBar={isOpenSideBar}
        itemsListIsLoading={itemsListIsLoading}
        handleSearchTerm={handleSearchTerm}
        buttons={buttons}
      />
      {!hideRefresh ? (
        <IconButton
          sx={styleActionRefreshIconButtonMobileUsers}
          onClick={handleRefresh}
          disabled={!date || itemsListIsLoading}
        >
          <RefreshIcon
            sx={
              !date || itemsListIsLoading
                ? styleActionIconMobileUsersLoading
                : { fontSize: '2.5rem' }
            }
          />
        </IconButton>
      ) : null}
      <Grid container sx={styleMobilePaginationUsers(theme, isOpenSideBar, showSearchBarMobile)}>
        <Grid item xs={12}>
          <CustomizedPagination
            count={totalItemsMobile}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            isMobile={isMobile}
          />
        </Grid>
        {!isDisableCheckBox ? (
          <Grid container justifyContent="space-between" alignItems="flex-start">
            <Typography onClick={selectAllRows} sx={styleTypographyTableButtonMobileUsers}>
              {t('selectAll')}
            </Typography>
            <Typography sx={styleTypographyTableMobileUsers}>
              {selectedRowsCount} {t('selectedCount')}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <DataListUserMobile
        rows={filteredItems}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsKeys={optionsKeys}
        tableSelectedRows={tableSelectedRows}
        setSelectedRows={setTableSelectedRows}
        selectedIndexRows={selectedIndexRows}
        setTableSelectedRows={setTableSelectedRowsRealData}
        showSearchBarMobile={showSearchBarMobile}
        isDisplayable={isTableDisplayable}
        isDisableCheckBox={isDisableCheckBox}
        isPartialData={isPartialData}
        linkOptions={linkOptions}
      />
    </Grid>
  );
}

ItemsMobile.propTypes = {
  config: PropTypes.shape({
    isDisableCheckBox: PropTypes.bool,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        datatestid: PropTypes.string,
        isDisplayable: PropTypes.bool,
        onClick: PropTypes.func,
        text: PropTypes.string
      })
    ),
    actions: PropTypes.shape({
      handleRefresh: PropTypes.func,
      handleSearchTerm: PropTypes.func
    }),
    data: PropTypes.shape({
      tableSelectedRows: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
            PropTypes.arrayOf(PropTypes.string)
          ])
        )
      ),
      setTableSelectedRows: PropTypes.func,
      setTableSelectedRowsRealData: PropTypes.func,
      items: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
            PropTypes.array,
            PropTypes.arrayOf(PropTypes.string)
          ])
        )
      )
    }),
    loading: PropTypes.shape({
      itemsListIsLoading: PropTypes.bool,
      isTableDisplayable: PropTypes.bool
    }),
    tables: PropTypes.shape({
      optionsKeys: PropTypes.shape({
        checkboxKey: PropTypes.string,
        titleNameKey: PropTypes.string,
        secondaryTitleKey: PropTypes.string,
        statusKey: PropTypes.string
      }),
      date: PropTypes.string,
      totalItems: PropTypes.number,
      page: PropTypes.number,
      setPage: PropTypes.func,
      handleChangePage: PropTypes.func,
      handleChangeRowsPerPage: PropTypes.func,
      rowsPerPage: PropTypes.number,
      selectedIndexRows: PropTypes.arrayOf(PropTypes.number)
    }),
    search: PropTypes.shape({
      setSearchTerm: PropTypes.func,
      searchTerm: PropTypes.string
    }),
    text: PropTypes.shape({
      titleText: PropTypes.string,
      bigTitleText: PropTypes.string
    }),
    hideRefresh: PropTypes.bool,
    isPartialData: PropTypes.bool,
    linkOptions: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
  })
};

ItemsMobile.defaultProps = {
  config: {}
};
