import PropTypes from 'prop-types';
import { Link, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { classes } from '../../assets/style/components/layout/SideBar';

export default function CustomizedListItem({
  child,
  itemOrChild,
  reductMobileSideBar,
  currentPathLocation
}) {
  const { t } = useTranslation();
  return (
    <ListItem
      disablePadding
      data-testid={`sideBarItem${itemOrChild.id}`}
      className={`${classes.sideBarCustomizedListItem} ${
        !child ? classes.sideBarCustomizedListItemParent : classes.sideBarCustomizedListItemChild
      }`}
      selected={itemOrChild.path === currentPathLocation}
      onClick={() => {
        if (itemOrChild.onClick) itemOrChild.onClick();
        reductMobileSideBar();
      }}
    >
      <Link
        component={RouterLink}
        to={itemOrChild.path}
        data-testid={itemOrChild.datatestid}
        className={classes.sideBarLink}
      >
        <ListItemButton className={classes.sideBarCustomizedListItemButton}>
          {!child ? (
            <ListItemIcon className={classes.sideBarCustomizedListItemIcon}>
              {itemOrChild.icon}
            </ListItemIcon>
          ) : (
            <ListItemIcon
              className={`${classes.sideBarCustomizedListItemIcon} ${classes.sideBarCustomizedListItemPoint}`}
            >
              <CircleRoundedIcon fontSize="small" sx={{ marginLeft: '6px', height: '8px' }} />
            </ListItemIcon>
          )}
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary={t(itemOrChild.id)}
            className={classes.sideBarCustomizedListItemText}
          />
        </ListItemButton>
      </Link>
    </ListItem>
  );
}

CustomizedListItem.propTypes = {
  child: PropTypes.bool,
  itemOrChild: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string])))
    ])
  ).isRequired,
  currentPathLocation: PropTypes.string.isRequired,
  reductMobileSideBar: PropTypes.func
};

CustomizedListItem.defaultProps = {
  reductMobileSideBar: () => {},
  child: false
};
