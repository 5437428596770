import ParametersService from '../services/parameters/ParametersService';

export const getParametersNoConnected = async (setParametersNotConnected) => {
  try {
    const res = await ParametersService.getParametersNoConnected();
    if (res.data?.parameters) {
      setParametersNotConnected(res.data.parameters);
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const formatParameter = (parametersList, key, defaultValue, format = (x) => x) => {
  const parameter = parametersList.find((item) => item.key === key);
  return parameter?.value ? format(parameter?.value) : defaultValue;
};
