import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';

function PopupDeleteProvider({ openPop, onClose, onClickValidate, providerName }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={openPop}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={[{ overflowWrap: 'break-word', maxWidth: '387.11px' }]}>
        <DialogContentText id="alert-dialog-slide-description" textAlign="center">
          {`${t('delete')} ${providerName} ?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <CustomizedButton
          datatestid="cancelDeleteProvider"
          variant="contained"
          onClick={onClose}
          text={t('cancel')}
        />
        <CustomizedButton
          variant="contained"
          datatestid="confirmDeleteProvider"
          onClick={() => {
            onClickValidate();
            onClose();
          }}
          text={t('confirm')}
        />
      </DialogActions>
    </Dialog>
  );
}

export default PopupDeleteProvider;

PopupDeleteProvider.propTypes = {
  openPop: PropTypes.bool,
  onClose: PropTypes.func,
  providerName: PropTypes.string,
  onClickValidate: PropTypes.func
};

PopupDeleteProvider.defaultProps = {
  openPop: false,
  onClose: () => {},
  providerName: null,
  onClickValidate: () => {}
};
