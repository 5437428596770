import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PermissionsHistory from '../../services/historyPermissions/HistoryPermissionService';

export const initialState = {
  permissions: [],
  columns: ['id', 'codename', 'desc', 'shouldRecord'],
  isLoading: false,
  status: 'fulfilled'
};

export const getPermissionsHistory = createAsyncThunk('history/permissions', async () => {
  const res = await PermissionsHistory.getPermissionsHistory();
  return res.data;
});

export const removePermissionsHistory = createAsyncThunk(
  'history/permissions/remove',
  async (ids) => {
    const res = await PermissionsHistory.removePermissionsHistory(ids);
    return res.data;
  }
);

export const addPermissionsHistory = createAsyncThunk('history/permissions/add', async (ids) => {
  const res = await PermissionsHistory.addPermissionsHistory(ids);
  return res.data;
});

export const permissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    resetPermissionHistory: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissionsHistory.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getPermissionsHistory.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.permissions = action.payload.permissionInHistory;
      })
      .addCase(getPermissionsHistory.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(removePermissionsHistory.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(removePermissionsHistory.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;

        const tempPermissions = state.permissions.map((permission) => ({
          ...permission,
          shouldRecord: action.meta.arg.includes(permission.id) ? false : permission.shouldRecord
        }));

        state.permissions = tempPermissions;
      })
      .addCase(removePermissionsHistory.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(addPermissionsHistory.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(addPermissionsHistory.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const tempPermissions = state.permissions.map((permission) => ({
          ...permission,
          shouldRecord: action.meta.arg.includes(permission.id) ? true : permission.shouldRecord
        }));

        state.permissions = tempPermissions;
      })
      .addCase(addPermissionsHistory.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const { resetPermissionHistory } = permissionSlice.actions;
export const selectPermissions = (state) => state.permissionsHistory.permissions;
export const selectPermissionHistoryColumns = (state) => state.permissionsHistory.columns;
export const selectIsLoading = (state) => state.permissionsHistory.isLoading;

export const selectPermission = (searchTerm) => (state) => {
  if (state.permissionsHistory.permissions.length > 0 && searchTerm.length > 2) {
    const specialCharacters = '[]{}()\\^$.|?*+';
    const fixedSearchTerm = searchTerm.split('').map((char) => {
      if (specialCharacters.includes(char)) {
        return `\\${char}`;
      }
      return char;
    });
    const searchTermUpdate = fixedSearchTerm.join('');
    const regexp = new RegExp(`${searchTermUpdate}`, 'gi');
    return state.permissionsHistory.permissions.filter(
      (permissions) => permissions.codename && permissions.codename.match(regexp)
    );
  }
  return state.permissionsHistory.permissions;
};

export const selectIsTableLoaded = (state) => {
  if (state.permissionsHistory.isLoading || state.permissionsHistory.permissions.length === 0) {
    return false;
  }
  return true;
};
export default permissionSlice.reducer;
