import React from 'react';
import { Grid, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import {
  styleFullWidth,
  styleFormatText
} from '../../assets/style/components/azureLicenses/AzureLicensesServices';

export default function JiraGroupList({ selectedGroups }) {
  const { t } = useTranslation();

  return (
    <Grid>
      {selectedGroups?.length === 0 ? (
        <Typography sx={styleFormatText}>{t('noJiraGroups')}</Typography>
      ) : null}
      <List sx={styleFullWidth} component="nav" aria-label="mailbox folders">
        {selectedGroups?.map((group) => (
          <ListItem data-testid={`groupItem${group}`} key={`groupItem${group}`}>
            <ListItemText primary={group} />
            <Divider />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
}

JiraGroupList.propTypes = {
  selectedGroups: PropTypes.arrayOf(PropTypes.string)
};
JiraGroupList.defaultProps = {
  selectedGroups: []
};
