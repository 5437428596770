import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NotificationAdminService from '../../services/notifications/NotificationAdminService';

export const initialState = {
  azureLicenseNotif: [],
  isNotif: 0,
  page: 0,
  rowsPerPage: 10,
  totalPages: 0,
  totalItems: 0,
  isLoading: true,
  status: 'fulfilled'
};

export const requestAllNotifsPagination = createAsyncThunk(
  'licensesNotification/list',
  async (payload) => {
    const { page, rowsPerPage } = payload;
    const res = await NotificationAdminService.requestLicenses(page, rowsPerPage);
    return res.data;
  }
);

export const setLicenseRead = createAsyncThunk('setLicenseRead', async (id) => {
  const res = await NotificationAdminService.setLicenseRead(id);
  return res.data;
});

export const azureLicenseNotifSlice = createSlice({
  name: 'azureLicenseNotif',
  initialState,
  reducers: {
    addLicenseNotifToList: (state, action) => {
      const index = state.azureLicenseNotif.findIndex((data) => {
        return data.id === action.payload.id;
      });
      if (index >= 0) {
        state.azureLicenseNotif[index].licenseNotifList.push({
          msg: action.payload.msg,
          read: false
        });
      } else {
        state.azureLicenseNotif.push({
          id: action.payload.id,
          licenseNotifList: [
            {
              date: action.payload.date,
              msg: action.payload.msg,
              read: false
            }
          ]
        });
      }
      state.isNotif = state.azureLicenseNotif.filter(
        (data) => data.licenseNotifList.filter((dataRead) => dataRead.read === false).length
      ).length;
    },
    resetLicenseNotifList: (state) => {
      state.azureLicenseNotif = [];
      state.isNotif = 0;
    },
    setLicenseNotifList: (state, action) => {
      state.azureLicenseNotif = action.payload.usersLicenseNotif;
      state.isNotif = state.azureLicenseNotif.filter(
        (data) => data.licenseNotifList.filter((dataRead) => dataRead.read === false).length
      ).length;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    resetLicenseNotifState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestAllNotifsPagination.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(requestAllNotifsPagination.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.page = action.meta.arg.page;
        state.rowsPerPage = action.meta.arg.rowsPerPage;
        state.azureLicenseNotif = action.payload.users;
        state.totalItems = action.payload.totalItems;
        state.totalPages = action.payload.totalPages;
        state.isNotif = state.azureLicenseNotif.filter(
          (data) => data.licenseNotifList.filter((dataRead) => dataRead.read === false).length
        ).length;
      })
      .addCase(requestAllNotifsPagination.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
    builder
      .addCase(setLicenseRead.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(setLicenseRead.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.azureLicenseNotif = action.payload.azureLicenseNotif;
      })
      .addCase(setLicenseRead.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const {
  addLicenseNotifToList,
  resetLicenseNotifList,
  setLicenseNotifList,
  setIsLoading,
  resetLicenseNotifState
} = azureLicenseNotifSlice.actions;

export const selectTotalItems = (state) => state.licenseNotif.totalItems;
export const licenseNotif = (state) => state.licenseNotif.azureLicenseNotif;
export const isNotif = (state) => state.licenseNotif.isNotif;

export default azureLicenseNotifSlice.reducer;
