import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemButton,
  Grid,
  Typography,
  Avatar,
  useTheme
} from '@mui/material';
import Badge from '@mui/material/Badge';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  styleAlignChatList,
  styleListButton,
  styleChatListItem,
  styleTypoLastMessage,
  styleConversationGrid,
  styleNotification
} from '../../assets/style/components/SMS/ChatList';
import { getDateFormatted } from '../../utils/Date/DateCompare';
import { selectAzureUserByPhone } from '../../redux/slices/AzureUsersList';

export default function ChatListItem({
  index,
  conversation,
  selectedConversation,
  handleSelectedConversation
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const phoneName = (phoneNumber) => {
    const name = useSelector(selectAzureUserByPhone(phoneNumber));
    return name?.displayName.split(' ')[0];
  };

  return (
    <Grid key={conversation.phoneNumber.id} sx={styleConversationGrid}>
      <ListItem>
        <ListItemButton
          data-testid={`conversationListButton${conversation.phoneNumber.name}`}
          sx={{
            ...styleListButton(theme),
            backgroundColor:
              selectedConversation.phoneNumberId === conversation.phoneNumber.id
                ? theme.palette.colorMainLight
                : null
          }}
          onClick={() => handleSelectedConversation(conversation)}
        >
          <Avatar style={{ marginRight: '1em' }} />
          <Grid sx={styleAlignChatList}>
            <Grid sx={styleChatListItem}>
              <Name phoneNumber={conversation.phoneNumber.name} index={index} />
              <Typography>
                {getDateFormatted(conversation.createdTime) === 'yesterday'
                  ? t('yesterday')
                  : getDateFormatted(conversation.createdTime)}
              </Typography>
            </Grid>
            <Typography
              data-testid={`conversationListText${conversation.phoneNumber.id}`}
              sx={styleTypoLastMessage}
            >
              {conversation?.status.name === 'sent'
                ? `Vous: ${conversation.text}`
                : `${phoneName(conversation.phoneNumber.name)}: ${conversation.text}`}
            </Typography>
          </Grid>
          {!(conversation?.status.name === 'received' && !conversation?.readTime) ? null : (
            <Badge badgeContent={'  '} sx={styleNotification} color="primary" />
          )}
        </ListItemButton>
      </ListItem>
    </Grid>
  );
}

function Name({ phoneNumber, index }) {
  const azureUser = useSelector(selectAzureUserByPhone(phoneNumber));

  return (
    <ListItemText
      data-testid={`phoneNumber${index}`}
      primary={azureUser ? azureUser?.displayName : phoneNumber}
    />
  );
}

ChatListItem.propTypes = {
  index: PropTypes.number.isRequired,
  conversation: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ).isRequired,
  selectedConversation: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
  ).isRequired,
  handleSelectedConversation: PropTypes.func.isRequired
};

Name.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};
