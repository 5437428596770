export const getPath = (DN) => {
  const dcstring = DN.substring(0, DN.indexOf('DC=') - 1);
  let path = [];
  if (dcstring) {
    path = dcstring.split(',');
  }
  path = path.reverse();
  let beforeValue = DN.substring(DN.indexOf('DC='));
  path = path.map((p) => {
    beforeValue = `${p},${beforeValue}`;
    return beforeValue;
  });
  path.unshift(DN.substring(DN.indexOf('DC=')));
  return path;
};

export const changeAd = (ADTemp, i, pathTemp, value) => {
  if (i === pathTemp.length) {
    let result = { ...ADTemp };
    result = value;
    return result;
  }
  const index = ADTemp.findIndex((us) => us.DN === pathTemp[i]);
  const ADTemp2 = ADTemp;
  const result = changeAd(ADTemp2[index]?.subDN, i + 1, pathTemp, value);
  if (index > -1) ADTemp2[index].subDN = result;
  return ADTemp2;
};

// export const getChildrenOfParentDn = (ADTemp, pathTemp) => {
//   let index = 0;
//   let ADTemp2 = ADTemp;
//   for (let i = 0; i < pathTemp.length; i += 1) {
//     index = ADTemp2.findIndex((us) => us.DN === pathTemp[i]);
//     ADTemp2 = ADTemp2[index].subDN;
//   }
//   return ADTemp2;
// };

export const getLabelAdByDN = (DN) => {
  const firstElem = DN.split(',')[0];
  const label = firstElem.substring(firstElem.indexOf('=') + 1);
  return label;
};

export function compare(a, b) {
  if (a.label.localeCompare(b.label) === -1) {
    return -1;
  }

  return 1;
}

export const getIndicesByDistinguishedName = (array, distinguishedNameList) => {
  return array
    .map((obj, index) => {
      if (distinguishedNameList.includes(obj.distinguishedName)) {
        return index;
      }
      return undefined;
    })
    .filter((index) => index !== undefined);
};
export const getCNfromDN = (DN) => DN.slice(3, DN.indexOf(','));
