export const styleHeader = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};

export const styleSideBarBtn = {
  display: { xs: 'flex', sm: 'none' },
  marginLeft: '10px',
  alignItems: 'center',
  justifyContent: 'center'
};

export const styleImgFit = {
  display: { xs: 'none', sm: 'block' },
  width: '100%',
  height: '100%'
};

export const styleImageLang = {
  width: '16px',
  height: 'auto',
  margin: 'auto',
  verticalAlign: 'middle',
  marginLeft: '1em'
};
