import { React } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { loginAD } from '../../../redux/slices/userConnected';
import '../../../assets/style/views/Login.css';
import LoginUserPassword from './LoginUserPassword';
import LoginAzure from './LoginAzure';

export default function LoginProvider({ authProvider }) {
  const { t } = useTranslation();
  if (authProvider === 'AZURE_FRONT') {
    return <LoginAzure />;
  }
  if (authProvider === 'ACTIVE_DIRECTORY') {
    return (
      <LoginUserPassword
        loginName={t('ConnectWithAD')}
        loginAction={loginAD}
        loginType={authProvider}
      />
    );
  }
}

LoginProvider.propTypes = {
  authProvider: PropTypes.string.isRequired
};
