import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  useTheme
} from '@mui/material';
import {
  styleRowUserSelected,
  styleNameStyle,
  styleInnerCell
} from '../../assets/style/views/UserPages';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';

export default function LicenseNotifList({ licenseNotificationSorted, userDisplay }) {
  const theme = useTheme();
  return (
    <TableRow>
      <TableCell colSpan={2} sx={styleNameStyle}>
        <TableContainer>
          <Table aria-label="table products">
            <TableBody>
              {licenseNotificationSorted.licenseNotifList
                .reverse()
                .slice(0, userDisplay)
                .map((data, index) => (
                  <TableRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={`notifList${licenseNotificationSorted.id}${index}`}
                    sx={[styleNameStyle, styleInnerCell, styleRowUserSelected(theme)]}
                  >
                    <TableCell sx={[styleNameStyle, styleInnerCell]}>
                      <Typography
                        gutterBottom
                        data-testid={`notification${licenseNotificationSorted.id}${index}`}
                      >
                        {data.msg}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={[
                        styleNameStyle,
                        styleInnerCell,
                        { width: '300px', padding: '0 !important' }
                      ]}
                    >
                      <Typography
                        gutterBottom
                        data-testid={`date${licenseNotificationSorted.id}${index}`}
                      >
                        {getDateTimeFormatted(Number(data.date))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableCell>
    </TableRow>
  );
}

LicenseNotifList.propTypes = {
  userDisplay: PropTypes.number.isRequired,
  licenseNotificationSorted: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.number.isRequired,
            PropTypes.string.isRequired,
            PropTypes.bool.isRequired
          ])
        )
      )
    ])
  )
};

LicenseNotifList.defaultProps = {
  licenseNotificationSorted: []
};
