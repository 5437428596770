import React from 'react';
import { Box, Typography, useTheme, IconButton, Grid } from '@mui/material';
import { TreeItem } from '@mui/lab';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getSubDNObject } from '../../redux/slices/AdUsers';

export default function StyledTreeItem({
  labelText,
  LabelIcon,
  DN,
  AD,
  OC,
  setExpanded,
  expanded,
  setSelectedDNUser,
  isSelectDNUser,
  isForForm,
  ...other
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const useTreeItemStyles = {
    content: {
      flexDirection: 'row-reverse'
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      with: '100%',
      padding: theme.spacing(0.5, 0)
    },
    labelIcon: {
      marginRight: theme.spacing(1)
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1
    }
  };

  const handleOnClick = () => {
    let temp = [...expanded];
    if (temp.includes(DN)) {
      temp = temp.filter((e) => e !== DN);
    } else {
      temp.push(DN);
    }
    setExpanded(temp);
    dispatch(getSubDNObject({ DN, isForForm }));
  };

  return (
    <TreeItem
      key={DN}
      nodeId={DN}
      label={
        <Grid container>
          <Grid item xs={11}>
            <Box
              data-testid={`tree-item:${DN}`}
              onClick={handleOnClick}
              onKeyPress={handleOnClick}
              role="button"
              tabIndex={0}
              sx={useTreeItemStyles.labelRoot}
            >
              <LabelIcon color="action" sx={useTreeItemStyles.labelIcon} />
              <Typography variant="body2" sx={useTreeItemStyles.labelText}>
                {labelText}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            {OC?.includes('person') || OC?.includes('group') ? (
              <IconButton
                onClick={() => {
                  if (isSelectDNUser) {
                    setSelectedDNUser(DN);
                  }
                }}
              />
            ) : null}
          </Grid>
        </Grid>
      }
      sx={{
        '.MuiTreeItem-content': {
          flexDirection: 'row-reverse'
        }
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  labelText: PropTypes.string.isRequired,
  LabelIcon: PropTypes.elementType.isRequired,
  DN: PropTypes.string.isRequired,
  AD: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
  ).isRequired,
  OC: PropTypes.string,
  setExpanded: PropTypes.func.isRequired,
  expanded: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedDNUser: PropTypes.func,
  isSelectDNUser: PropTypes.bool.isRequired,
  isForForm: PropTypes.bool.isRequired
};

StyledTreeItem.defaultProps = {
  setSelectedDNUser: () => {
    return null;
  },
  OC: ''
};
