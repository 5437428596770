import socket from '../../socket/socket';

const AzureUsersService = {
  findAzureUsers() {
    return socket.emit('azure-users:find', 'true');
  },
  refreshAzureUsers() {
    return socket.emit('azure-users:refresh', 'true');
  }
};

export default AzureUsersService;
