import React from 'react';
import { List, Typography, ListItemButton, ListItem, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { styleListButton } from '../../../assets/style/components/SMS/ChatList';

export const styleList = {
  padding: 0,
  width: '100%'
};

export default function ListItems({ listItems, selectedItemId }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  return (
    <List sx={{ ...styleList, overflowY: 'auto' }}>
      {listItems?.length > 0 &&
        listItems.map(({ id, name, onClick }) => (
          <ListItem key={`${name}${id}`}>
            <ListItemButton
              data-testid={`ListButton${name}`}
              sx={{
                ...styleListButton(theme, selectedItemId === id),
                padding: '15px',
                borderRadius: '10px'
              }}
              onClick={async () => {
                await onClick(dispatch);
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>{name}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
}

ListItems.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  selectedItemId: PropTypes.number
};

ListItems.defaultProps = {
  listItems: [],
  selectedItemId: null
};
