import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Settings() {
  const { t } = useTranslation();

  return (
    <div>
      <Typography sx={(theme) => ({ color: theme.palette.colorBlack })}>{t('settings')}</Typography>
    </div>
  );
}
