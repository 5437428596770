import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import ReactJson from 'react-json-view';
import { useTheme } from '@emotion/react';
import errorRedirection from '../../utils/errorRedirection';
import LoadingWrapper from '../../components/layout/LoadingWrapper';
import HeaderPage from '../../components/HeaderPage';
import {
  getActionDetails,
  resetActionsState,
  selectedAction,
  selectIsLoading,
  requestAllActionsPagination
} from '../../redux/slices/ActionsHistory';
import StandardBox from '../../components/StandardBox';

export default function ActionsDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const actions = useSelector(selectedAction);
  const isActionsLoading = useSelector(selectIsLoading);
  const navigate = useNavigate();

  const theme = useTheme();

  const { idDetails } = useParams();
  const idInt = Number(idDetails);

  const dispatchGetActionsDetails = async () => {
    try {
      await dispatch(getActionDetails(idInt)).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const headerPageConfig = {
    text: {
      titleText: t('syneryxUsers'),
      bigTitleText: `${t('actionDetails')} ${actions.id}`,
      selectedItemsText: '',
      tagId: 'titleHeadPage'
    },
    buttonReturn: {
      isDisplayable: true,
      onClick: () => navigate('/actionsHistory')
    }
  };

  useEffect(() => {
    dispatchGetActionsDetails();
    dispatch(requestAllActionsPagination);
    return function cleanup() {
      dispatch(resetActionsState());
    };
  }, [dispatch]);

  return (
    <LoadingWrapper isChildDisplayable={!isActionsLoading}>
      <Grid sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <HeaderPage headerPageConfig={headerPageConfig} />
        <Grid sx={{ width: '100%', flex: '1', overflowY: 'auto' }}>
          <StandardBox
            title="details"
            ChildComponent={
              <ReactJson
                src={actions}
                name="actions"
                displayDataTypes={false}
                displayObjectSize={false}
                quotesOnKeys={false}
                style={{
                  overflowX: 'auto',
                  display: 'flex'
                }}
                theme={{
                  base00: theme.palette.colorMainLight, // background color
                  base01: theme.palette.colorBlack,
                  base02: theme.palette.colorMainLightHover, // straight thin left line + null background
                  base03: theme.palette.colorBlack,
                  base04: theme.palette.colorBlack, // displayObjectSize
                  base05: theme.palette.colorBlack,
                  base06: theme.palette.colorBlack,
                  base07: theme.palette.colorBlack, // key + {}
                  base08: theme.palette.colorBlack,
                  base09: theme.palette.colorMainHover, // data
                  base0A: theme.palette.colorMainHover, // null text color
                  base0B: theme.palette.colorBlack,
                  base0C: theme.palette.colorBlack, // array id color
                  base0D: theme.palette.colorMainSelected, // collapse icon color
                  base0E: theme.palette.colorMainHover, // boolean color
                  base0F: theme.palette.colorMainHover // int color + copy icon color
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </LoadingWrapper>
  );
}
