import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

function RootRoute({ isLogin, isRoot, isBackOffice }) {
  if (!isLogin) return <Navigate to="/login" />;
  return isRoot && isBackOffice ? <Outlet /> : <Navigate to="/error404" />;
}

RootRoute.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  isRoot: PropTypes.bool.isRequired,
  isBackOffice: PropTypes.bool.isRequired
};

export default RootRoute;
