import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthGroupService from '../../services/authGroups/AuthGroupsService';

export const initialState = {
  authGroups: [],
  permissions: [],
  selectedauthGroups: {},
  selectdPermission: [],
  isLoading: false,
  status: 'fulfilled'
};

export const getAllAuthGroups = createAsyncThunk('authGroups/getAllAuthGroups', async () => {
  const res = await AuthGroupService.getAuthGroups();
  return res.data;
});

export const createAuthGroups = createAsyncThunk(
  'authGroups/createAuthGroups',
  async (authgroup) => {
    const res = await AuthGroupService.createAuthGroup(
      authgroup.name,
      authgroup.providerId,
      authgroup.organizationType,
      authgroup.providerMappingId
    );
    return res.data;
  }
);

export const deleteAuthGroups = createAsyncThunk('authGroups/deleteAuthGroups', async (id) => {
  const res = await AuthGroupService.deleteAuthGroups(id);
  return res.data;
});

export const getAllPermissions = createAsyncThunk('authGroups/getAllPermissions', async () => {
  const res = await AuthGroupService.getAllPermissions();
  return res.data;
});

export const getPermissionsByidAuthGroup = createAsyncThunk(
  'authGroups/getPermissionsByidAuthGroup',
  async (idAuthGroup) => {
    const res = await AuthGroupService.getPermissionsByidAuthGroup(idAuthGroup);
    return res.data;
  }
);

export const createAuthPermissions = createAsyncThunk(
  'authGroups/createAuthPermissions',
  async ({ idAuthGroup, idPermissions }) => {
    const res = await AuthGroupService.createAuthPermissions(idAuthGroup, idPermissions);
    return res.data;
  }
);

export const deleteAuthPermissions = createAsyncThunk(
  'authGroups/deleteAuthPermissions',
  async ({ idAuthGroup, idPermissions }) => {
    const res = await AuthGroupService.deleteAuthPermissions(idAuthGroup, idPermissions);
    return res.data;
  }
);

export const AuthGroupsSlice = createSlice({
  name: 'authGroups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAuthGroups.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAllAuthGroups.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.authGroups = action.payload.authGroups;
      })
      .addCase(getAllAuthGroups.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(getAllPermissions.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.permissions = action.payload.authPermissions;
      })
      .addCase(getAllPermissions.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(getPermissionsByidAuthGroup.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getPermissionsByidAuthGroup.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectdPermission = action.payload.authPermissions;
      })
      .addCase(getPermissionsByidAuthGroup.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(deleteAuthGroups.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(deleteAuthGroups.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectdPermission = [];
        const tempAuthGroups = state.authGroups.filter(
          (authgroup) => authgroup.id !== action.meta.arg
        );
        state.authGroups = tempAuthGroups;
      })
      .addCase(deleteAuthGroups.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(createAuthGroups.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(createAuthGroups.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const newAuthGroup = action.payload.authGroup;
        state.authGroups.push(newAuthGroup);
      })
      .addCase(createAuthGroups.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(createAuthPermissions.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(createAuthPermissions.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const tempPerms = [...state.selectdPermission];
        const allPerms = [...state.permissions];
        const newPerms = tempPerms.concat(
          allPerms.filter((el) =>
            action.payload.authGroupPermissions.some((perm) => perm.permissionId === el.id)
          )
        );
        state.selectdPermission = newPerms.sort((a, b) => a.id - b.id);
      })
      .addCase(createAuthPermissions.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(deleteAuthPermissions.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(deleteAuthPermissions.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const { idPermissions } = action.meta.arg;
        let updatedSelectedPermission = state.selectdPermission;
        updatedSelectedPermission = updatedSelectedPermission.filter(
          (permission) => !idPermissions.includes(permission.id)
        );
        state.selectdPermission = updatedSelectedPermission;
      })
      .addCase(deleteAuthPermissions.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const { resetAuthGroups, resetSelectedAuthGroups } = AuthGroupsSlice.actions;

export const selectAuthGroups = (state) => state?.authGroups?.authGroups;

export const selectAuthGroupsById = (state) => {
  return state.authGroups?.selectedauthGroups;
};

export const selectGetAllPermissions = (state) => {
  return state?.authGroups?.permissions;
};
export const selectSelectedPermission = (state) => state.authGroups?.selectdPermission;
export const selectIsLoading = (state) => state.authGroups.isLoading;

export const selectIsTableLoaded = (state) => {
  if (state.authGroups.isLoading) {
    return false;
  }
  return true;
};

export default AuthGroupsSlice.reducer;
