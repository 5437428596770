export const styleStandardBoxGrid = (theme) => ({
  backgroundColor: theme.palette.colorMainLight,
  padding: '10px 5px',
  margin: '10px 50px 30px 50px',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.colorGray}`,
  boxShadow: `2px 2px 10px ${theme.palette.colorGray}`
});

export const styleHeaderWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '5px 5px 13px 5px',
  flexWrap: 'wrap'
};

export const styleHeaderTitle = (theme) => ({
  paddingLeft: '5px',
  marginLeft: '10px',
  color: theme.palette.colorSoftBlack,
  fontWeight: 'bold'
});

export const styleHeaderButtonsWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginLeft: '50px',
  marginBottom: '15px'
};

export const styleButton = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  borderRadius: '15px',
  textDecoration: 'none',
  width: { xs: '160px', sm: '160px', md: '100px' },
  height: '60px',
  marginLeft: '15px',
  marginTop: '10px',
  marginBottom: '10px',
  color: theme.palette.colorMainLight,
  backgroundColor: theme.palette.colorMain,
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.main,
    transform: 'scale(0.95, 0.95)',
    color: theme.palette.colorBlack,
    outline: `solid ${theme.palette.colorMainSelected}`
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    background: theme.palette.colorMain
  }
});

export const styleButton2 = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  borderRadius: '15px',
  textDecoration: 'none',
  width: { xs: '160px', sm: '160px', md: '150px' },
  height: '55px',
  marginLeft: '25px',
  color: theme.palette.colorMainLight,
  backgroundColor: theme.palette.colorMain,
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.main,
    transform: 'scale(0.95, 0.95)'
  },
  '&:focus': {
    outline: `solid ${theme.paltte.colorMainSelected}`
  },
  '&:disabled': {
    background: theme.palette.colorMain
  }
});

export const styleHeaderArrowBack = {
  display: 'flex',
  justifyContent: 'flex-end'
};

export const styleButtonArrowBack = (theme) => ({
  color: theme.palette.colorMainSelected,
  marginRight: '45px',
  fontSize: 'small'
});

export const styleButtonIconArrowBack = (theme) => ({
  backgroundColor: theme.palette.colorMainLight,
  borderRadius: '30px',
  padding: '10px'
});
