import { Autocomplete, Box, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ArrayField({ keyName, value, setModifiedItem, labelName, sxedit }) {
  const { t } = useTranslation();
  return (
    <Box key={keyName} sx={sxedit}>
      <InputLabel htmlFor="input">{labelName || keyName}</InputLabel>
      <Autocomplete
        multiple
        fullWidth
        value={value || []}
        options={[]}
        freeSolo
        onChange={(e, newValue) => {
          setModifiedItem(newValue, keyName);
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="standard"
            label={t('pressEnterToAdd')}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
      />
    </Box>
  );
}

ArrayField.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  setModifiedItem: PropTypes.func,
  labelName: PropTypes.string,
  sxedit: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ArrayField.defaultProps = {
  keyName: '',
  value: '',
  setModifiedItem: () => {},
  labelName: '',
  sxedit: null
};
