const hasPermissions = (permissions, codename) => {
  if (permissions.some((perm) => codename === perm.permission.codename)) return true;
  if (
    codename === 'GET_SYNCHRO_AD_AZURE_USERS' &&
    permissions.some((perm) => perm.permission.codename === 'GET_AZURE_USERS') &&
    permissions.some((perm) => perm.permission.codename === 'GET_AD_USERS')
  )
    return true;
  return false;
};

export default hasPermissions;
