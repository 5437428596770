import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import StandardBox from '../StandardBox';
import AdGroupList from '../adUserGroups/AdGroupList';
import { selectedUser } from '../../redux/slices/AdUsers';

export default function UserAdGroupDescription() {
  const user = useSelector(selectedUser);
  const listButton = [];
  return (
    <Grid>
      <StandardBox
        title="adGroups"
        buttons={listButton}
        ChildComponent={<AdGroupList selectedGroups={user.memberOf} />}
      />
    </Grid>
  );
}
