export const styleCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const styleWidth = (width) => ({
  width: `${width}px`
});

export const styleIconSize = (size) => ({
  fontSize: `${size}px`
});
