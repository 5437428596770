import { Box, InputLabel, TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function NumberField({ keyName, value, setModifiedItem, labelName, sxedit }) {
  const { t } = useTranslation();

  const removeArrow = {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none'
    },
    '& input[type=number]': {
      MozAppearance: 'textfield'
    },
    '.MuiFormHelperText-root': {
      marginLeft: '0px !important'
    }
  };

  return (
    <Box sx={sxedit}>
      <InputLabel htmlFor="input">{labelName || keyName}</InputLabel>
      <TextField
        required
        data-testid={`numberField${keyName}`}
        id={keyName}
        name="numberField"
        type="number"
        value={value}
        fullWidth
        onChange={(e) => {
          setModifiedItem(Number(e.target.value), keyName);
        }}
        autoFocus
        sx={removeArrow}
        helperText={t('EnterANumber')}
      />
    </Box>
  );
}
NumberField.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  setModifiedItem: PropTypes.func,
  labelName: PropTypes.string,
  sxedit: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

NumberField.defaultProps = {
  keyName: '',
  value: false,
  setModifiedItem: () => {},
  labelName: '',
  sxedit: null
};
