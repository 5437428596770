import { React } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getProfile, getPermissions } from '../../../redux/slices/userConnected';
import '../../../assets/style/views/Login.css';
import { loginAzure } from '../../../services/authentication/AzureAD/authAzure';
import AzureButton from '../../../components/buttons/AzureButton';

export default function LoginAzure() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLoginAzure = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(loginAzure());
      if (response.payload.token) {
        localStorage.setItem('token', response.payload.token);
        localStorage.setItem('isOpenSideBar', true);
        await dispatch(getProfile());
        await dispatch(getPermissions());
      }
    } catch (error) {
      console.error(error);
    }
  };
  return <AzureButton text={t('loginAzure')} type="button" onClick={handleLoginAzure} />;
}
