import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Input, InputAdornment, Divider, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import {
  styleActionIconButtonMobileUsers,
  styleActionIconButtonMobileUsersAzure,
  styleActionBackIconButtonMobileUsers,
  styleTypographyMobileTitleUsers,
  styleActionIconMobileUsersAzure,
  styleActionIconMobileUsers,
  styleTypographyMobileUsers,
  styleSearchBarMobile,
  styleTypographyMobileUnderTitleUsers,
  styleMobileDrawerUsers,
  styleSearchContainerMobile,
  styleButtonContainerMobile,
  styleTypographyButtonsType,
  styleDivider
} from '../assets/style/views/UserPages';

export default function HeaderMobileList({
  title,
  searchTitle,
  showSearchBarMobile,
  setShowSearchBarMobile,
  setSearchTerm,
  isOpenSideBar,
  itemsListIsLoading,
  handleSearchTerm,
  buttons
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const azureButtons = buttons.filter((item) => item.isAzure);
  const nonAzureButtons = buttons.filter((item) => !item.isAzure);

  return (
    <Grid container sx={styleMobileDrawerUsers(theme, isOpenSideBar)}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Typography sx={styleTypographyMobileUsers}>{title}</Typography>
        {showSearchBarMobile ? (
          <IconButton
            sx={styleActionBackIconButtonMobileUsers}
            onClick={() => {
              setShowSearchBarMobile(false);
              setSearchTerm('');
            }}
          >
            <ArrowBackIcon sx={styleActionIconMobileUsers} />
          </IconButton>
        ) : null}
      </Grid>
      {showSearchBarMobile ? (
        <>
          <Grid item xs={11}>
            <Typography sx={styleTypographyMobileTitleUsers}>{searchTitle}</Typography>
          </Grid>
          <Grid container direction="row" alignItems="center" sx={styleSearchContainerMobile}>
            <Grid container paddingBottom="0">
              <Grid item xs={11}>
                <Input
                  disabled={itemsListIsLoading}
                  type="text"
                  name="searchBar"
                  disableUnderline
                  placeholder={t('searchUser')}
                  onChange={handleSearchTerm}
                  fullWidth
                  data-testid="searchJiraUser"
                  sx={styleSearchBarMobile}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={styleTypographyMobileUnderTitleUsers}>
                  {t('infoSearchBarMobile')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid sx={{ overflow: 'auto', display: 'flex' }}>
          <Grid alignItems="center" sx={styleButtonContainerMobile}>
            <IconButton
              sx={styleActionIconButtonMobileUsers}
              onClick={() => {
                setShowSearchBarMobile(true);
              }}
            >
              <SearchIcon sx={styleActionIconMobileUsersAzure} />
            </IconButton>
            {nonAzureButtons.map((button) =>
              button.isDisplayable ? (
                <IconButton
                  key={button.datatestid}
                  disabled={button.disabled}
                  sx={styleActionIconButtonMobileUsersAzure}
                  onClick={button.onClick}
                >
                  {button.icon}
                </IconButton>
              ) : null
            )}
          </Grid>
          {azureButtons.length > 0 && <Divider sx={styleDivider} orientation="vertical" />}
          {azureButtons.length > 0 ? (
            <Grid>
              <Typography sx={styleTypographyButtonsType}>Azure</Typography>
              <Grid
                sx={{
                  display: 'flex',
                  gridGap: '20px'
                }}
              >
                {azureButtons.map((button) =>
                  button.isDisplayable ? (
                    <IconButton
                      key={button.datatestid}
                      disabled={button.disabled}
                      sx={styleActionIconButtonMobileUsersAzure}
                      onClick={button.onClick}
                    >
                      {button.icon}
                    </IconButton>
                  ) : null
                )}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      )}
    </Grid>
  );
}

HeaderMobileList.propTypes = {
  title: PropTypes.string.isRequired,
  searchTitle: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      datatestid: PropTypes.string,
      isDisplayable: PropTypes.bool,
      onClick: PropTypes.func,
      text: PropTypes.string
    })
  ),
  isOpenSideBar: PropTypes.string.isRequired,
  showSearchBarMobile: PropTypes.bool.isRequired,
  setShowSearchBarMobile: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  itemsListIsLoading: PropTypes.bool.isRequired
};

HeaderMobileList.defaultProps = {
  buttons: []
};
