import {
  getToday,
  getYesterday,
  isSameDay,
  isMoreThanOneHour,
  isNoValue,
  newDateUTC,
  getUserLang
} from './DateFunction';

const dateOption = { month: 'numeric', day: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE };
const timeOption = { hour: 'numeric', minute: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE };

export const getDateFormatted = (date1) => {
  if (isNoValue(date1)) return null;
  const lang = getUserLang();
  const date = newDateUTC(date1);
  const today = getToday();
  const yesterday = getYesterday();

  if (isSameDay(date, today)) {
    return new Date(date).toLocaleString(lang, timeOption);
  }
  if (isSameDay(date, yesterday)) {
    return 'yesterday';
  }
  return new Date(date).toLocaleString(lang, dateOption);
};

export const getCompareDateFormatted = (date1, date2) => {
  if (isNoValue(date2)) return null;
  const lang = getUserLang();
  const dateOne = newDateUTC(date1);
  const dateTwo = newDateUTC(date2);
  const today = getToday();
  if (isNoValue(date1)) {
    if (isSameDay(dateTwo, today)) {
      return new Date(dateTwo).toLocaleString(lang, timeOption);
    }
    return new Date(dateTwo).toLocaleString(lang, dateOption);
  }
  if (isSameDay(dateTwo, today)) {
    if (!isSameDay(dateOne, dateTwo)) {
      return new Date(dateTwo).toLocaleString(lang, timeOption);
    }
    if (isMoreThanOneHour(dateOne, dateTwo)) {
      return new Date(dateTwo).toLocaleString(lang, timeOption);
    }
    return null;
  }
  if (!isSameDay(dateOne, dateTwo)) {
    return new Date(dateTwo).toLocaleString(lang, dateOption);
  }
  return null;
};
