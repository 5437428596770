import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import StandardBox from '../StandardBox';
import InformationList from '../InformationList';
import { selectedResource } from '../../redux/slices/BoondManagerResources';

export default function BoondUserDescription() {
  const [displayColumns, setDisplayColumns] = useState([]);

  const user = useSelector(selectedResource);

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      const columnsDisplay = Object.keys(user)
        .filter((key) => key !== 'key' && key !== 'groups')
        .map((key) => {
          switch (key) {
            case 'active':
            case 'deleted':
              return { name: key, key, type: 'icon' };
            default:
              return { name: key, key, type: 'string' };
          }
        });
      setDisplayColumns(columnsDisplay);
    }
  }, [user]);

  return (
    <Grid>
      <StandardBox
        title="BoondManager"
        ChildComponent={<InformationList row={user} columns={displayColumns} />}
      />
    </Grid>
  );
}
