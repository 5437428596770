import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UsersAdminService from '../../services/admin/UsersAdminService';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';
import BoondResourcesService from '../../services/users/BoondResourcesService';
import { formatBoondResourceColumns } from '../../utils/columnsFormatters';

export const initialState = {
  boondResources: [],
  totalBoondResources: 0,
  isLoading: false,
  status: 'fulfilled',
  updatedAt: '',
  selectedBoondResource: {},
  columns: []
};

export const findBoondResources = createAsyncThunk('findBoondResources', () => {
  BoondResourcesService.findBoondResources();
});
export const refreshBoondResourcesList = createAsyncThunk('refreshBoondResources', () => {
  BoondResourcesService.refreshBoondResources();
});
export const getBoondResourceById = createAsyncThunk('getboondUser', async (id) => {
  const res = await UsersAdminService.getBoondResourceById(id);
  return res.data;
});

export const boondResourcesSlice = createSlice({
  name: 'boondResources',
  initialState,
  reducers: {
    resetSelectedResource: () => {
      return initialState;
    },
    addBoondResourcesToList: (state, action) => {
      state.boondResources = state.boondResources.concat(action.payload.users);
      formatBoondResourceColumns(state);
      state.totalBoondResources = state.boondResources.length;
    },
    setBoondResourcesListUpdatedTime: (state, action) => {
      const dateFormatted = getDateTimeFormatted(Number(action.payload));
      state.updatedAt = dateFormatted;
      state.isLoading = false;
    },
    resetBoondResourceList: (state) => {
      state.boondResources = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoondResourceById.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getBoondResourceById.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedBoondResource = action.payload.boondResource;
      })
      .addCase(getBoondResourceById.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })

      .addCase(findBoondResources.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.boondResources = [];
      })
      .addCase(findBoondResources.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(findBoondResources.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      })
      .addCase(refreshBoondResourcesList.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
        state.boondResources = [];
      })
      .addCase(refreshBoondResourcesList.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isLoading = false;
      })
      .addCase(refreshBoondResourcesList.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});
export const {
  resetSelectedResource,
  addBoondResourcesToList,
  resetBoondResourceList,
  setBoondResourcesListUpdatedTime
} = boondResourcesSlice.actions;

export const selectBoondResources = (state) => state.boondResources.boondResources;
export const selectBoondResourcesIsLoading = (state) => state.boondResources.isLoading;
export const selectTotalResources = (state) => state.boondResources.totalBoondResources;
export const selectedResource = (state) => state.boondResources.selectedBoondResource;
export const selectColumns = (state) => state.boondResources.columns;
export const selectBoondResourceUpdateTime = (state) => state.boondResources.updatedAt;

export const selectIsTableLoaded = (state) => {
  if (state.boondResources.isLoading || state.boondResources.columns.length === 0) {
    return false;
  }
  return true;
};

export const selectIsUserLoaded = (state) => {
  if (!state.boondResources.isLoading && state.boondResources.selectedBoondResource?.firstName) {
    return true;
  }
  return false;
};
export default boondResourcesSlice.reducer;
