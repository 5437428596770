import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getBoondResourceById,
  selectedResource,
  selectIsUserLoaded
} from '../../../redux/slices/BoondManagerResources';
import errorRedirection from '../../../utils/errorRedirection';
import BoondUserDescription from '../../../components/boondUser/BoondUserDescription';
import HeaderPage from '../../../components/HeaderPage';
import LoadingWrapper from '../../../components/layout/LoadingWrapper';

export default function BoondManagerResource() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector(selectedResource);
  const isUserLoaded = useSelector(selectIsUserLoaded);
  const dispatchGetBoondResource = async () => {
    try {
      await dispatch(getBoondResourceById(id)).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const headerPageConfig = {
    text: {
      titleText: t('BoondUser'),
      bigTitleText: `${user?.firstName} ${user?.lastName}`,
      selectedItemsText: '',
      tagId: 'BoondUser'
    },
    buttonReturn: {
      isDisplayable: true,
      onClick: () => navigate('/boondManagerResources')
    }
  };

  useEffect(() => {
    dispatchGetBoondResource();
  }, [dispatch]);

  return (
    <LoadingWrapper isChildDisplayable={isUserLoaded}>
      <HeaderPage headerPageConfig={headerPageConfig} />
      <Grid>
        <BoondUserDescription />
      </Grid>
    </LoadingWrapper>
  );
}
