export const styleRowGrid = {
  display: 'flex',
  margin: '5px 0px 5px 0px',
  padding: '5px 5px 0px 30px'
};

export const styleEllipsis = {
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '20px'
};

export const styleInformationNameText = (theme) => ({
  color: theme.palette.colorBlack,
  fontSize: '20px'
});

export const styleInformationListWrapper = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  height: '400px',
  color: theme.palette.colorMainSelected,
  overflow: 'auto'
});

export const styleList = (theme) => ({
  padding: 0,
  maxWidth: 360,
  overflow: 'auto',
  maxHeight: 300,
  border: `1px solid ${theme.palette.colorSecondaryLight}`
});
