import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllParameters,
  selectParameters,
  selectSelectedParameter,
  updateParameterById,
  selectParameter
} from '../../../../redux/slices/Parameters';
import BackOfficeDesktop from '../../../../components/BackOffice/BackOfficeDesktop';

const formatType = (type) => {
  if (type === 'string') return 'edit';
  if (type === 'image') return 'image';

  return 'password';
};
const formatted = (parameter) => {
  if (parameter)
    return [
      {
        keyName: parameter.key,
        type: formatType(parameter.type),
        value: parameter.value
      }
    ];
  return [];
};
const formatParametersName = (selectedParameters) => {
  if (selectedParameters)
    return selectedParameters.map((parameter) => {
      return {
        id: parameter.id,
        name: parameter.key,
        onClick: async (dispatch) => {
          await dispatch(selectParameter(parameter.id));
        }
      };
    });
  return [];
};

export default function Parameters() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modifiedParameter, setModifiedParameter] = useState({});
  const [file, setFile] = useState(null);
  const isItemDisplayable = Object.keys(modifiedParameter).length;
  const parameterList = formatParametersName(useSelector(selectParameters));

  const selectedParameter = useSelector(selectSelectedParameter);

  const handleClickUpdateButton = async () => {
    const formData = new FormData();
    formData.append('type', modifiedParameter.type);
    formData.append('key', modifiedParameter.key);
    formData.append('value', modifiedParameter.value);
    if (modifiedParameter.type === 'image') {
      formData.append('file', file);
    }
    await dispatch(updateParameterById({ id: modifiedParameter.id, formData }));
  };

  useEffect(() => {
    dispatch(getAllParameters());
  }, [dispatch]);

  useEffect(() => {
    setModifiedParameter(selectedParameter);
    setFile(null);
  }, [selectedParameter]);
  return (
    <Grid>
      <BackOfficeDesktop
        isItemDisplayable={!!isItemDisplayable}
        listItems={parameterList}
        selectedItemId={selectedParameter?.id}
        fieldList={formatted(modifiedParameter)}
        selectedItemName={selectedParameter?.key}
        setModifiedItem={(newValue) => {
          setModifiedParameter({ ...modifiedParameter, value: newValue });
        }}
        isItemModified={modifiedParameter === selectedParameter}
        handleClickCancelButton={() => {
          setModifiedParameter(selectedParameter);
        }}
        handleClickUpdateButton={handleClickUpdateButton}
        titleBodyName={t('manageMyParameters')}
        titleHeader={t('myParameters')}
        file={file}
        setFile={setFile}
      />
    </Grid>
  );
}
