import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { styleCopyButton, styleIconeSize } from '../../assets/style/components/buttons/CopyButton';

export default function CopyButton({ copyValue }) {
  const copyContents = (value) => {
    navigator.clipboard.writeText(value.copyValue);
  };

  return (
    <Button sx={styleCopyButton} onClick={() => copyContents({ copyValue })}>
      <ContentCopyIcon sx={styleIconeSize} />
    </Button>
  );
}

CopyButton.propTypes = {
  copyValue: PropTypes.string
};
CopyButton.defaultProps = {
  copyValue: null
};
