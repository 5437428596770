import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, DialogTitle } from '@mui/material';
import { styleLoadingIcon } from '../../assets/style/views/UserPages';

export default function LoadingWrapper(props) {
  const { isChildDisplayable, children } = props;

  if (isChildDisplayable) {
    return children;
  }
  return (
    <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress sx={styleLoadingIcon} />
    </DialogTitle>
  );
}

LoadingWrapper.propTypes = {
  isChildDisplayable: PropTypes.bool.isRequired,
  children: PropTypes.node
};

LoadingWrapper.defaultProps = {
  children: <div />
};
