const HeaderManagerHandler = {
  addAuthorization(config) {
    const Config = config;
    const token = localStorage.getItem('token');
    if (token) {
      Config.headers.Authorization = `Bearer ${token}`;
    }
    const dataSession = localStorage.getItem('dataSession');
    if (dataSession) {
      Config.headers.DataSession = dataSession;
    }
    return Config;
  },
  logoutOnForbiddenError(error, store) {
    if (error.response.status === 401 && !error.response.data.success) {
      localStorage.removeItem('token');
      localStorage.removeItem('dataSession');
      localStorage.removeItem('isBackOffice');
      store.dispatch({ type: 'user/logout' });
    }
    if (error.response.status !== 401) {
      switch (error.response.status) {
        case 403:
          store.dispatch({ type: 'user/error403' });
          break;
        case 400:
          store.dispatch({ type: 'user/error400' });
          break;
        case 424:
          store.dispatch({ type: 'user/error424' });
          break;
        default:
          store.dispatch({ type: 'user/error404' });
          break;
      }
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      code: error.response.status.toString(),
      message: error.response.data.key
    });
  }
};

export default HeaderManagerHandler;
