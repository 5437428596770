import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';
import { deleteAppList } from '../../../../redux/slices/AppList';

function PopupDeleteAppList({ openPop, onClose, resetSelectedApps, onAppListIdsUpdate }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = async () => {
    setTimeout(async () => {
      try {
        const res = await dispatch(deleteAppList({ appListIdList: onAppListIdsUpdate }));
        if (res?.payload?.success === true) {
          resetSelectedApps();
          onClose();
        }
      } catch (error) {
        console.error(error);
      }
    });
  };
  return (
    <Dialog
      open={openPop}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={[{ overflowWrap: 'break-word', maxWidth: '387.11px' }]}>
        <DialogContentText id="alert-dialog-slide-description" textAlign="center">
          {`${t('delete')} ${'AppList'} ?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <CustomizedButton
          datatestid="cancelDeleteAppList"
          variant="contained"
          onClick={onClose}
          text={t('cancel')}
        />
        <CustomizedButton
          variant="contained"
          datatestid="confirmDeleteAppList"
          onClick={handleClick}
          text={t('confirm')}
        />
      </DialogActions>
    </Dialog>
  );
}

export default PopupDeleteAppList;

PopupDeleteAppList.propTypes = {
  openPop: PropTypes.bool,
  onClose: PropTypes.func,
  onAppListIdsUpdate: PropTypes.arrayOf(PropTypes.number),
  resetSelectedApps: PropTypes.func.isRequired
};

PopupDeleteAppList.defaultProps = {
  openPop: false,
  onClose: () => {},
  onAppListIdsUpdate: () => {}
};
