import socket from '../../socket/socket';

const AdUsersService = {
  findAdUsers() {
    return socket.emit('ad-users:find', 'true');
  },
  refreshAdUsers() {
    return socket.emit('ad-users:refresh', 'true');
  }
};

export default AdUsersService;
