export const styleCommentBox = (theme) => ({
  border: `1px solid ${theme.palette.colorGray}`,
  borderLeft: `5px solid ${theme.palette.colorGray}`,
  borderRadius: '5px',
  padding: '5px 10px 5px 10px',
  margin: '5px'
});

export const styleBorderLeft = (theme) => ({
  borderLeft: `5px solid ${theme.palette.colorMainSelected}`,
  backgroundColor: theme.palette.colorSecondaryLight
});

export const stylePaddingTop = {
  paddingTop: '2 em'
};

export const StyleGridSize = {
  maxHeight: '22em',
  overflow: 'auto',
  marginTop: '5px'
};

export const styleCommentBody = {
  marginLeft: '15px'
};

export const stylePaddingBottomTop = {
  paddingTop: '0.6em',
  paddingBottom: '0.6em'
};

export const styleFlexResponsive = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
  justifyContent: {
    xs: 'center',
    sm: 'center',
    md: 'flex-end',
    lg: 'flex-end'
  },
  alignItems: { xs: 'flex-end', sm: 'flex-end', md: 'center', lg: 'center' }
};

export const styleMarginTop = { marginTop: '20px' };

export const styleLink = (theme) => ({
  overflow: 'hidden',
  color: theme.palette.colorMain
});

export const styleMarginTopBot = { margin: '0px 5px 0px 5px' };
