import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';
import { createAuthGroups } from '../../../../redux/slices/AuthGroups';
import {
  getAllProviders,
  getAllProvidersNames,
  providersNames,
  selectProviders
} from '../../../../redux/slices/Providers';
import { providersOrganizationType } from '../../../../utils/fieldListProviders';

export default function PopUpAddAuthGroups({ open, handleClose, selectCreatedGroup }) {
  const { t } = useTranslation();
  const [authGroup, setAuthGroup] = useState({});
  const dispatch = useDispatch();

  const providersNamesSelector = useSelector(providersNames);
  const providers = useSelector(selectProviders);

  const providersSelectList = providers
    .filter((provider) => provider.isAuth)
    .map((provider) => {
      const providerNameByProvider = providersNamesSelector.find(
        (providername) => providername.id === provider.providerNameId
      );
      return { providerId: provider.id, providerName: providerNameByProvider };
    });

  const closePopup = () => {
    setAuthGroup({});
    handleClose();
  };

  const dispatchGetProviders = async () => {
    await dispatch(getAllProviders()).unwrap();
  };

  const dispatchGetProvidersNames = async () => {
    await dispatch(getAllProvidersNames());
  };

  useEffect(() => {
    dispatchGetProviders();
    dispatchGetProvidersNames();
  }, [dispatch]);

  const dispatchAuthGroups = async () => {
    if (authGroup.name.trim() !== '') {
      const authgroup = {
        name: authGroup.name,
        providerId: authGroup.providerId,
        organizationType: authGroup.organizationType,
        providerMappingId: authGroup.providerMappingId
      };
      const result = await dispatch(createAuthGroups(authgroup));
      await selectCreatedGroup(result.payload.authGroup);
      closePopup();
    }
  };

  const handleChangeProviders = (event) => {
    const selectedProviderName = event.target.value;
    const providerNameTaken = providersSelectList.find(
      (elem) => elem.providerName.name === selectedProviderName
    );
    setAuthGroup((authGroupinput) => ({
      ...authGroupinput,

      providerName: providerNameTaken?.providerName,
      providerId: providerNameTaken?.providerId,

      organizationType:
        providersOrganizationType[selectedProviderName]?.length === 1
          ? providersOrganizationType[selectedProviderName]?.[0]
          : ''
    }));
  };

  return (
    <Dialog open={open} fullWidth>
      <Grid item xs={8} sx={{ marginLeft: '20px', marginRight: '20px' }}>
        <DialogTitle>{`${t('addRole')} :`}</DialogTitle>
        <form>
          <Grid item xs={8} sx={{ marginLeft: '20px', marginRight: '20px', marginBottom: '10px' }}>
            <InputLabel htmlFor="authGroupName">Role name</InputLabel>
            <TextField
              id="addAuthGroupName"
              data-testid="addAuthGroupName"
              sx={{ width: '100%', height: '100%', marginBottom: '10px' }}
              onChange={(e) =>
                setAuthGroup((authGroupinput) => ({
                  ...authGroupinput,
                  name: e.target.value
                }))
              }
            />
            <InputLabel htmlFor="providerName">Provider name</InputLabel>
            <TextField
              id="addAuthGroupProviderName"
              value={authGroup?.providerName?.name || ''}
              data-testid="addAuthGroupProviderName"
              sx={{ width: '100%', height: '100%' }}
              onChange={handleChangeProviders}
              select
              helperText="optionnal"
            >
              {providersSelectList.map(({ providerId, providerName }) => (
                <MenuItem
                  key={providerId}
                  id={providerId}
                  value={providerName?.name}
                  data-testid={`menuItem${providerName?.name}`}
                >
                  {providerName?.name}
                </MenuItem>
              ))}
            </TextField>

            {authGroup?.providerName?.name ? (
              <>
                <InputLabel htmlFor="organizationType">OrganizationType</InputLabel>
                <Select
                  id="organizationType"
                  displayEmpty
                  value={authGroup.organizationType}
                  data-testid="addAuthGroupOrganizationType"
                  sx={{ width: '100%', height: '100%' }}
                  disabled={providersOrganizationType[authGroup?.providerName?.name].length === 1}
                  onChange={(e) =>
                    setAuthGroup((authGroupinput) => ({
                      ...authGroupinput,
                      organizationType: e.target.value
                    }))
                  }
                >
                  {providersOrganizationType[authGroup?.providerName?.name].map((type) => (
                    <MenuItem key={type} id={type} value={type} data-testid={`menuItem${type}`}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>

                <InputLabel htmlFor="providerMappingId" sx={{ marginTop: '10px' }}>
                  ProviderMappingId
                </InputLabel>
                <TextField
                  id="addAuthGroupProviderMappingId"
                  data-testid="addAuthGroupProviderMappingId"
                  sx={{ width: '100%', height: '100%' }}
                  onChange={(e) =>
                    setAuthGroup((authGroupinput) => ({
                      ...authGroupinput,
                      providerMappingId: e.target.value
                    }))
                  }
                />
              </>
            ) : null}
          </Grid>
        </form>
        <DialogActions sx={{ margin: '20px', justifyContent: 'center' }}>
          <CustomizedButton
            variant="contained"
            datatestid="deleteAuthGroup"
            onClick={() => closePopup()}
            text={t('cancel')}
          />
          <CustomizedButton
            variant="contained"
            datatestid="confirmCreateAuthGroup"
            type="submit"
            onClick={() => dispatchAuthGroups()}
            text={t('save')}
          />
        </DialogActions>
      </Grid>
    </Dialog>
  );
}

PopUpAddAuthGroups.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectCreatedGroup: PropTypes.func
};

PopUpAddAuthGroups.defaultProps = {
  selectCreatedGroup: () => {}
};
