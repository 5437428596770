import { createSlice, current, createAsyncThunk } from '@reduxjs/toolkit';
import MessageService from '../../services/sms/MessageService';

export const initialState = {
  conversationsList: [],
  selectedConversation: {
    phoneNumberId: null,
    phoneNumber: null,
    messages: [],
    isRead: 0
  },
  notReadCounter: 0,
  isTyping: false
};

export const rcsHandler = createAsyncThunk('rcsHandler', (selectedPhoneNumber) => {
  MessageService.rcsHandler(selectedPhoneNumber);
});

export const sendMessage = createAsyncThunk('sendMessage', (newMessage) => {
  MessageService.sendMessage(newMessage);
});

export const findConversations = createAsyncThunk('findConversations', () => {
  MessageService.findConversations();
});

export const findOneConversation = createAsyncThunk(
  'findOneConversation',
  (phoneNumberId, phoneNumberName) => {
    MessageService.findOneConversation(phoneNumberId, phoneNumberName);
  }
);

export const conversationList = createAsyncThunk('conversationList', () => {
  MessageService.conversationList();
});

export const findNotReadMessages = createAsyncThunk('findNotReadMessages', async () => {
  await MessageService.findNotReadMessages();
});

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setConversationList: (state, action) => {
      state.conversationsList = action.payload;
    },
    updateConversationList: (state, action) => {
      let isNewConversation = true;

      for (let i = 0; i < state.conversationsList.length; i += 1) {
        if (
          state.conversationsList[i].phoneNumber.id === action.payload.phoneNumber.id ||
          state.conversationsList[i].phoneNumber.name === action.payload.phoneNumber.name
        ) {
          state.conversationsList.splice(i, 1);
          if (state.selectedConversation.phoneNumberId === action.payload.phoneNumber.id) {
            state.conversationsList.unshift({
              ...action.payload,
              readTime: new Date().toDateString()
            });
          } else {
            state.conversationsList.unshift(action.payload);
          }
          isNewConversation = false;
        }
      }
      if (isNewConversation) state.conversationsList.unshift(action.payload);
    },
    updateReadTimeConversationListById: (state, action) => {
      state.notReadCounter = 0;
      for (let i = 0; i < state.conversationsList.length; i += 1) {
        if (state.conversationsList[i].phoneNumber.id === action.payload) {
          state.conversationsList[i] = {
            ...state.conversationsList[i],
            readTime: new Date().toDateString()
          };
        }
        if (!state?.conversationsList[i]?.readTime) {
          state.notReadCounter += 1;
        }
      }
    },
    setIsNotRead: (state) => {
      state.selectedConversation.isRead = 1;
    },
    setIsRead: (state) => {
      state.selectedConversation.isRead = 0;
    },
    setIsTyping: (state, action) => {
      state.isTyping = action.payload;
    },
    setNotReadCounter: (state, action) => {
      state.notReadCounter = action.payload.count;
    },
    setSelectedConversation: (state, action) => {
      state.selectedConversation = {
        phoneNumberId: action.payload.phoneNumberId,
        phoneNumber: action.payload.phoneNumber,
        messages: current(state).selectedConversation.messages
      };
    },
    setMessages: (state, action) => {
      state.selectedConversation.messages = action.payload;
      if (!action?.payload[action.payload.length - 1]?.readTime) {
        state.selectedConversation.isRead = 1;
      }
    },
    updateSelectedMessages: (state, action) => {
      for (let i = 0; i < state.selectedConversation.messages.length; i += 1) {
        if (
          state.selectedConversation.messages[i].messageId === null &&
          action.payload.messageId !== null &&
          state.selectedConversation.messages[i].text === action.payload.text
        ) {
          state.selectedConversation.messages[i] = action.payload;
          return;
        }
        if (
          state.selectedConversation.messages[i].messageId === action.payload.messageId &&
          state.selectedConversation.messages[i].messageId !== null &&
          state.selectedConversation.messages[i].text === action.payload.text
        ) {
          state.selectedConversation.messages[i] = { ...action.payload };
          return;
        }
      }
      state.selectedConversation.messages.push(action.payload);
      if (action?.payload[action.payload.length - 1]?.readTime) {
        state.selectedConversation.isRead = 0;
      }
    },
    resetMessageState: () => {
      return initialState;
    }
  }
});

export const {
  setConversationList,
  updateConversationList,
  setSelectedConversation,
  setMessages,
  selectedConversation,
  updateSelectedMessages,
  setIsTyping,
  setIsRead,
  setIsNotRead,
  updateReadTimeConversationListById,
  updateWithReceivedMessage,
  resetMessageState,
  setNotReadCounter
} = messagesSlice.actions;

export const selectConversationsList = (state) => state.messages.conversationsList;
export const selectPickedConversation = (state) => state.messages.selectedConversation;
export const selectMessages = (state) => state.messages.selectedConversation.messages;
export const selectNotReadCounter = (state) => state.messages.notReadCounter;
export const selectIsTyping = (state) => state.messages.isTyping;

export default messagesSlice.reducer;
