import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProvidersService from '../../services/providers/ProvidersService';

export const initialState = {
  providersNames: [],
  providers: [],
  selectedProvider: {},
  isLoading: false,
  status: 'fulfilled'
};

export const getAllProviders = createAsyncThunk('getProviders/list', async () => {
  const res = await ProvidersService.getProviders();
  return res.data;
});

export const getProviderDetails = createAsyncThunk('provider/id', async (id) => {
  const res = await ProvidersService.getProvider(id);
  return res.data;
});

export const deleteProvider = createAsyncThunk('provider/deleteProvider', async (id) => {
  const res = await ProvidersService.deleteProvider(id);
  return res.data;
});

export const updateProvider = createAsyncThunk('provider/update/id', async (payload) => {
  const res = await ProvidersService.updateProvider(payload.id, payload.body);
  return res.data;
});

export const createProvider = createAsyncThunk('provider/create', async (payload) => {
  const { providerNameId, body, isAuth } = payload;
  const res = await ProvidersService.createProvider(providerNameId, body, isAuth);
  return res.data;
});

export const getAllProvidersNames = createAsyncThunk('getProvidersNames/list', async () => {
  const res = await ProvidersService.getProvidersNames();
  return res.data;
});

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    resetProviders: () => {
      return initialState;
    },
    resetSelectedProvider: (state) => {
      state.selectedProvider = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProviders.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAllProviders.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.providers = action.payload.providers;
      })
      .addCase(getAllProviders.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(getProviderDetails.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getProviderDetails.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedProvider = action.payload.provider;
      })
      .addCase(getProviderDetails.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(deleteProvider.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(deleteProvider.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.selectedProvider = {};
        const tempProviders = state.providers.filter((provider) => provider.id !== action.meta.arg);
        state.providers = tempProviders;
      })
      .addCase(deleteProvider.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(getAllProvidersNames.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(getAllProvidersNames.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        state.providersNames = action.payload.providersNames;
      })
      .addCase(getAllProvidersNames.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });

    builder
      .addCase(updateProvider.pending, (state) => {
        state.status = 'pending';
        state.isLoading = true;
      })
      .addCase(updateProvider.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isLoading = false;
        const tempSelectedProvider = { ...state.selectedProvider };
        tempSelectedProvider.providerAuth = action.meta.arg.body.providerAuth;
        state.selectedProvider = tempSelectedProvider;
      })
      .addCase(updateProvider.rejected, (state) => {
        state.status = 'rejected';
        state.isLoading = false;
      });
  }
});

export const { resetProviders, resetSelectedProvider } = providersSlice.actions;

export const selectProviders = (state) => state?.providers?.providers;
export const selectSelectedProvider = (state) => state.providers?.selectedProvider;
export const providersNames = (state) => state.providers?.providersNames;

export default providersSlice.reducer;
