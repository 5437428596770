import React, { useState } from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CustomizedButton from '../buttons/CustomizedButton';
import AzureLicensesServices from './AzureLicensesServices';
import {
  stylePadding,
  styleFullWidth,
  stylePaddingRight
} from '../../assets/style/components/popup/AzureLicensesPopup';
import { selectAzureLicensesAvailableByAppliesTo } from '../../redux/slices/AzureLicenses';
import AzureLicensesTab from './AzureLicensesTab';

export default function AzureSetLicenses({ closePopup, assignLicense, numberOfUsers }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [selectedLicenses, setSelectedLicenses] = useState([]);
  const [unassignServiceMap, setUnassignServiceMap] = useState(new Map());
  const [searchTerm, setSearchTerm] = useState('');

  const azureLicenses = useSelector(
    selectAzureLicensesAvailableByAppliesTo(numberOfUsers, 'User', 'Enabled', '')
  );
  const allAzureLicenses = useSelector(
    selectAzureLicensesAvailableByAppliesTo(numberOfUsers, 'User', 'Enabled', '')
  );
  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];
    const tempRows = copyRows.map(
      (rowIndex) => allAzureLicenses[rowIndex === null ? 0 : rowIndex].skuId
    );
    setSelectedLicenses(tempRows);
  };

  return (
    <Grid sx={stylePadding}>
      <Grid display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="h4" sx={stylePaddingRight}>
          {t('selectLicense')}
        </Typography>
        <Tooltip title={t('infoLicensesDisplay')} placement="bottom-start">
          <InfoOutlinedIcon />
        </Tooltip>
      </Grid>
      <Grid>
        {isLastStep ? (
          <AzureLicensesServices
            selectedLicenses={selectedLicenses}
            unassignServiceMap={unassignServiceMap}
            setUnassignServiceMap={setUnassignServiceMap}
            isAssignMode
          />
        ) : (
          <AzureLicensesTab
            page={page}
            setPage={setPage}
            setSelectedLicenses={setTableSelectedRowsRealData}
            checkboxKey="skuId"
            numberOfUsers={numberOfUsers}
            activeCheckBox
            azureLicenses={azureLicenses}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        )}
        <Grid display="flex" justifyContent="space-around" sx={styleFullWidth}>
          <CustomizedButton
            onClick={closePopup}
            text={t('cancel')}
            datatestid="CancelLicenseStep"
          />
          {isLastStep ? (
            <Grid display="flex" justifyContent="space-around">
              <CustomizedButton
                onClick={() => {
                  setIsLastStep(false);
                }}
                text={t('previous')}
                datatestid="PreviousLicenseStep"
              />
              <CustomizedButton
                onClick={() => assignLicense(selectedLicenses, unassignServiceMap)}
                text={t('attribute')}
                datatestid="AttributeLicense"
              />
            </Grid>
          ) : (
            <CustomizedButton
              disabled={selectedLicenses.length === 0}
              onClick={() => {
                setIsLastStep(true);
              }}
              text={t('next')}
              datatestid="NextLicenseStep"
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

AzureSetLicenses.propTypes = {
  closePopup: PropTypes.func.isRequired,
  assignLicense: PropTypes.func.isRequired,
  numberOfUsers: PropTypes.number.isRequired
};
