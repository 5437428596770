import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import {
  getAllAuthGroups,
  selectAuthGroups,
  selectSelectedPermission,
  deleteAuthGroups,
  deleteAuthPermissions,
  getPermissionsByidAuthGroup,
  selectIsTableLoaded,
  selectAuthGroupsById,
  getAllPermissions
} from '../../../../redux/slices/AuthGroups';
import BackOfficeDesktop from '../../../../components/BackOffice/BackOfficeDesktop';
import errorRedirection from '../../../../utils/errorRedirection';
import PopUpAddAuthGroups from './PopUpAddAuthGroups';
import { formatColumnsForTable } from '../../../../components/datalist/ComponentsInTable';
import PopUpDeleteAuthGroup from './PopUpDeleteAuthGroup';
import PopUpAddPermissions from './PopUpAddPermissions';
import PopUpDeletePermissions from './PopUpDeletePermissions';

const formatAuthGroups = (authGroups, setAuthGroupSelected) => {
  if (authGroups?.length) {
    return authGroups.map((authGroup) => {
      return {
        id: authGroup?.id,
        name: authGroup?.name,
        onClick: async (dispatch) => {
          await dispatch(getPermissionsByidAuthGroup(authGroup?.id));
          setAuthGroupSelected(authGroup);
        }
      };
    });
  }
  return [];
};

function AuthGroups() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [popupDeleteAuthGroup, setPopupDeleteAuthGroup] = useState(false);
  const [popupDeletePermission, setPopupDeletePermission] = useState(false);
  const [popupAdd, setPopupAdd] = useState(false);
  const [popupAddPermission, setPopupAddPermission] = useState(false);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [authGroupSelected, setAuthGroupSelected] = useState({});

  const authGroupsLists = formatAuthGroups(useSelector(selectAuthGroups), setAuthGroupSelected);
  const selectAuthGroupsByObject = useSelector(selectAuthGroupsById);
  const PermissionAuthGroupsColumn = useSelector(selectSelectedPermission);
  const isTableLoaded = useSelector(selectIsTableLoaded);

  const selectCreatedGroup = async (authGroup) => {
    await dispatch(getPermissionsByidAuthGroup(authGroup.id));
    setAuthGroupSelected(authGroup);
  };

  const isItemDisplayableDataTable = !!authGroupSelected.id;

  const buttons = [
    {
      disabled: false,
      datatestid: 'removeAuthGroup',
      isDisplayable: true,
      onClick: () => setPopupDeleteAuthGroup(true),
      text: t('deleteRole')
    },
    {
      disabled: false,
      datatestid: 'addPermission',
      isDisplayable: true,
      onClick: () => setPopupAddPermission(true),
      text: t('AddPermission')
    },

    {
      disabled: tableSelectedRows.length === 0,
      datatestid: 'deletePermission',
      isDisplayable: true,
      onClick: () => setPopupDeletePermission(true),
      text: t('deletePermission')
    }
  ];

  const dispatchGetAuthGroups = async () => {
    try {
      await dispatch(getAllAuthGroups()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };
  const dispatchGetAllPermissions = async () => {
    try {
      await dispatch(getAllPermissions()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const dispatchDeleteAuthGroup = async (id) => {
    await dispatch(deleteAuthGroups(id));
  };

  const dispatchDeletePermissions = async () => {
    await dispatch(
      deleteAuthPermissions({
        idAuthGroup: authGroupSelected.id,
        idPermissions: tableSelectedRows
      })
    );
    setSelectedIndexRows([]);
    setTableSelectedRows([]);
  };

  useEffect(() => {
    dispatchGetAuthGroups();
    dispatchGetAllPermissions();
  }, [dispatch]);

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map((rowIndex) => {
      return PermissionAuthGroupsColumn[rowIndex === null ? 0 : rowIndex]?.id;
    });
    setTableSelectedRows(tempRows);
  };

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const columnOptions = [
    { name: 'codename', display: true },
    { name: 'desc', display: true, filter: true }
  ];

  const formatedColumns = formatColumnsForTable(
    ['codename', 'desc'],
    PermissionAuthGroupsColumn,
    columnOptions,
    t
  );

  const authGroupProviderMapping = authGroupSelected.authGroupMapping
    ? {
        providerName: authGroupSelected?.authGroupMapping?.provider?.name,
        organizationType: authGroupSelected?.authGroupMapping?.organizationType,
        providerMappingId: authGroupSelected?.authGroupMapping?.providerMappingId
      }
    : null;

  const desktopConfig = {
    actions: {},

    buttons,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: PermissionAuthGroupsColumn
    },

    loading: {
      isTableDisplayable: isTableLoaded
    },

    tables: {
      formatedColumns,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {},

    text: {
      titleText: t('authGroups'),
      bigTitleText: t('managePermissions'),
      selectedItemsText: t('selectedPermission'),
      tagId: 'AuthGroup'
    },

    isDisableCheckBox: false,
    searchBarPlaceOlder: t('searchPermission'),
    hideRefresh: true,
    isPositionFixed: true,
    additionalInformationInField: authGroupProviderMapping
  };

  return (
    <div>
      <PopUpAddAuthGroups
        handleClose={() => setPopupAdd(false)}
        open={popupAdd}
        selectCreatedGroup={selectCreatedGroup}
      />
      <PopUpDeleteAuthGroup
        openPop={popupDeleteAuthGroup}
        onClose={() => setPopupDeleteAuthGroup(false)}
        onClickValidate={async () => {
          await dispatchDeleteAuthGroup(authGroupSelected.id);
          setAuthGroupSelected({});
        }}
        name={selectAuthGroupsByObject?.authGroup?.name}
      />
      <PopUpAddPermissions
        handleClose={() => setPopupAddPermission(false)}
        authGroupId={authGroupSelected.id}
        open={popupAddPermission}
      />
      <PopUpDeletePermissions
        openPop={popupDeletePermission}
        onClose={() => setPopupDeletePermission(false)}
        onClickValidate={async () => dispatchDeletePermissions()}
        name={t('deleteRoleText')}
      />
      <BackOfficeDesktop
        listItems={authGroupsLists}
        isItemDisplayableDataTable={!!isItemDisplayableDataTable}
        selectedItemId={authGroupSelected.id}
        handleAddPopup={() => setPopupAdd(true)}
        desktopConfig={desktopConfig}
        nameButton={t('addRole')}
        titleBodyName={t('manageAuthGroups')}
      />
    </div>
  );
}

export default AuthGroups;
