import axios from 'axios';
import HeaderManagerHandler from './headerManagerHandler';

const headerManager = (store) => {
  axios.interceptors.request.use((config) => HeaderManagerHandler.addAuthorization(config));
  axios.interceptors.response.use(
    (response) => response,
    (error) => HeaderManagerHandler.logoutOnForbiddenError(error, store)
  );
};

export default headerManager;
