import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';

export const styleTypographyName = {
  fontSize: '16px',
  borderRadius: '5px',
  fontWeight: 600,
  fontStyle: 'italic',
  width: '100%',
  textAlign: 'center'
};

function PopupDeleteAzureGroup({ openPop, onClose, onClickValidate, name }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={openPop}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={[{ overflowWrap: 'break-word', maxWidth: '387.11px' }]}>
        <DialogContentText id="alert-dialog-slide-description" textAlign="center">
          {`${t('confirmDeleteBegin')}`}
        </DialogContentText>
        <Typography sx={styleTypographyName}>{name}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <CustomizedButton
          datatestid="cancelDeleteAzureGroup"
          variant="contained"
          onClick={onClose}
          text={t('cancel')}
        />
        <CustomizedButton
          variant="contained"
          datatestid="confirmDeleteAzureGroup"
          onClick={() => {
            onClickValidate();
            onClose();
          }}
          text={t('confirm')}
        />
      </DialogActions>
    </Dialog>
  );
}

export default PopupDeleteAzureGroup;

PopupDeleteAzureGroup.propTypes = {
  openPop: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  onClickValidate: PropTypes.func
};

PopupDeleteAzureGroup.defaultProps = {
  openPop: false,
  onClose: () => {},
  name: '',
  onClickValidate: () => {}
};
