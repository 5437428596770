import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

function PrivateRoute({ isLogin, isBackOffice }) {
  if (isBackOffice && isLogin) {
    return <Navigate to="/providers" />;
  }
  return isLogin ? (
    <Outlet />
  ) : (
    <Navigate to={process.env.REACT_APP_IS_LOGINBOARD_DISPLAYED === 'true' ? '/' : '/login'} />
  );
}

PrivateRoute.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  isBackOffice: PropTypes.bool.isRequired
};

export default PrivateRoute;
