import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styleBoxScrolleIcon } from '../../assets/style/views/LoginBoard';
import HeaderPage from '../../components/HeaderPage';
import BoardLicences from '../../components/BoardLicences';
import AppListService from '../../services/appList/AppListService';

export default function HomePage() {
  const { t } = useTranslation();
  const [appListConnected, setAppListConnected] = useState(null);
  const headerPageConfig = {
    text: {
      titleText: t('homePage'),
      bigTitleText: t('homePageLicences'),
      selectedItemsText: t('selectedLicences'),
      tagId: 'HomePage'
    },
    isPositionFixed: true
  };
  const getRouteConnected = async () => {
    const res = await AppListService.getRouteConnected();

    if (res.data?.appList) {
      setAppListConnected(res.data.appList);
    }
  };
  useEffect(() => {
    if (!appListConnected) getRouteConnected();
  }, []);
  return (
    <Grid container>
      <HeaderPage headerPageConfig={headerPageConfig} />
      <Grid sx={styleBoxScrolleIcon}>
        {appListConnected ? (
          <BoardLicences isConnected appList={appListConnected} onAppListIdsUpdate={null} />
        ) : null}
      </Grid>
    </Grid>
  );
}
