import socket from '../../socket/socket';

const JiraUsersService = {
  findJiraUsers() {
    return socket.emit('jira-users:find', 'true');
  },
  refreshJiraUsers() {
    return socket.emit('jira-users:refresh', 'true');
  }
};

export default JiraUsersService;
