import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import BackOfficeDesktop from '../../../../components/BackOffice/BackOfficeDesktop';
import {
  getAzureGroupsDetails,
  selectAzureGroups,
  selectedAzureGroup,
  updateAzureGroups,
  getAllAzureGroups,
  deleteAzureGroup,
  getDomains,
  selectDomains
} from '../../../../redux/slices/AzureGroups';
import errorRedirection from '../../../../utils/errorRedirection';
import PopupDeleteAzureGroup from './PopupDeleteAzureGroup';
import PopupAddAzureGroup from './PopupAddAzureGroup';

const formatAzureGroupValueForFields = (value, azureGroups, keyName, domains) => {
  if (keyName === 'azureGroupPermFrom') {
    return {
      value,
      options: azureGroups.map((azureGroup) => {
        const tempAzureGroup = { ...azureGroup };
        delete tempAzureGroup.onClick;
        return tempAzureGroup;
      })
    };
  }
  if (keyName === 'domains') {
    return {
      value,
      options: domains
    };
  }
  return value;
};

const adaptTypeByKeyName = (keyName) => {
  if (keyName === 'azureGroupPermFrom' || keyName === 'domains') return 'autoComplete';
  if (keyName === 'azureMicrosoftGroupId') return 'staticText';
  if (keyName === 'isIntune' || keyName === 'isDefault') return 'isBoolean';
  return 'edit';
};

const formatted = (selectAzureGroup, azureGroups, domains) => {
  return Object.keys(selectAzureGroup)
    .filter((keyName) => {
      if (keyName === 'id' || keyName === 'tenantId' || keyName === 'azureGroup') return false;
      return true;
    })
    .map((keyName) => ({
      keyName,
      value: formatAzureGroupValueForFields(
        selectAzureGroup[keyName],
        azureGroups,
        keyName,
        domains
      ),
      type: adaptTypeByKeyName(keyName)
    }));
};

const formatAzureGroups = (azureGroups) => {
  if (azureGroups?.length) {
    return azureGroups.map(({ id, name }) => {
      return {
        id,
        name,
        onClick: async (dispatch) => {
          await dispatch(getAzureGroupsDetails(id));
        }
      };
    });
  }
  return [];
};

export default function AzureGroups() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const azureGroups = formatAzureGroups(useSelector(selectAzureGroups));
  const domains = useSelector(selectDomains);
  const selectedAzureGroupRedux = useSelector(selectedAzureGroup);
  const [modifiedAzureGroup, setModifiedAzureGroup] = useState({});
  const isItemDisplayable = Object.keys(modifiedAzureGroup).length;

  const [popupDelete, setPopupPopupDelete] = useState(false);
  const [popupAdd, setPopupAdd] = useState(false);

  const isItemModified =
    JSON.stringify(selectedAzureGroupRedux) === JSON.stringify(modifiedAzureGroup);
  const setNewAzureGroup = (newValue, keyName) => {
    setModifiedAzureGroup({
      ...modifiedAzureGroup,
      [keyName]: newValue
    });
  };

  const dispatchGetAllAzureGroups = async () => {
    try {
      await dispatch(getAllAzureGroups()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  const dispatchGetDomains = async () => {
    await dispatch(getDomains());
  };

  const dispatchUpdateAzureGroup = async (azureGroup) => {
    await dispatch(updateAzureGroups(azureGroup));
  };

  const handleClickCancelButton = () => {
    setModifiedAzureGroup(selectedAzureGroupRedux);
  };

  const handleClickUpdateButton = () => {
    dispatchUpdateAzureGroup(modifiedAzureGroup);
  };

  useEffect(() => {
    dispatchGetDomains();
    dispatchGetAllAzureGroups();
  }, [dispatch]);

  useEffect(() => {
    if (selectedAzureGroupRedux) {
      setModifiedAzureGroup(selectedAzureGroupRedux);
    } else {
      setModifiedAzureGroup({});
    }
  }, [selectedAzureGroupRedux]);

  const dispatchDeleteAzureGroup = async (id) => {
    await dispatch(deleteAzureGroup(id));
  };

  return (
    <Grid>
      <PopupDeleteAzureGroup
        openPop={popupDelete}
        onClose={() => setPopupPopupDelete(false)}
        onClickValidate={() => dispatchDeleteAzureGroup(selectedAzureGroupRedux.id)}
        name={selectedAzureGroupRedux.name}
      />
      <PopupAddAzureGroup openPop={popupAdd} onClose={() => setPopupAdd(false)} />
      <BackOfficeDesktop
        isItemDisplayable={!!isItemDisplayable}
        listItems={azureGroups}
        selectedItemId={selectedAzureGroupRedux?.id}
        fieldList={formatted(modifiedAzureGroup, azureGroups, domains)}
        selectedItemName={selectedAzureGroupRedux?.name}
        setModifiedItem={setNewAzureGroup}
        isItemModified={isItemModified}
        handleAddPopup={() => setPopupAdd(true)}
        handleClickCancelButton={handleClickCancelButton}
        handleClickUpdateButton={handleClickUpdateButton}
        handleDeletePopup={() => setPopupPopupDelete(true)}
        titleBodyName={t('manageAzureGroups')}
        titleHeader={t('AzureGroups')}
        nameButton={t('addAzureGroups')}
      />
    </Grid>
  );
}
