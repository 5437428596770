export const styleDeviceNameText = (theme) => ({
  color: theme.palette.colorSoftBlack
});
export const styleMainGrid = (theme) => ({
  flexDirection: 'column',
  backgroundColor: theme.palette.colorMainLight,
  width: '100%',
  flex: '1 !important',
  overflowY: 'auto'
});

export const styleDeviceGrid = (theme) => ({
  backgroundColor: theme.palette.colorMainLight,
  padding: '5px',
  marginTop: '10px',
  marginBottom: '30px',
  borderRadius: '5px',
  boxShadow: `1px 1px 1px ${theme.palette.colorGray}`
});

export const stylePaddingLeftRight = {
  padding: '0px 5px 0px 5px'
};
export const styleDeviceIcon = {
  fontSize: { xs: '25px', sm: '35px' },
  marginRight: '5px'
};

export const styleDeviceName = {
  fontSize: { xs: '25px', sm: '35px' },
  marginLeft: '60px'
};

export const styleDeviceName2 = (theme) => ({
  backgroundColor: theme.palette.colorMainLight,
  fontSize: '20px',
  marginLeft: '60px'
});

export const stylePaddingStartRow = {
  padding: '5px 5px 0px 30px'
};
export const styleNameTextWitdhPadding = {
  witdh: '30%',
  paddingRight: '10px'
};

export const styleRowGrid = {
  witdh: '50%',
  margin: '5px 0px 5px 0px',
  padding: '5px 5px 0px 30px'
};

export const stylePaddingLeft = {
  paddingLeft: '5px'
};

export const styleElipsis = {
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const styleButtonPreview = (theme) => ({
  width: { xs: '160px', sm: '160px', md: '150px' },
  height: '50px',
  borderRadius: '10px',
  color: theme.palette.colorMainLight,
  backgroundColor: theme.palette.colorMain,
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.main,
    transform: 'scale(0.95, 0.95)'
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    background: theme.palette.colorMain
  }
});

export const styleFlexAlign = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const styleIconActive = {
  color: 'green',
  paddingLeft: '10px'
};

export const styleIconaDisabled = { color: 'red', paddingLeft: '10px' };
