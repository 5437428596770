import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Button, IconButton } from '@mui/material';
import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';
import { FileDrop } from 'react-file-drop';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CancelIcon from '@mui/icons-material/Cancel';

export default function CustomFiledDrop({ setFile, setFileName, fileName }) {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const onFileInputChange = (event) => {
    const { files } = event.target;
    setFileName(files[0].name);
    setFile(files[0]);
  };
  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Grid>
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
      />

      <FileDrop
        data-testid="CreateAppFile"
        onDrop={(files) => {
          setFile(files[0]);
          setFileName(files[0].name);
        }}
      >
        <Box
          sx={(theme) => ({
            background: theme.palette.colorSecondaryLight,
            borderRadius: '8px',
            border: `dashed 2px ${theme.palette.colorSoftBlack}`
          })}
        >
          {fileName.length ? (
            <Grid
              container
              sx={{
                height: '90px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.colorMainSelected })}
              >
                {fileName}
              </Typography>
              <IconButton
                onClick={() => {
                  setFile(null);
                  setFileName('');
                }}
              >
                <CancelIcon />
              </IconButton>
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                height: '90px'
              }}
            >
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Button
                  data-testid="SelectFileButton"
                  sx={(theme) => ({
                    textTransform: 'lowercase',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    color: theme.palette.colorMainSelected,
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'scale(1.1)'
                    }
                  })}
                  onClick={onTargetClick}
                >
                  {t('ChooseAFile')}
                </Button>
                <Typography>{`${t('orDragItHere')} :`}</Typography>{' '}
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <FileDownloadIcon />
              </Grid>
            </Grid>
          )}
        </Box>
      </FileDrop>
    </Grid>
  );
}
CustomFiledDrop.propTypes = {
  setFile: PropTypes.func.isRequired,
  setFileName: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired
};
