import { Grid, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import ItemsDesktop from './ItemsDesktop';
import ItemsMobile from './ItemsMobile';

export default function ItemsList({ config }) {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid>
      {!isMobile ? <ItemsDesktop desktopConfig={config} /> : <ItemsMobile config={config} />}
    </Grid>
  );
}

ItemsList.propTypes = {
  config: PropTypes.shape({
    isDisableCheckBox: PropTypes.bool,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        datatestid: PropTypes.string,
        isDisplayable: PropTypes.bool,
        onClick: PropTypes.func,
        text: PropTypes.string
      })
    ),
    actions: PropTypes.shape({
      handleRefresh: PropTypes.func,
      handleSearchTerm: PropTypes.func
    }),
    data: PropTypes.shape({
      tableSelectedRows: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
            PropTypes.arrayOf(PropTypes.string)
          ])
        )
      ),
      setTableSelectedRows: PropTypes.func,
      setTableSelectedRowsRealData: PropTypes.func,
      items: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
            PropTypes.array,
            PropTypes.arrayOf(PropTypes.string)
          ])
        )
      )
    }),
    loading: PropTypes.shape({
      itemsListIsLoading: PropTypes.bool,
      isTableDisplayable: PropTypes.bool
    }),
    tables: PropTypes.shape({
      optionsKeys: PropTypes.shape({
        checkboxKey: PropTypes.string,
        titleNameKey: PropTypes.string,
        secondaryTitleKey: PropTypes.string,
        statusKey: PropTypes.string
      }),
      date: PropTypes.string,
      totalItems: PropTypes.number,
      page: PropTypes.number,
      handleChangePage: PropTypes.func,
      handleChangeRowsPerPage: PropTypes.func,
      rowsPerPage: PropTypes.number,
      selectedIndexRows: PropTypes.arrayOf(PropTypes.number)
    }),
    search: PropTypes.shape({
      setSearchTerm: PropTypes.func
    }),
    text: PropTypes.shape({
      titleText: PropTypes.string,
      bigTitleText: PropTypes.string
    }),
    isPartialData: PropTypes.bool,
    linkOptions: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
  })
};

ItemsList.defaultProps = {
  config: {}
};
