import socket from '../../socket/socket';

const MessageService = {
  rcsHandler(selectedPhoneNumber) {
    return socket.emit('RCS-capability:test', { phoneNumber: selectedPhoneNumber });
  },
  sendMessage(newMessage) {
    return socket.emit('new-message:send', newMessage);
  },
  findConversations() {
    return socket.emit('conversations-list:ask');
  },
  findOneConversation(phoneNumberId, phoneNumberName) {
    return socket.emit('conversation-id', {
      phoneNumberId,
      phoneNumber: phoneNumberName
    });
  },
  conversationList() {
    return socket.emit('conversations-list:ask');
  },
  findNotReadMessages() {
    return socket.emit('find-unread-messages:ask');
  }
};

export default MessageService;
