import { regexPassword } from './regex';

const enableDisabledOU = (inputString, userText, disableText, defaultEnableUserText) => {
  const parts = inputString.split(',');
  const firstPart = parts[0];
  const secondPart = parts[1];
  const userTextArray = userText.split(',');
  if (secondPart.includes(`OU=${disableText}`)) {
    parts.splice(1, 1);
    parts[0] = `${firstPart},OU=${defaultEnableUserText}`;
  } else if (userTextArray.some((text) => secondPart.includes(`OU=${text}`))) {
    parts.splice(1, 1);
    parts[0] = `${firstPart},OU=${disableText}`;
  } else return null;

  return parts.join(',');
};

export const formatGroups = (groups) =>
  groups.map((string) => {
    const substringModified = string.substring(string.indexOf('=') + 1, string.indexOf(','));
    return { name: substringModified, dn: string };
  });

export const randomNumberString = (length) => {
  let numberString = '';
  for (let index = 0; index < length; index += 1) {
    numberString += JSON.stringify(Math.floor(Math.random() * 10));
  }
  return numberString;
};
const veryStrong = 100;
const strong = 70;
const medium = 30;
const tooWeak = 5;
export const complexityPassword = (password) => {
  if (password.length >= 15 && regexPassword(password)) {
    return veryStrong;
  }
  if (password.length >= 10 && regexPassword(password)) {
    return strong;
  }
  if (password.length >= 4) {
    return medium;
  }
  return tooWeak;
};

export default enableDisabledOU;
