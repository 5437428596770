import React from 'react';
import PropTypes from 'prop-types';
import { Grid, LinearProgress } from '@mui/material';
import { complexityPassword } from '../../utils/adUsers';

const getBarColor = (type) => {
  if (type === 100) return 'inherit';
  if (type === 70) return 'success';
  if (type === 30) return 'warning';
  return 'error';
};

function CustomPasswordStrengthBar({ password }) {
  const strength = complexityPassword(password);

  return (
    <Grid>
      <LinearProgress
        variant="determinate"
        value={strength}
        width="100%"
        height="5px"
        color={getBarColor(strength)}
      />
    </Grid>
  );
}

export default CustomPasswordStrengthBar;

CustomPasswordStrengthBar.propTypes = {
  password: PropTypes.string.isRequired
};
