import React from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  Divider
} from '@mui/material';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectAssignAzureLicenses } from '../../redux/slices/AzureLicenses';
import {
  styleFullWidth,
  styleHalfOpacity,
  styleFontWeight,
  styleHoverAccordion,
  styleMarginLeft,
  styleFormatText
} from '../../assets/style/components/azureLicenses/AzureLicensesServices';

export default function AzureLicensesServices({
  selectedLicenses,
  unassignServiceMap,
  setUnassignServiceMap,
  isAssignMode
}) {
  const { t } = useTranslation();
  const licensesFiltered = useSelector(selectAssignAzureLicenses(selectedLicenses));

  const handleAssignService = (serviceId, licenseId) => {
    const isMapExist = !!unassignServiceMap.get(licenseId);
    if (isMapExist) {
      const tab = unassignServiceMap.get(licenseId);
      if (!tab.includes(serviceId)) {
        setUnassignServiceMap((map) => new Map(map.set(licenseId, [...tab, serviceId])));
        return;
      }
      setUnassignServiceMap(
        (map) =>
          new Map(
            map.set(
              licenseId,
              tab.filter((elem) => elem !== serviceId)
            )
          )
      );
      return;
    }
    setUnassignServiceMap((map) => new Map(map.set(licenseId, [serviceId])));
  };

  const handleTextServicePlans = (license) => {
    const totalServices = license.servicePlans.filter(
      (service) => service.appliesTo === 'User'
    ).length;
    let selectedServices = totalServices;
    const unassignServices = unassignServiceMap.get(license.skuId);
    if (unassignServices === undefined) {
      return `${selectedServices}/${totalServices}`;
    }
    selectedServices = totalServices - unassignServices.length;
    return `${selectedServices}/${totalServices}`;
  };

  const isDisabledService = (licenseId, serviceId) => {
    if (isAssignMode) return null;
    if (unassignServiceMap.get(licenseId).indexOf(serviceId) !== -1) return styleHalfOpacity;
    return null;
  };

  return (
    <Grid>
      {selectedLicenses.length === 0 ? (
        <Typography sx={styleFormatText}>{t('noLicense')}</Typography>
      ) : null}
      {licensesFiltered.map((license) => (
        <Accordion key={license.displayName}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-testid={`CollapseLicense${license.id}`}
            sx={styleHoverAccordion}
          >
            <Grid display="flex" justifyContent="space-between" sx={styleFullWidth}>
              <Grid display="flex" justifyContent="flex-start">
                <ArrowRightIcon />
                <Typography sx={styleFontWeight}>{license.displayName}</Typography>
              </Grid>

              <Typography>
                {t('services')} &nbsp;
                {handleTextServicePlans(license)}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ marginBottom: '10px' }} />
            <Grid display="flex" flexDirection="column" sx={styleMarginLeft}>
              {license.servicePlans.map((service) => (
                <Grid key={service.servicePlanId}>
                  {service.appliesTo === 'User' ? (
                    <Grid display="flex" alignItems="center">
                      {isAssignMode ? (
                        <Checkbox
                          defaultChecked
                          onClick={() => {
                            handleAssignService(service.servicePlanId, license.skuId);
                          }}
                          data-testid={`Checkbox${service.servicePlanId}`}
                        />
                      ) : null}
                      <Typography sx={isDisabledService(license.skuId, service.servicePlanId)}>
                        {service.servicePlanName}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid display="flex" alignItems="center">
                      {isAssignMode ? (
                        <>
                          <Checkbox disabled />
                          <Typography sx={styleHalfOpacity}>{service.servicePlanName}</Typography>
                        </>
                      ) : null}
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
}

AzureLicensesServices.propTypes = {
  selectedLicenses: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUnassignServiceMap: PropTypes.func,
  unassignServiceMap: PropTypes.instanceOf(Map).isRequired,
  isAssignMode: PropTypes.bool
};

AzureLicensesServices.defaultProps = {
  isAssignMode: false,
  setUnassignServiceMap: undefined
};
