export const styleList = {
  maxHeight: 620,
  padding: '1em 1em 1em 1em'
};

export const styleAlignChatList = {
  width: '100%'
};

export const styleListButton = (theme, isSelected) => ({
  display: 'flex',
  flexDirection: 'row',
  background: isSelected && theme.palette.colorMainLightHover,
  padding: '2em',
  borderRadius: '16px'
});

export const styleChatListItem = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '.MuiTypography-body1.MuiListItemText-primary': {
    fontWeight: '700'
  }
};

export const styleTypoLastMessage = (theme) => ({
  height: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  overflowWrap: 'inherit',
  textAlign: 'left',
  width: '200px',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  color: theme.palette.colorGray
});

export const styleNotification = (theme) => ({
  top: '18px',
  right: '10px',
  '.BaseBadge-badge': {
    padding: '11px',
    fontSize: '0.8em',
    backgroundColor: theme.palette.colorMain
  }
});

export const styleFiberIcon = {
  color: 'red',
  fontSize: '15px',
  paddingLeft: '5px'
};

export const styleConversationGrid = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};
