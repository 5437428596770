import React, { useEffect, useState } from 'react';
import { Box, Grid, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  styleHeader,
  styleTitle,
  styleTypographyProfile
} from '../../../../assets/style/views/SMS';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';
import PopupDeleteAppList from './PopupDeleteAppList';
import BoardLicences from '../../../../components/BoardLicences';
import {
  getAllAppList,
  selectAppListByGroupIdAndConnected,
  selectAppListById
} from '../../../../redux/slices/AppList';
import PopupAddAppList from './PopupAddAppList';
import PopupUpdateAppList from './PopupUpdateAppList';
import { getAllAuthGroups, selectAuthGroups } from '../../../../redux/slices/AuthGroups';
import errorRedirection from '../../../../utils/errorRedirection';

export default function AllAppList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [otherComponentData, setOtherComponentData] = useState([]);
  const [popupDelete, setPopupPopupDelete] = useState(false);
  const [roleIdFilter, setRoleIdFilter] = useState(0);
  const [connectedFilter, setConnectedFilter] = useState(0);
  const handleAppListIdUpdate = (appListIds) => {
    setOtherComponentData(appListIds);
  };
  const handleDeletePopup = () => setPopupPopupDelete(true);
  const resetSelectedApps = () => {
    setOtherComponentData([]);
  };
  const AllAppLists = useSelector(
    selectAppListByGroupIdAndConnected(roleIdFilter, connectedFilter)
  );
  const dispatch = useDispatch();
  const [updatePopup, setUpdatePopup] = useState(false);
  const dispatchGetAuthGroups = async () => {
    try {
      await dispatch(getAllAuthGroups()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };
  const roles = useSelector(selectAuthGroups);
  const tempRoles = roles.length
    ? [...roles.map((role) => ({ value: role.id, text: role.name })), { value: 0, text: 'None' }]
    : [];

  const connectionStates = [
    { value: true, text: 'connected' },
    { value: false, text: 'notConnected' },
    { value: 0, text: 'None' }
  ];
  useEffect(() => {
    dispatch(getAllAppList());
  }, []);
  const [popupAdd, setPopupAdd] = useState(false);

  useEffect(() => {
    dispatchGetAuthGroups();
  }, [dispatch]);

  const selectedAPP = useSelector(
    selectAppListById(otherComponentData?.length ? otherComponentData[0] : null)
  );

  const handlePopupClose = () => {
    setUpdatePopup(false);
  };
  const theme = useTheme();
  return (
    <Grid>
      <Box>
        <Grid sx={styleHeader(theme, false)}>
          <Typography sx={styleTypographyProfile}>{t('myAppList')}</Typography>
          <Typography variant="h4" sx={styleTitle}>
            {t('manageMyApps')}
          </Typography>
        </Grid>
      </Box>
      <Grid
        container
        sx={{
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Grid
          container
          display="flex"
          direction="row"
          sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              margin: '20px'
            }}
          >
            <CustomizedButton
              onClick={() => setPopupAdd(true)}
              text={t('addApp')}
              datatestid="AddApp"
            />
            <CustomizedButton
              disabled={otherComponentData.length === 0}
              variant="contained"
              datatestid="DeleteAppList"
              text={t('delete')}
              onClick={() => setPopupPopupDelete(true)}
            />
            <CustomizedButton
              disabled={otherComponentData.length !== 1}
              variant="contained"
              datatestid="modifyApp"
              text={t('modifyApp')}
              onClick={() => setUpdatePopup(true)}
            />
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', marginLeft: '20px', marginRight: '20px' }}
          >
            <Box sx={{ margin: '10px' }}>
              <InputLabel id="select-auth-group">Role</InputLabel>
              <Select
                defaultValue={0}
                id="list-role"
                label="role"
                data-testid="selectRole"
                sx={{ width: 200 }}
                value={roleIdFilter}
                onChange={(event) => setRoleIdFilter(event.target.value)}
              >
                {tempRoles.map((role) => (
                  <MenuItem
                    key={role.value}
                    value={role.value}
                    data-testid={`menuItem${role.text}`}
                  >
                    {t(role.text)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ margin: '10px' }}>
              <InputLabel id="select-connected?">{t('connected')} </InputLabel>
              <Select
                defaultValue={0}
                id="list-connected"
                label="Connected?"
                data-testid="selectConnected"
                sx={{ width: 200 }}
                value={connectedFilter}
                onChange={(event) => setConnectedFilter(event.target.value)}
              >
                {connectionStates.map((state) => (
                  <MenuItem
                    key={state.value}
                    value={state.value}
                    data-testid={`menuItem${state.text}`}
                  >
                    {t(state.text)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <PopupDeleteAppList
        openPop={popupDelete}
        onClose={() => setPopupPopupDelete(false)}
        data-testid="popupDeleteAppList"
        onAppListIdsUpdate={otherComponentData}
        resetSelectedApps={resetSelectedApps}
      />
      <PopupAddAppList openPop={popupAdd} onClose={() => setPopupAdd(false)} />
      <PopupUpdateAppList
        openPop={updatePopup}
        onClose={handlePopupClose}
        selectedAppId={otherComponentData[0]}
        application={selectedAPP}
      />

      <BoardLicences
        roleIdFilter={roleIdFilter === 0 ? null : roleIdFilter}
        isConnected={connectedFilter === 0 ? null : connectedFilter}
        isBackOffice
        handleDeletePopup={handleDeletePopup}
        onAppListIdsUpdate={handleAppListIdUpdate}
        appList={AllAppLists}
      />
    </Grid>
  );
}
