import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StandardBox from '../StandardBox';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';
import { selectedUser } from '../../redux/slices/JiraUsers';
import JiraGroupsPopup from '../popup/JiraGroupsPopup';
import JiraGroupList from '../jiraUserGroups/JiraGroupList';

export default function UserJiraGroupDescription() {
  const { t } = useTranslation();
  const user = useSelector(selectedUser);
  const permissions = useSelector(selectPermissions);
  const [jiraGroupsPopup, setJiraGroupsPopup] = useState(false);
  const [isRemoveLicense, setIsRemoveLicense] = useState(false);

  const listButton = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_JIRA_GROUPS_TO_USER'),
      text: `${t('assignJiraGroups')}`,
      datatestid: 'addJiraGroups',
      onClick: () => {
        setJiraGroupsPopup(true);
        setIsRemoveLicense(false);
      }
    },
    {
      hasPermissions: hasPermissions(permissions, 'SET_REMOVE_JIRA_GROUPS_TO_USER'),
      text: `${t('removeJiraGroups')}`,
      datatestid: 'removeJiraGroups',
      onClick: () => {
        setJiraGroupsPopup(true);
        setIsRemoveLicense(true);
      }
    }
  ];

  return (
    <Grid>
      <StandardBox
        title="jiraGroups"
        buttons={listButton}
        ChildComponent={
          <JiraGroupList
            selectedGroups={user.groups}
            // TODO: à voir
            // unassignServiceMap={[]}
            // setUnassignServiceMap={[]}
            isAssignMode={false}
          />
        }
      />
      <JiraGroupsPopup
        popup={jiraGroupsPopup}
        closePopup={() => setJiraGroupsPopup(false)}
        selectedUsers={[user.name]}
        isRemove={isRemoveLicense}
      />
    </Grid>
  );
}
