import { Box, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';
import { createProvider } from '../../../../redux/slices/Providers';
import errorRedirection from '../../../../utils/errorRedirection';
import providersAttributes from '../../../../utils/fieldListProviders';
import Field from '../../../../components/BackOffice/BackOfficeDesktop/EditItem/Field';

function PopupAddProvider({ openPop, onClose, providersNames, dispatchGetProviders }) {
  const { t } = useTranslation();
  const [providerName, setProviderName] = useState({});
  const [providerAuthField, setProviderAuthField] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setModifiedItem = (newValue, keyName) => {
    if (keyName.includes('-')) {
      const [parentKey, valueKey] = keyName.split('-');

      setProviderAuthField((providerAuthFieldObject) => ({
        ...providerAuthFieldObject,
        [parentKey]: {
          ...(providerAuthFieldObject[parentKey] || {}),
          [valueKey]: newValue
        }
      }));
    } else {
      setProviderAuthField((prevProviderAuthField) => ({
        ...prevProviderAuthField,
        [keyName]: newValue
      }));
    }
  };

  const isError = (keyname, isRequired) => {
    if (!providerAuthField?.[keyname] && isRequired && isSubmit) {
      return true;
    }
    return false;
  };

  const isFieldsObjectFilled = (providerField, providerAuthFieldObject) => {
    return providerField.fields.every((field) => {
      const fieldValue = providerAuthFieldObject?.[field.fieldname];
      const isFieldComplete = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';

      const fieldObjectKeyName = field?.fieldname;
      const fieldObjectValueKeyName = `${providerField.fieldname}-${field.fieldname}`;

      if (field.type === 'isBoolean') {
        if (!isFieldComplete) {
          setModifiedItem(false, fieldObjectValueKeyName);
          return false;
        }
      }

      if (field.required) {
        if (field.type === 'isObject' && fieldValue) {
          return isFieldsObjectFilled(field, fieldObjectKeyName);
        }
        isError(providerField.fieldname, true, field?.fieldname);
        return isFieldComplete;
      }
      if (!field.required && fieldValue === undefined && field.type !== 'isObject') {
        setModifiedItem('', fieldObjectValueKeyName);
        return false;
      }

      return true;
    });
  };

  const isAllFieldsFilled = providersAttributes[providerName.name]?.fields?.every(
    (providerField) => {
      if (providerField.type === 'isObject') {
        return isFieldsObjectFilled(providerField, providerAuthField[providerField.fieldname]);
      }

      const fieldValue = providerAuthField[providerField.fieldname];
      const isFieldComplete = fieldValue !== undefined && fieldValue !== null && fieldValue !== '';

      if (providerField.type === 'isBoolean') {
        if (!isFieldComplete) {
          setModifiedItem(false, providerField.fieldname);
          return false;
        }
      }
      if (providerField.required) {
        if (!isFieldComplete) {
          return false;
        }
      } else if (fieldValue === undefined) {
        setModifiedItem('', providerField.fieldname);
        return false;
      }
      return true;
    }
  );

  const closePopup = () => {
    onClose();
    setProviderName({});
    setProviderAuthField({});
    setIsSubmit(false);
  };

  const handleClickClosePopup = () => closePopup();

  const dispatchCreateProvider = async (providerNameId, body, isAuth) => {
    await dispatch(createProvider({ providerNameId, body, isAuth }));
    dispatchGetProviders();
    closePopup();
  };

  const handleClickCreateProvider = () => {
    try {
      if (isAllFieldsFilled) {
        const isAuthConnector = providersAttributes[providerName.name]?.isAuthConnector;

        const stringifyBody = JSON.stringify(providerAuthField);
        dispatchCreateProvider(providerName.id, stringifyBody, isAuthConnector);
        closePopup();
      }
      setIsSubmit(true);
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  return (
    <Dialog
      open={openPop}
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ width: '100%' }}
    >
      <DialogContent sx={{ overflowY: 'unset', paddingBottom: '0px' }}>
        <DialogContentText id="alert-dialog-slide-description" textAlign="center">
          {t('addProvider')}
        </DialogContentText>
      </DialogContent>
      <Grid item xs={8} sx={{ margin: '20px' }}>
        <Box>
          <InputLabel htmlFor="providerName">Provider name</InputLabel>
          <Select
            name="providerName"
            value={providerName.name || ''}
            data-testid="addProviderProviderName"
            onChange={(e, value) =>
              setProviderName(providersNames.find((elem) => elem.id === value.props.id))
            }
            sx={{ width: '100%' }}
          >
            {providersNames.map(({ id, name }) => (
              <MenuItem key={id} id={id} value={name} data-testid={`menuItem${name}`}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
      <Grid item xs={8} sx={{ margin: '0px 20px 5px' }}>
        <InputLabel htmlFor="providerName">ProviderAuth</InputLabel>

        {providersAttributes[providerName.name]?.fields.map((providerField) => {
          const childFieldList =
            providerField.type === 'isObject'
              ? providerField.fields.map((childField) => {
                  return {
                    keyName: childField.fieldname,
                    type: childField.type,
                    value: providerAuthField[providerField.fieldname]?.[childField.fieldname],
                    required: childField.required
                  };
                })
              : null;
          return (
            <Field
              key={`${providerField.name}${providerField.fieldname}`}
              keyName={providerField.fieldname}
              value={providerAuthField[providerField.fieldname]}
              setModifiedItem={setModifiedItem}
              type={providerField.type !== 'isObject' ? providerField.type : 'emptyObjectField'}
              sxedit={{ marginBottom: '15px' }}
              isError={isError}
              isRequired={providerField.required}
              labelName={providerField.required ? `${providerField.fieldname}*` : null}
              childFieldList={childFieldList}
            />
          );
        })}
      </Grid>

      <DialogActions sx={{ justifyContent: 'center' }}>
        <CustomizedButton
          variant="contained"
          text={t('cancel')}
          datatestid="cancelAddProvider"
          onClick={() => handleClickClosePopup()}
        />
        <CustomizedButton
          variant="contained"
          text={t('confirm')}
          datatestid="confirmAddProvider"
          type="submit"
          onClick={() => handleClickCreateProvider()}
        />
      </DialogActions>
    </Dialog>
  );
}

export default PopupAddProvider;

PopupAddProvider.propTypes = {
  openPop: PropTypes.bool,
  onClose: PropTypes.func,
  providersNames: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ),
  dispatchGetProviders: PropTypes.func
};

PopupAddProvider.defaultProps = {
  openPop: false,
  onClose: () => {},
  providersNames: [],
  dispatchGetProviders: () => {}
};
