import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Dialog, Grid, DialogTitle, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  styleCloseButton,
  styleDialogText,
  styleGridPadding,
  styleTextPadding,
  styleColorTitle
} from '../../assets/style/components/popup/InformationPopup';
import identifier from '../../utils/keySelector';
import LoadingWrapper from '../layout/LoadingWrapper';

export default function InformationPopup({ openPop, onClose, title, data, rows, isLoading }) {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog onClose={onClose} open={openPop}>
        <LoadingWrapper isChildDisplayable={!isLoading}>
          <DialogTitle sx={styleDialogText}>
            {title}
            <IconButton aria-label="close" onClick={onClose} sx={styleCloseButton}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          {!data ? null : (
            <Grid sx={styleGridPadding}>
              {rows.map((row) => (
                <Grid sx={styleTextPadding} key={row.name}>
                  <Grid display="flex" justifyContent="space-between">
                    <Typography variant="h6" component="h6" sx={styleColorTitle}>
                      {t(`${row.name}`).toUpperCase()} :
                    </Typography>
                    {!row.component ? null : row.component}
                  </Grid>
                  <Typography data-testid={identifier(row)}>{data[identifier(row)]}</Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </LoadingWrapper>
      </Dialog>
    </div>
  );
}

InformationPopup.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element]))
  ).isRequired,
  data: PropTypes.objectOf(PropTypes.string),
  openPop: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string
};

InformationPopup.defaultProps = {
  isLoading: false,
  data: null,
  title: null
};
