import React, { useState } from 'react';
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  ListItemIcon,
  List,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';

import { useLocation } from 'react-router-dom';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

import CloseMenu from './CloseMenu';
import CustomizedListItem from './CustomizedListItem';
import { classes } from '../../assets/style/components/layout/SideBar';

export default function SideBarList({
  isOpenSideBar,
  openSubMenuId,
  reductMobileSideBar,
  openReductedSubMenuId,
  setOpenSubMenuId,
  setOpenReductedSubMenuId,
  subMenuList
}) {
  const children = (item) => {
    return isOpenSideBar && openSubMenuId === item.id ? item.child : [];
  };
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (id, e) => {
    if (isOpenSideBar) {
      return openSubMenuId === id ? setOpenSubMenuId('none') : setOpenSubMenuId(id);
    }
    // setOpenSubMenuId('none');
    setAnchorEl(e.currentTarget);
    return openReductedSubMenuId === id
      ? setOpenReductedSubMenuId('none')
      : setOpenReductedSubMenuId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenReductedSubMenuId('none');
  };
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  if (isMobile && !isOpenSideBar && openReductedSubMenuId !== 'none') {
    setOpenReductedSubMenuId('none');
  }
  const currentPathLocation = `/${useLocation().pathname.split('/')[1]}`;
  return (
    <List className={classes.sideBarList}>
      {subMenuList?.map((item) =>
        item.child ? (
          <Box key={item.id} className={classes.sideBarBoxItem}>
            <ListItem
              disablePadding
              className={classes.sideBarListItem}
              onClick={(e) => handleClick(item.id, e)}
              selected={item.child.map(({ path }) => path === currentPathLocation).includes(true)}
            >
              <ListItemButton className={classes.sideBarButtonList} id="basic-button">
                <Box className={classes.sideBarBoxItemWithChild}>
                  <ListItemIcon className={classes.sideBarListItemIcon}>{item.icon}</ListItemIcon>
                  <ListItemText primary={t(item.id)} className={classes.sideBarListItemText} />
                </Box>
                {openSubMenuId === item.id ? (
                  <ExpandLessRoundedIcon className={classes.sideBarChevronItem} />
                ) : (
                  <ExpandMoreRoundedIcon className={classes.sideBarChevronItem} />
                )}
              </ListItemButton>
            </ListItem>
            <CloseMenu
              item={item}
              handleClose={handleClose}
              currentPathLocation={currentPathLocation}
              isOpenSideBar={isOpenSideBar}
              openReductedSubMenuId={openReductedSubMenuId}
              anchorEl={anchorEl}
            />
            <TransitionGroup>
              {children(item).map((child) => (
                <Collapse key={`${child.id}`}>
                  <CustomizedListItem
                    child
                    itemOrChild={child}
                    reductMobileSideBar={reductMobileSideBar}
                    currentPathLocation={currentPathLocation}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </Box>
        ) : (
          <CustomizedListItem
            key={item.id}
            itemOrChild={item}
            currentPathLocation={currentPathLocation}
          />
        )
      )}
    </List>
  );
}

SideBarList.propTypes = {
  isOpenSideBar: PropTypes.bool.isRequired,
  openSubMenuId: PropTypes.string.isRequired,
  openReductedSubMenuId: PropTypes.string.isRequired,
  setOpenSubMenuId: PropTypes.func.isRequired,
  setOpenReductedSubMenuId: PropTypes.func.isRequired,
  subMenuList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
        PropTypes.bool,
        PropTypes.arrayOf(
          PropTypes.objectOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.func,
              PropTypes.bool,
              PropTypes.element
            ])
          )
        )
      ])
    )
  ).isRequired,
  reductMobileSideBar: PropTypes.func
};

SideBarList.defaultProps = {
  reductMobileSideBar: () => {}
};
