export const stylePadding = { padding: '15px' };

export const styleFullWidth = {
  width: '100%'
};
export const stylePaddingRight = {
  paddingRight: '10px'
};
export const styleButton = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  borderRadius: '4px',
  textDecoration: 'none',
  width: { xs: '160px', sm: '160px', md: '200px' },
  height: '42px',
  margin: '10px 0',
  marginLeft: '10px',
  color: theme.palette.colorMainLight,
  backgroundColor: theme.palette.colorMainSelected,
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.colorMainSelected,
    transform: 'scale(0.95, 0.95)'
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    background: theme.palette.colorMain
  }
});
