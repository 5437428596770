import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';

export default function CheckedIcon({ checked }) {
  return checked ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />;
}

CheckedIcon.propTypes = {
  checked: PropTypes.bool.isRequired
};
