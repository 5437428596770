import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import {
  styleAppBar,
  styleDrawerWrapper,
  styleMain,
  styleMobileSideBar,
  styleDesktopSideBar
} from '../../assets/style/components/layout/MainPage';
import SideBar from './SideBar';
import SubMenuUserList from './SideBar/SubMenuList';
import { styleDrawerHeader } from '../../assets/style/components/layout/SideBar';
import DisplayChevronSideBar from './DisplayChevronSideBar';
import MobileSideBar from './MobileSideBar';

export default function MainPage({ ChildComponent }) {
  const [isOpenSideBar, setIsOpenSideBar] = useState(
    JSON.parse(localStorage.getItem('isOpenSideBar'))
  );
  const [isOpenMobileSideBar, setIsOpenMobileSideBar] = useState(false);
  const [openSubMenuId, setOpenSubMenuId] = useState('none');
  const [openReductedSubMenuId, setOpenReductedSubMenuId] = useState('none');

  const reductMobileSideBar = () => {
    setIsOpenMobileSideBar(false);
  };
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  if (isMobile) {
    if (!isOpenSideBar && isOpenMobileSideBar) {
      setIsOpenMobileSideBar(false);
    }
  }
  const currentPathLocation = `/${useLocation().pathname.split('/')[1]}`;
  const openSubMenuFromPath = () => {
    const currentParent = SubMenuUserList(0).filter(
      (item) =>
        item.child && item.child.map(({ path }) => path === currentPathLocation).includes(true)
    );
    setOpenSubMenuId(currentParent.length ? currentParent[0].id : 'none');
  };
  const toggleDrawer = (toggle) => () => {
    if (!isOpenMobileSideBar) {
      openSubMenuFromPath();
    }
    setIsOpenMobileSideBar(toggle);
  };
  const toggleDrawer2 = (toggle) => () => {
    if (!isOpenMobileSideBar) {
      openSubMenuFromPath();
    }
    setIsOpenMobileSideBar(toggle);
    setIsOpenSideBar(true);
  };
  return (
    <Box>
      <AppBar sx={styleAppBar(theme, isMobile)}>
        <Header
          toggleDrawer={toggleDrawer2}
          isOpenMobileSideBar={isOpenMobileSideBar}
          openSubMenuFromPath={openSubMenuFromPath}
        />
      </AppBar>
      <Box sx={styleDrawerWrapper}>
        {!isMobile ? (
          <Box sx={styleDrawerHeader(isOpenSideBar, theme)}>
            <DisplayChevronSideBar
              isOpenSideBar={isOpenSideBar}
              handleDrawerClose={() => {
                localStorage.setItem('isOpenSideBar', false);
                setIsOpenSideBar(false);
                if (toggleDrawer) {
                  toggleDrawer();
                }
              }}
              handleDrawerOpen={() => {
                localStorage.setItem('isOpenSideBar', true);
                setIsOpenSideBar(true);
              }}
              openSubMenuFromPath={openSubMenuFromPath}
            />
          </Box>
        ) : null}
        <Drawer open={isOpenMobileSideBar} onClose={toggleDrawer(false)} sx={styleMobileSideBar}>
          <MobileSideBar
            isOpenSideBar={isOpenMobileSideBar}
            setIsOpenSideBar={setIsOpenMobileSideBar}
            toggleDrawer={toggleDrawer(false)}
            style={{
              top: '64px',
              background: theme.palette.colorMainGradient,
              height: '100%'
            }}
            reductMobileSideBar={reductMobileSideBar}
            openSubMenuId={openSubMenuId}
            openReductedSubMenuId={openReductedSubMenuId}
            setOpenSubMenuId={setOpenSubMenuId}
            setOpenReductedSubMenuId={setOpenReductedSubMenuId}
            openSubMenuFromPath={openSubMenuFromPath}
          />
        </Drawer>
        <SideBar
          isOpenSideBar={isOpenSideBar}
          setIsOpenSideBar={setIsOpenSideBar}
          style={styleDesktopSideBar}
          openSubMenuId={openSubMenuId}
          openReductedSubMenuId={openReductedSubMenuId}
          setOpenSubMenuId={setOpenSubMenuId}
          setOpenReductedSubMenuId={setOpenReductedSubMenuId}
          openSubMenuFromPath={openSubMenuFromPath}
        />
        <Box component="main" sx={styleMain(theme, isOpenSideBar)}>
          <ChildComponent />
        </Box>
      </Box>
    </Box>
  );
}

MainPage.propTypes = {
  ChildComponent: PropTypes.func.isRequired
};
