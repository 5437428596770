import React, { useEffect, useState } from 'react';
import { Grid, Typography, List, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

import { useTranslation } from 'react-i18next';
import {
  styleFullWidth,
  styleFormatText
} from '../../assets/style/components/azureLicenses/AzureLicensesServices';
import { styleChipStatusGroupDescription } from '../../assets/style/views/UserPages';

export default function AdGroupList({ selectedGroups }) {
  const { t } = useTranslation();
  const [localGroups, setLocalGroups] = useState([]);

  useEffect(() => {
    setLocalGroups(selectedGroups.slice().sort((a, b) => a.name.localeCompare(b.name)));
  }, [selectedGroups]);

  return (
    <Grid>
      {selectedGroups?.length === 0 ? (
        <Typography sx={styleFormatText}>{t('noAdGroups')}</Typography>
      ) : null}
      <List sx={styleFullWidth} component="nav" aria-label="mailbox folders">
        {localGroups?.map((localGroup) => (
          <Chip
            key={`${localGroup.name}`}
            icon={<CircleRoundedIcon fontSize="small" sx={{ marginLeft: '6px', height: '8px' }} />}
            label={localGroup.name}
            sx={styleChipStatusGroupDescription}
          />
        ))}
      </List>
    </Grid>
  );
}

AdGroupList.propTypes = {
  selectedGroups: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, DN: PropTypes.string })
  )
};
AdGroupList.defaultProps = {
  selectedGroups: []
};
