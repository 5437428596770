import { Box, IconButton, InputAdornment, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';

export default function PasswordField({ keyName, value, setModifiedItem, sxedit }) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Box sx={sxedit}>
      <InputLabel htmlFor="input">{keyName}</InputLabel>
      <TextField
        required
        data-testid={`passwordField${keyName}`}
        type={isVisible ? null : 'password'}
        id={keyName}
        name="password"
        autoComplete="password"
        value={value}
        fullWidth
        onChange={(e) => {
          setModifiedItem(e.target.value, keyName);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon className="loginIcon" position="start" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setIsVisible(!isVisible)}>
                {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
        autoFocus
      />
    </Box>
  );
}

PasswordField.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  setModifiedItem: PropTypes.func,
  sxedit: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

PasswordField.defaultProps = {
  keyName: '',
  value: '',
  setModifiedItem: () => {},
  sxedit: null
};
