import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styleLicencesCard } from '../assets/style/views/LoginBoard';
import SelectablePicture from './picture/SelectablePicture';

export default function BoardLicences({ customStyle, appList, isBackOffice, onAppListIdsUpdate }) {
  const selectApp = (selected, id) => {
    if (!selected) {
      onAppListIdsUpdate((prevItem) => [...prevItem, id]);
    } else {
      onAppListIdsUpdate((prevItem) => prevItem.filter((appId) => appId !== id));
    }
  };
  return (
    <Box style={customStyle}>
      <Box sx={styleLicencesCard}>
        {appList.map(({ name, link, urlId, id }) => (
          <SelectablePicture
            key={id}
            name={name}
            link={link}
            urlId={urlId}
            id={id}
            isBackOffice={isBackOffice}
            selectApp={selectApp}
          />
        ))}
      </Box>
    </Box>
  );
}
BoardLicences.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customStyle: PropTypes.object,
  isBackOffice: PropTypes.bool,
  appList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      urlId: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      isPublic: PropTypes.bool,
      authGroup: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
      )
    })
  ).isRequired,
  onAppListIdsUpdate: PropTypes.func
};

BoardLicences.defaultProps = {
  customStyle: { width: '100%' },
  isBackOffice: false,
  onAppListIdsUpdate: () => {}
};
