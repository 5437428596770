import React from 'react';
import PropTypes from 'prop-types';
import { Folder, Person, People } from '@mui/icons-material';
import StyledTreeItem from './StyledTreeItem';

export default function BrowseOUElem({
  elem,
  AD,
  setExpanded,
  expanded,
  setSelectedDNUser,
  isSelectDNUser,
  isForForm
}) {
  let labelIcon;

  if (elem.objectClass?.includes('user')) {
    labelIcon = Person;
  } else if (elem.objectClass?.includes('group')) {
    labelIcon = People;
  } else {
    labelIcon = Folder;
  }
  return (
    <StyledTreeItem
      labelText={elem.label}
      LabelIcon={labelIcon}
      DN={elem.DN}
      AD={AD}
      OC={elem.objectClass}
      setExpanded={setExpanded}
      expanded={expanded}
      setSelectedDNUser={setSelectedDNUser}
      isSelectDNUser={isSelectDNUser}
      isForForm={isForForm}
    >
      {elem.subDN &&
        elem.subDN.map((item) => {
          return (
            <div key={item.DN}>
              <BrowseOUElem
                elem={item}
                AD={AD}
                setExpanded={setExpanded}
                expanded={expanded}
                setSelectedDNUser={setSelectedDNUser}
                isSelectDNUser={isSelectDNUser}
                isForForm={isForForm}
              />
            </div>
          );
        })}
    </StyledTreeItem>
  );
}

BrowseOUElem.propTypes = {
  elem: PropTypes.PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
    .isRequired,
  AD: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
  ).isRequired,
  setExpanded: PropTypes.func.isRequired,
  expanded: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSelectDNUser: PropTypes.bool.isRequired,
  setSelectedDNUser: PropTypes.func,
  isForForm: PropTypes.bool.isRequired
};

BrowseOUElem.defaultProps = {
  setSelectedDNUser: () => {
    return null;
  }
};
