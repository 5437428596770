import { Box, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import PropTypes from 'prop-types';

export default function ClassicField({ keyName, value, setModifiedItem, labelName, isError }) {
  return (
    <Box sx={{ marginBottom: '10px' }}>
      <InputLabel htmlFor="input">{labelName || keyName}</InputLabel>
      <TextField
        required
        data-testid={`classicField${keyName}`}
        id={keyName}
        name="classicField"
        value={value}
        fullWidth
        onChange={(e) => {
          setModifiedItem(e.target.value, keyName);
        }}
        error={isError}
        autoFocus
      />
    </Box>
  );
}

ClassicField.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  setModifiedItem: PropTypes.func,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  labelName: PropTypes.string
};

ClassicField.defaultProps = {
  keyName: '',
  value: '',
  setModifiedItem: () => {},
  isError: false,
  labelName: ''
};
