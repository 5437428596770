import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

function PublicRoute({ isLogin, isBackOffice }) {
  if (isBackOffice && isLogin) {
    return <Navigate to="/providers" />;
  }
  return isLogin ? <Navigate to="/home" /> : <Outlet />;
}

PublicRoute.propTypes = {
  isLogin: PropTypes.bool,
  isBackOffice: PropTypes.bool
};

PublicRoute.defaultProps = {
  isLogin: false,
  isBackOffice: false
};

export default PublicRoute;
