import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Checkbox, Grid, Chip, Link, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { Link as RouterLink } from 'react-router-dom';
import {
  styleTypographyAccountItemListMobileUsers,
  styleMobileListUsers,
  styleMobileTopItemListUsers,
  styleMobileBotItemListUsers,
  styleMobileItemListUsers,
  styleChipStatus,
  styleTypographyNameItemListMobileUsers,
  styleTypographyMoreInfoLinkItemListMobileUsers,
  styleTypographyMoreInfoItemListMobileUsers
} from '../../assets/style/views/UserPages';
import LoadingWrapper from '../layout/LoadingWrapper';
import identifier from '../../utils/keySelector';
import { arrayWithoutObject, isObjectInArray } from '../../utils/array';

export default function DataListCollapse({
  rows,
  page,
  rowsPerPage,
  optionsKeys,
  setSelectedRows,
  showSearchBarMobile,
  isDisplayable,
  isDisableCheckBox,
  tableSelectedRows,
  isPartialData,
  linkOptions
}) {
  const startItemsDisplay = isPartialData ? 0 : page * rowsPerPage;
  const endItemsDisplay = isPartialData ? rows.length : page * rowsPerPage + rowsPerPage;

  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" sx={styleMobileListUsers(showSearchBarMobile)}>
      <LoadingWrapper isChildDisplayable={isDisplayable}>
        {rows.slice(startItemsDisplay, endItemsDisplay).map((row) => (
          <Grid
            container
            key={row[optionsKeys?.titleNameId]}
            item
            sx={styleMobileItemListUsers}
            xs={11}
          >
            <Grid container sx={styleMobileTopItemListUsers} justifyContent="space-between">
              {!isDisableCheckBox ? (
                <Grid item xs={2}>
                  <Checkbox
                    sx={{
                      left: '4px',
                      bottom: '1px',
                      gridColumn: '1',
                      '&.Mui-checked': { color: theme.palette.colorMain }
                    }}
                    checked={tableSelectedRows && isObjectInArray(tableSelectedRows, row)}
                    onChange={() => {
                      if (!isObjectInArray(tableSelectedRows, row)) {
                        setSelectedRows([...tableSelectedRows, row]);
                      } else {
                        const newSelectedRows = arrayWithoutObject(tableSelectedRows, row);
                        setSelectedRows(newSelectedRows);
                      }
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item xs={7}>
                <Typography
                  sx={{
                    ...styleTypographyNameItemListMobileUsers,
                    padding: isDisableCheckBox ? '10px' : '0',
                    overflow: 'hidden',
                    width: '90%',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {row[optionsKeys?.titleNameId] ? `${row[optionsKeys.titleNameId]} - ` : null}
                  {row[optionsKeys.titleNameKey]}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Link
                  sx={styleTypographyMoreInfoLinkItemListMobileUsers}
                  to={
                    linkOptions
                      ? `${row[linkOptions.linkTo1]}+${row[linkOptions.linkTo2]}`
                      : identifier(row)
                  }
                  component={RouterLink}
                  underline="none"
                >
                  <Typography sx={styleTypographyMoreInfoItemListMobileUsers}>
                    {t('seeMore')}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container sx={styleMobileBotItemListUsers}>
              <Grid item xs={9}>
                <Typography sx={styleTypographyAccountItemListMobileUsers}>
                  {row[optionsKeys.secondaryTitleKey]}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                {row[optionsKeys.statusKey] !== undefined && (
                  <Chip
                    icon={
                      <CircleRoundedIcon
                        fontSize="small"
                        sx={{ marginLeft: '6px', height: '8px' }}
                      />
                    }
                    label={!row[optionsKeys.statusKey] ? `${t('notActive')}` : `${t('active')}`}
                    color={!row[optionsKeys.statusKey] ? 'error' : 'success'}
                    sx={styleChipStatus(theme, row[optionsKeys.statusKey])}
                  />
                )}
                {optionsKeys.customStatus ? <optionsKeys.customStatus row={row} /> : null}
              </Grid>
              {optionsKeys?.otherTitleKey
                ? optionsKeys?.otherTitleKey.map((otherTitleKey) => {
                    return (
                      <Grid item xs={12}>
                        <Typography sx={styleTypographyAccountItemListMobileUsers}>
                          {row[otherTitleKey]}
                        </Typography>
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </Grid>
        ))}
      </LoadingWrapper>
    </Grid>
  );
}

DataListCollapse.defaultProps = {
  showSearchBarMobile: false,
  optionsKeys: {},
  isDisableCheckBox: false,
  tableSelectedRows: [],
  isPartialData: false,
  linkOptions: null
};

DataListCollapse.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired
        )
      ])
    )
  ).isRequired,
  optionsKeys: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.func, PropTypes.array])
  ),

  page: PropTypes.number.isRequired,
  showSearchBarMobile: PropTypes.bool,
  rowsPerPage: PropTypes.number.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  isDisplayable: PropTypes.bool.isRequired,
  isDisableCheckBox: PropTypes.bool,
  tableSelectedRows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string)
      ])
    )
  ),
  isPartialData: PropTypes.bool,
  linkOptions: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string]))
};
