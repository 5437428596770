import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Field from './EditItem/Field';
import CustomizedButton from '../../buttons/CustomizedButton';

export default function EditItem({
  selectedItemName,
  fieldList,
  setModifiedItem,
  handleDeletePopup,
  file,
  setFile
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Grid
      item
      sx={{
        maxHeight: 'calc(100vh - 169px)',
        overflowY: 'auto',
        width: '100%',
        padding: '50px'
      }}
    >
      {selectedItemName ? (
        <form>
          <Grid item>
            <Box
              sx={{
                paddingBottom: '30px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                sx={{
                  fontSize: '30px',
                  fontWeight: 'bold',
                  color: theme.palette.colorMain
                }}
                id="input"
              >
                {selectedItemName}
              </Typography>

              <CustomizedButton
                variant="contained"
                datatestid="deleteItem"
                onClick={handleDeletePopup}
                text={t('delete')}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box>
              {fieldList.map(({ keyName, value, type }) => {
                return (
                  <Field
                    key={`${keyName}${selectedItemName}`}
                    keyName={keyName}
                    value={value}
                    setModifiedItem={setModifiedItem}
                    type={type}
                    file={file}
                    setFile={setFile}
                  />
                );
              })}
            </Box>
          </Grid>
        </form>
      ) : (
        <Typography sx={{ fontSize: '25px', fontWeight: 'bold' }}>
          {t('selectItemToDisplay')}
        </Typography>
      )}
    </Grid>
  );
}

EditItem.propTypes = {
  selectedItemName: PropTypes.string,
  fieldList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
        PropTypes.bool
      ]),
      type: PropTypes.string
    })
  ),
  setModifiedItem: PropTypes.func,
  handleDeletePopup: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFile: PropTypes.func
};

EditItem.defaultProps = {
  selectedItemName: null,
  fieldList: [],
  setModifiedItem: () => {},
  handleDeletePopup: () => {},
  file: null,
  setFile: () => {}
};
