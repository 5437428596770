import { Box, Grid, InputLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CustomizedButton from '../../../../components/buttons/CustomizedButton';
import { addAzureGroup } from '../../../../redux/slices/AzureGroups';
import { styleTypographyName } from './PopupDeleteAzureGroup';
import StyledSwitch from '../../../../components/buttons/StyledSwitch';

function PopupAddAzureGroup({ openPop, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [azureMicrosoftGroupId, setAzureMicrosoftGroupId] = useState('');
  const [isIntune, setIsIntune] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  const closePopup = () => {
    onClose();
  };

  const handleClickClosePopup = () => closePopup();

  const dispatchAddAzureGroup = async () => {
    await dispatch(addAzureGroup({ name, azureMicrosoftGroupId, isIntune, isDefault }));
    closePopup();
  };

  const styledBooleanField = {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  return (
    <Dialog open={openPop} onClose={onClose}>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          textAlign="center"
          sx={{ ...styleTypographyName, fontWeight: 800, fontSize: 18 }}
        >
          {t('createAzureGroup')}
        </DialogContentText>
      </DialogContent>
      <Grid item xs={8} sx={{ padding: '0px 16px 16px 16px' }}>
        <Box sx={{ marginBottom: 4 }}>
          <InputLabel>{t('name')}</InputLabel>
          <TextField
            id="input"
            data-testid="CreateAzureGroupName"
            sx={{ width: '100%', height: '100%' }}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <Box sx={{ marginBottom: 4 }}>
          <InputLabel>{t('azureMicrosoftGroupId')}</InputLabel>
          <TextField
            id="input"
            sx={{ width: '100%', height: '100%' }}
            data-testid="CreateAzureGroupId"
            onChange={(e) => setAzureMicrosoftGroupId(e.target.value)}
          />
        </Box>
        <Box sx={{ ...styledBooleanField, marginBottom: 4 }}>
          <InputLabel>{t('isDefault')}</InputLabel>
          <StyledSwitch
            data-testid="CreateAzureGroupIsIntune"
            onClick={() => setIsDefault((elem) => !elem)}
            checked={isDefault}
          />
        </Box>
        <Box sx={styledBooleanField}>
          <InputLabel>{t('isIntune')}</InputLabel>
          <StyledSwitch
            data-testid="CreateAzureGroupIsDefault"
            onClick={() => setIsIntune((elem) => !elem)}
            checked={isIntune}
          />
        </Box>
      </Grid>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <CustomizedButton
          variant="contained"
          text={t('cancel')}
          datatestid="cancelAddAzureGroup"
          onClick={() => handleClickClosePopup()}
        />
        <CustomizedButton
          variant="contained"
          text={t('confirm')}
          datatestid="confirmAddAzureGroup"
          onClick={() => dispatchAddAzureGroup()}
        />
      </DialogActions>
    </Dialog>
  );
}

export default PopupAddAzureGroup;

PopupAddAzureGroup.propTypes = {
  openPop: PropTypes.bool,
  onClose: PropTypes.func
};

PopupAddAzureGroup.defaultProps = {
  openPop: false,
  onClose: () => {}
};
