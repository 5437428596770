import { PublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk } from '@reduxjs/toolkit';
import UsersAdminService from '../../admin/UsersAdminService';
import AuthService from '../AuthService';

export const loginAzure = createAsyncThunk('users/microsoft/login', async () => {
  const { azureFrontAuth } = (await AuthService.getAzureFrontAuth()).data;
  const { clientId, redirectUri, tenanId, customScope } = JSON.parse(azureFrontAuth);
  const msalConfig = {
    auth: {
      clientId,
      redirectUri,
      tenanId
    }
  };
  const msalInstance = new PublicClientApplication(msalConfig);
  const loginRequest = {
    scopes: ['profile', customScope],
    prompt: 'select_account'
  };
  const loginResponse = await msalInstance.loginPopup(loginRequest);
  const response = await AuthService.loginAzure({
    token: loginResponse.accessToken
  });
  return response.data;
});

export const azurePrivilegeUp = createAsyncThunk('users/azure/privilege/up', async () => {
  const { azureFrontAuth } = (await AuthService.getAzureFrontAuth()).data;
  const { clientId, redirectUri, tenanId, customScope } = JSON.parse(azureFrontAuth);
  const msalConfig = {
    auth: {
      clientId,
      redirectUri,
      tenanId
    }
  };
  const msalInstance = new PublicClientApplication(msalConfig);
  const loginRequestForceReLogin = {
    scopes: ['profile', customScope],
    prompt: 'login'
  };
  const { accessToken } = await msalInstance.loginPopup(loginRequestForceReLogin);
  return accessToken;
});

export const azurePrivilegeDown = createAsyncThunk('users/azure/privilege/down', async () => {
  const response = await UsersAdminService.azurePrivilegeDown();
  return response.data;
});
