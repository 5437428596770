export const getUserLang = () => {
  const currentLang = localStorage.getItem('Lang');
  if (currentLang === 'en') {
    return 'en-EN';
  }
  return 'fr-FR';
};

const lang = getUserLang();

const dateOption = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timeZone: process.env.REACT_APP_TIMEZONE
};

const timeOption = { hour: 'numeric', minute: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE };

export const newDateUTC = (date1) => {
  const dateParam = new Date(date1);
  const dateParamNowUtc = Date.UTC(
    dateParam.getUTCFullYear(),
    dateParam.getUTCMonth(),
    dateParam.getUTCDate(),
    dateParam.getUTCHours(),
    dateParam.getUTCMinutes(),
    dateParam.getUTCSeconds()
  );
  return dateParamNowUtc;
};

export const isNoValue = (value) => {
  if (!value) return true;
  return false;
};

export const getCurrentFormattedDate = () => {
  const utcDate = new Date();
  const parisTime = new Date(utcDate.getTime());
  const formattedDate = parisTime.toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'Europe/Paris'
  });

  return formattedDate.toString();
};
export const getLocalTimeFormatted = (date) => {
  const dateUTC = newDateUTC(date);
  const time = new Date(dateUTC).toLocaleString(lang, timeOption);
  return time;
};

export const getToday = () => {
  const currentTime = newDateUTC(new Date());
  return currentTime;
};

export const getYesterday = () => {
  const currentTime = newDateUTC(new Date());
  const currentDate = new Date(currentTime);
  const yesterdayTime = currentDate.setDate(currentDate.getDate() - 1);
  return yesterdayTime;
};

export const isSameDay = (time1, time2) => {
  const date1 = new Date(time1).toLocaleString(lang, dateOption);
  const date2 = new Date(time2).toLocaleString(lang, dateOption);
  return date1 === date2;
};

export const isMoreThanOneHour = (time1, time2) => {
  const oneHourTimeValue = 1000 * 60 * 60;
  return time2 - time1 > oneHourTimeValue;
};

export const getDateTimeFormatted = (date) => {
  const dateUtc = newDateUTC(date);
  const dateFormatted = new Date(dateUtc).toLocaleString(lang, dateOption);
  const timeFormatted = new Date(dateUtc).toLocaleString(lang, timeOption);
  return `${dateFormatted} ${timeFormatted}`;
};
