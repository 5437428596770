export const styleButton = (theme) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'block',
  textOverflow: 'ellipsis',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontSize: { xs: '13px', sm: '13px', md: '13px' },
  borderRadius: '4px',
  textDecoration: 'none',
  width: { xs: '160px', sm: '300px', md: '300px' },
  height: '32px',
  margin: '10px 5px 0px 0px',
  color: theme.palette.colorMainLight,
  backgroundColor: theme.palette.colorMainSelected,
  transition: 'all 0.5s',
  '&:hover': {
    background: theme.palette.colorMainSelected,
    transform: 'scale(0.95, 0.95)'
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    background: theme.palette.colorMain
  }
});

export const styleFlexWrap = {
  justifyContent: { sm: 'flex-start', md: 'space-around' },
  flexDirection: { xs: 'column', sm: 'column', md: 'row' }
};
