export const styleContainer = {
  flex: '2',
  minWidth: { md: '300px' }
};

export const styleTitleWrapper = {
  height: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const styleTitle = {
  fontSize: '24px',
  fontWeight: 'bold'
};

export const styleTextfieldWrapper = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: theme.palette.colorSoftBlack
});

export const styleTextfield = {
  marginRight: '0, 10px'
};

export const stylePopupContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
};

export const stylePopupNameWrapper = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: '3',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const stylePopupInfoWrapper = {
  flex: '2',
  overflow: 'hidden',
  marginLeft: '10px'
};

export const stylePopupName = {
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const stylePopupInfo = {
  fontSize: '13px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const stylePopupInfoIcon = {
  fontSize: '13px',
  marginRight: '5px'
};

export const styleFieldIcon = {
  fontSize: '25px'
};

export const styleAutoCompleteWrapper = (theme) => ({
  backgroundColor: theme.palette.colorMainLight,
  border: `1px solid ${theme.palette.colorGray}`,
  borderRadius: '16px',
  marginBottom: '10px',
  width: { md: '345px' },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
});
