import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { styleList } from '../../assets/style/components/SMS/ChatList';
import {
  setSelectedConversation,
  setIsTyping,
  updateReadTimeConversationListById,
  selectPickedConversation,
  findConversations,
  findOneConversation,
  selectConversationsList
} from '../../redux/slices/Messages';
import ChatListItem from './ChatListItem';

export default function ChatList({ setIsChatOpen }) {
  const dispatch = useDispatch();

  const conversationsList = useSelector(selectConversationsList);
  const selectedConversation = useSelector(selectPickedConversation);
  useEffect(() => {
    dispatch(findConversations());
  }, []);

  const handleSelectedConversation = (conversation) => {
    const { id, name } = conversation.phoneNumber;
    dispatch(setIsTyping(false));
    dispatch(setSelectedConversation({ phoneNumberId: id, phoneNumber: name }));
    dispatch(updateReadTimeConversationListById(id));
    dispatch(findOneConversation(id, name));
    setIsChatOpen(true);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <List sx={styleList}>
        {conversationsList.map((conversation, index) => (
          <ChatListItem
            key={JSON.stringify(index)}
            index={index}
            conversation={conversation}
            selectedConversation={selectedConversation}
            handleSelectedConversation={handleSelectedConversation}
          />
        ))}
      </List>
    </Box>
  );
}

ChatList.propTypes = {
  setIsChatOpen: PropTypes.func.isRequired
};
