import socket from '../../socket/socket';

const BoondResourcesService = {
  findBoondResources() {
    return socket.emit('boond-resources:find');
  },
  refreshBoondResources() {
    return socket.emit('boond-resources:refresh', 'true');
  }
};

export default BoondResourcesService;
