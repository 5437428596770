import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import {
  getLicensesAzure,
  resetAzureLicenseState,
  selectAzureLicenses,
  selectLicensesIsLoading,
  selectIsTableDisplayable
} from '../../../redux/slices/AzureLicenses';
import errorRedirection from '../../../utils/errorRedirection';
import { formatColumnsForTable } from '../../../components/datalist/ComponentsInTable';
import ItemsList from '../ItemsList';

export default function AzureLicenses() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const azureLicenses = useSelector(selectAzureLicenses(''));
  const LicensesIsLoading = useSelector(selectLicensesIsLoading);
  const isTableDisplayable = useSelector(selectIsTableDisplayable);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);
  const navigate = useNavigate();

  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map(
      (rowIndex) => azureLicenses[rowIndex === null ? 0 : rowIndex]?.id
    );
    setTableSelectedRows(tempRows);
  };

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(() => value);
    setPage(0);
  };
  const handleRefresh = async () => {
    setPage(0);
    resetSelectedRows();
  };

  const columns = [
    'displayName',
    'prepaidUnitsEnable',
    'consumedUnits',
    'prepaidUnitsAvailable',
    'prepaidUnitsWarning'
  ];

  const columnOptions = [
    { name: 'displayName', display: true, filter: true },
    { name: 'prepaidUnitsEnable', display: true, filter: true },
    { name: 'consumedUnits', display: true, filter: true },
    { name: 'prepaidUnitsAvailable', display: true, filter: true },
    { name: 'prepaidUnitsWarning', display: true, filter: true }
  ];

  const optionsKeys = {
    titleNameKey: 'displayName',
    secondaryTitleKey: 'prepaidUnitsEnable',
    statusKey: 'consumedUnits'
  };

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const formatedColumns = formatColumnsForTable(columns, azureLicenses, columnOptions, t);

  const dispatchGetLicensesAzure = async () => {
    try {
      await dispatch(getLicensesAzure()).unwrap();
    } catch (error) {
      errorRedirection(error.code, navigate);
    }
  };

  useEffect(() => {
    dispatchGetLicensesAzure();
    return function cleanup() {
      dispatch(resetAzureLicenseState());
    };
  }, [dispatch]);

  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    buttons: [],
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: azureLicenses
    },

    loading: {
      itemsListIsLoading: LicensesIsLoading,
      isTableDisplayable
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date: null,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('azureLicenses'),
      bigTitleText: t('licensesAzureAd'),
      selectedItemsText: t('selectedLicences'),
      tagId: 'AzureLicenses'
    },
    isDisableCheckBox: true,
    hideRefresh: true
  };

  return (
    <Grid>
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
