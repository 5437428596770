import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import TRANSLATIONS_EN from './langages/en.json';
import TRANSLATIONS_FR from './langages/fr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      fr: {
        translation: TRANSLATIONS_FR
      }
    }
  });
