import { Box, InputLabel } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import Field from '../Field';

export default function emptyObjectField({ keyName, setModifiedItem, childFieldList }) {
  return (
    <Box>
      <InputLabel htmlFor="input">{keyName}</InputLabel>
      <Box
        sx={(theme) => ({
          marginBottom: '15px',
          border: `3px solid ${theme.palette.colorMainLightHover}`,
          padding: '5px',
          borderRadius: '8px'
        })}
      >
        {childFieldList.map((objectKey) => {
          const objectChildKeyName = `${keyName}-${objectKey.keyName}`;

          return (
            <Field
              key={objectChildKeyName}
              keyName={objectChildKeyName}
              value={objectKey.value}
              setModifiedItem={setModifiedItem}
              type={objectKey.type}
              labelName={objectKey.required ? `${objectKey.keyName}*` : objectKey.keyName}
              sxedit={{ marginBottom: '15px' }}
            />
          );
        })}
      </Box>
    </Box>
  );
}

emptyObjectField.propTypes = {
  keyName: PropTypes.string,
  setModifiedItem: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  childFieldList: PropTypes.array
};

emptyObjectField.defaultProps = {
  keyName: '',
  setModifiedItem: () => {},
  childFieldList: []
};

// oneOfType([PropTypes.bool, PropTypes.func])
