import { Box, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import PropTypes from 'prop-types';

export default function StaticTextField({ keyName, value }) {
  return (
    <Box sx={{ marginBottom: '25px' }}>
      <InputLabel htmlFor="input">{keyName}</InputLabel>
      <TextField
        disabled
        required
        id={keyName}
        autoComplete="azureGroup"
        value={value}
        fullWidth
        autoFocus
      />
    </Box>
  );
}

StaticTextField.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool])
};

StaticTextField.defaultProps = {
  keyName: '',
  value: ''
};
