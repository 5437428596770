export const isObjectInArray = (array, object) => {
  return array.some(
    (el) =>
      !Object.keys(el)
        .map((key) => el[key] === object[key])
        .includes(false)
  );
};

export const arrayWithoutObject = (array, object) => {
  return [...array].filter((el) =>
    Object.keys(el)
      .map((key) => el[key] === object[key])
      .includes(false)
  );
};
