const identifier = (row) => {
  if (row.idDetails) return row.idDetails;
  if (row.id) return row.id;
  if (row.key) return row.key;
  if (row.userPrincipalNameSynchro) return row.userPrincipalNameSynchro;
  if (row.distinguishedName) return row.distinguishedName;
  return row.name;
};

export default identifier;
