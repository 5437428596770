import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import {
  selectPermissionHistoryColumns,
  selectPermission,
  selectIsLoading,
  selectIsTableLoaded,
  getPermissionsHistory,
  removePermissionsHistory,
  addPermissionsHistory,
  resetPermissionHistory
} from '../../../../redux/slices/PermissionsHistory';
import { formatColumnsForTable } from '../../../../components/datalist/ComponentsInTable';
import ItemsList from '../../ItemsList';
import hasPermissions from '../../../../utils/Permissions/permissions';
import { selectPermissions } from '../../../../redux/slices/userConnected';
import { styleActionIconMobileUsersAzure } from '../../../../assets/style/views/UserPages';
import PopUpHistory from './PopUpHistory';

export default function PermissionInHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const permissions = useSelector(selectPermissions);

  const permissionColumn = useSelector(selectPermissionHistoryColumns);
  const allPermissions = useSelector(selectPermission(''));
  const isPermissionsLoading = useSelector(selectIsLoading);
  const updatedTime = '00/00/0000'; /* useSelector(selectDeviceUpdatedAt) */
  const isTableLoaded = useSelector(selectIsTableLoaded);
  const [selectedIndexRows, setSelectedIndexRows] = useState([]);

  const [addPermissionInHistoryPopUp, setAddPermissionInHistoryPopUp] = useState(false);
  const [removePermissionInHistoryPopUp, setRemovePermissionInHistoryPopUp] = useState(false);

  const resetSelectedRows = () => {
    setTableSelectedRows([]);
    setSelectedIndexRows([]);
  };

  const permissionInHistory = allPermissions.filter((item) => item.shouldRecord === true);
  const permissionNotInHistory = allPermissions.filter((item) => item.shouldRecord === false);

  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];

    setSelectedIndexRows(copyRows);
    const tempRows = copyRows.map(
      (rowIndex) => allPermissions[rowIndex === null ? 0 : rowIndex]?.id
    );
    setTableSelectedRows(tempRows);
  };

  useEffect(() => {
    dispatch(getPermissionsHistory());
    return function cleanup() {
      dispatch(resetPermissionHistory());
    };
  }, [dispatch]);

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(() => value);
    setPage(0);
  };
  const handleRefresh = async () => {
    setPage(0);
    resetSelectedRows();
  };

  const handleAddPermissionInHistory = async (selectedPermissions) => {
    dispatch(addPermissionsHistory(selectedPermissions));
  };

  const handleRemovePermission = async (selectedPermissions) => {
    dispatch(removePermissionsHistory(selectedPermissions));
  };

  const optionsKeys = {
    titleNameKey: 'codename',
    secondaryTitleKey: 'desc',
    statusKey: 'shouldRecord'
  };

  const buttons = [
    {
      disabled: false,
      datatestid: 'AddPermissionInHistory',
      isDisplayable: hasPermissions(permissions, 'BACK_OFFICE'),
      onClick: () => setAddPermissionInHistoryPopUp(true),
      text: t('addPermissionInHistory'),
      icon: <PostAddIcon sx={styleActionIconMobileUsersAzure} />
    },
    {
      disabled: false,
      datatestid: 'RemovePermissionInHistory',
      isDisplayable: hasPermissions(permissions, 'BACK_OFFICE'),
      onClick: () => {
        setRemovePermissionInHistoryPopUp(true);
      },
      text: t('removePermissionInHistory'),
      icon: <PlaylistRemoveIcon sx={styleActionIconMobileUsersAzure} />
    }
  ];

  const searchBar = {
    isDisplayable: true,
    disabled: false
  };

  const columnOptions = [
    { name: 'id', display: false },
    {
      name: 'codename',
      display: true,
      filter: true
    },
    {
      name: 'desc',
      display: true,
      filter: true
    },
    { name: 'shouldRecord', type: 'CheckedIconInTable', display: true }
  ];

  const formatedColumns = formatColumnsForTable(permissionColumn, allPermissions, columnOptions, t);
  const desktopConfig = {
    actions: {
      handleRefresh,
      handleSearchTerm
    },

    buttons,
    searchBar,

    data: {
      tableSelectedRows,
      setTableSelectedRows,
      setTableSelectedRowsRealData,
      items: allPermissions
    },

    loading: {
      itemsListIsLoading: isPermissionsLoading,
      isTableDisplayable: isTableLoaded
    },

    tables: {
      optionsKeys,
      formatedColumns,
      date: updatedTime,
      totalItems,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      selectedIndexRows,
      setTotalItems
    },

    search: {
      searchTerm,
      setSearchTerm
    },

    text: {
      titleText: t('managePermissionHistory'),
      bigTitleText: t('manageHistory'),
      selectedItemsText: t('selectedPermission'),
      tagId: 'PermissionHistory'
    },

    isDisableCheckBox: true,
    hideRefresh: true,
    searchBarPlaceOlder: t('searchPermission')
  };

  return (
    <Grid>
      <PopUpHistory
        title={t('addPermissionInHistory')}
        action={handleAddPermissionInHistory}
        items={permissionNotInHistory}
        open={addPermissionInHistoryPopUp}
        handleClose={() => setAddPermissionInHistoryPopUp(false)}
      />
      <PopUpHistory
        title={t('removePermissionInHistory')}
        action={handleRemovePermission}
        items={permissionInHistory}
        open={removePermissionInHistoryPopUp}
        handleClose={() => setRemovePermissionInHistoryPopUp(false)}
      />
      <ItemsList config={desktopConfig} />
    </Grid>
  );
}
