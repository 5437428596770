export const styleCloseButton = (theme) => ({
  color: theme.palette.colorMainSelected,
  position: 'absolute',
  right: 8,
  top: 8
});

export const styleDialogText = {
  margin: 0,
  marginRight: '35px',
  padding: 2
};

export const styleGridPadding = {
  padding: '5px 15px 15px 15px'
};

export const styleTextPadding = {
  padding: '5px 0px 5px 5px'
};

export const styleColorTitle = (theme) => ({
  color: theme.palette.colorSoftBlack
});
