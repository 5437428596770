import { Link, ListItemText, Menu, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

export default function CloseMenu({
  item,
  handleClose,
  currentPathLocation,
  isOpenSideBar,
  openReductedSubMenuId,
  anchorEl
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={openReductedSubMenuId === item.id}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      sx={{
        '.MuiMenu-paper': {
          background: theme.palette.colorMainGradient
        },
        '.MuiMenu-list': {
          padding: '1px'
        }
      }}
    >
      {item.child.map((child) => (
        <Link
          key={child.id}
          component={RouterLink}
          to={child.path}
          datatest-id={child.datatestid}
          sx={{
            textDecoration: 'none',
            color: theme.palette.colorMainLight
          }}
        >
          <MenuItem
            sx={{
              borderRadius: '0px',
              marginTop: '0px',
              display: isOpenSideBar ? 'block' : 'flex',
              justifyContent: isOpenSideBar ? undefined : 'center',
              '&:hover': {
                background: theme.palette.colorMainHover
              },
              '&.Mui-selected': {
                backgroundColor: theme.palette.colorMainSelected
              }
            }}
            onClick={handleClose}
            selected={child.path === currentPathLocation}
          >
            <ListItemText primary={t(child.id)} sx={{ textOverflow: 'ellipsis' }} />
          </MenuItem>
        </Link>
      ))}
    </Menu>
  );
}

CloseMenu.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string])))
    ])
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  currentPathLocation: PropTypes.string.isRequired,
  isOpenSideBar: PropTypes.bool.isRequired,
  openReductedSubMenuId: PropTypes.string.isRequired,
  anchorEl: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.func
    ])
  )
};

CloseMenu.defaultProps = {
  anchorEl: null
};
