import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StandardBox from '../StandardBox';
import AzureLicensesServices from '../azureLicenses/AzureLicensesServices';
import { selectedUser } from '../../redux/slices/AzureUsersList';
import AzureLicensesPopup from '../popup/AzureLicensesPopup';
import hasPermissions from '../../utils/Permissions/permissions';
import { selectPermissions } from '../../redux/slices/userConnected';

export default function UserLicensesDescription() {
  const { t } = useTranslation();

  const user = useSelector(selectedUser);
  const permissions = useSelector(selectPermissions);

  const [userLicensesList, setUserLicensesList] = useState([]);
  const [userUnassignServiceMap, setUserUnassignServiceMap] = useState(new Map());
  const [manageLicensesPopup, setManageLicensesPopup] = useState(false);
  const [isRemoveLicense, setIsRemoveLicense] = useState(false);

  const getUserLicensesList = () => {
    setUserUnassignServiceMap(new Map());
    const licensesList = user?.assignedLicenses.map((license) => {
      setUserUnassignServiceMap((map) => new Map(map.set(license.skuId, license.disabledPlans)));
      return license.skuId;
    });
    setUserLicensesList(licensesList);
  };
  useEffect(() => {
    if (user.assignedLicenses) getUserLicensesList();
  }, [user]);

  const listButton = [
    {
      hasPermissions: hasPermissions(permissions, 'SET_AZURE_LICENSES_TO_USER'),
      text: `${t('addLicenses')}`,
      datatestid: 'AddAzureLicenses',
      onClick: () => {
        setManageLicensesPopup(true);
        setIsRemoveLicense(false);
      }
    },
    {
      hasPermissions: hasPermissions(permissions, 'SET_REMOVE_AZURE_LICENSES_TO_USER'),
      disabled: userLicensesList.length === 0,
      text: `${t('removeLicenses')}`,
      datatestid: 'removeLicenses',
      onClick: () => {
        setManageLicensesPopup(true);
        setIsRemoveLicense(true);
      }
    }
  ];

  return (
    <Grid>
      <StandardBox
        title="azureLicenses"
        buttons={listButton}
        ChildComponent={
          <AzureLicensesServices
            selectedLicenses={userLicensesList}
            unassignServiceMap={userUnassignServiceMap}
            setUnassignServiceMap={setUserUnassignServiceMap}
            isAssignMode={false}
          />
        }
      />
      <AzureLicensesPopup
        popup={manageLicensesPopup}
        closePopup={() => setManageLicensesPopup(false)}
        numberOfUsers={1}
        selectedUsers={[user.id]}
        isRemove={isRemoveLicense}
        userLicensesList={userLicensesList}
      />
    </Grid>
  );
}
