import React, { useEffect, useState } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import background from '../../../assets/images/backgroundpegasusboardinverse.webp';
import BoardLicences from '../../../components/BoardLicences';
import HeaderBoard from '../../../components/layout/HeaderBoard';
import {
  styleTitle,
  styleLicenceTitle,
  styleBackground
} from '../../../assets/style/views/LoginBoard';
import AuthService from '../../../services/authentication/AuthService';
import AppListService from '../../../services/appList/AppListService';

export default function HeaderLoginBoard() {
  const { t } = useTranslation();
  const [isRedirectLogin, setIsRedirectLogin] = useState(true);
  const [appListNotConnected, setAppListNotConnected] = useState([]);
  const getAuthProviders = async () => {
    const providers = (await AuthService.getAuthProviders())?.data?.authProviders;
    if (providers?.length === 1 && providers[0] === 'AZURE_FRONT') setIsRedirectLogin(false);
  };
  const getRouteNoConnected = async () => {
    const res = await AppListService.getRouteNoConnected();
    if (res.data?.appList) {
      setAppListNotConnected(res.data.appList);
    }
  };
  useEffect(() => {
    getAuthProviders();
    getRouteNoConnected();
  }, []);
  return (
    <Box>
      <HeaderBoard isRedirectLogin={isRedirectLogin} />
      <CardMedia component="img" image={background} sx={styleBackground} />
      <Typography component="span" sx={styleTitle}>
        {t('partOne')}
        <Typography component="span" sx={styleLicenceTitle}>
          <br />
          {t('partTwo')}
        </Typography>
        <br />
        {t('partThree')}
      </Typography>
      <BoardLicences
        appList={appListNotConnected}
        customStyle={{ width: '100%', marginTop: '10px' }}
      />
    </Box>
  );
}
