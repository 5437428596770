import { Grid, Typography, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  stylePadding,
  stylePaddingRight
} from '../../assets/style/components/popup/AzureLicensesPopup';
import CustomizedButton from '../buttons/CustomizedButton';
import {
  selectAssignAzureLicenses,
  selectAzureLicensesAvailableByAppliesTo
} from '../../redux/slices/AzureLicenses';
import AzureLicensesTab from './AzureLicensesTab';

export default function AzureRemoveLicenses({ userLicensesList, removeLicense, closePopup }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [selectedLicenses, setSelectedLicenses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const azureLicenses = useSelector(
    userLicensesList.length === 0
      ? selectAzureLicensesAvailableByAppliesTo(0, 'User', 'Enabled', '')
      : selectAssignAzureLicenses(userLicensesList)
  );
  const setTableSelectedRowsRealData = (rows) => {
    const copyRows = [...rows];
    const tempRows = copyRows.map(
      (rowIndex) => azureLicenses[rowIndex === null ? 0 : rowIndex].skuId
    );
    setSelectedLicenses(tempRows);
  };

  return (
    <Grid>
      <Grid sx={stylePadding}>
        <Grid display="flex" justifyContent="flex-start" alignItems="center">
          <Typography variant="h4" sx={stylePaddingRight}>
            {t('removeLicenses')}
          </Typography>
          <Tooltip title={t('infoLicensesDisplay')} placement="bottom-start">
            <InfoOutlinedIcon />
          </Tooltip>
        </Grid>
        <Grid>
          <AzureLicensesTab
            page={page}
            setPage={setPage}
            assignLicense={removeLicense}
            setSelectedLicenses={setTableSelectedRowsRealData}
            checkboxKey="skuId"
            activeCheckBox
            azureLicenses={azureLicenses}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <Grid display="flex" justifyContent="space-around">
            <CustomizedButton
              variant="contained"
              datatestid="CancelButton"
              onClick={closePopup}
              text={t('cancel')}
            />
            <CustomizedButton
              variant="contained"
              datatestid="RemoveLicensesButton"
              onClick={() => {
                removeLicense(selectedLicenses);
              }}
              text={t('removeLicenses')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

AzureRemoveLicenses.propTypes = {
  userLicensesList: PropTypes.arrayOf(PropTypes.string),
  removeLicense: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired
};

AzureRemoveLicenses.defaultProps = {
  userLicensesList: []
};
