import React from 'react';
import { Logout } from '@mui/icons-material';
import { styleIconSize } from '../../../assets/theme/utils';
import store from '../../../redux/store';

const iconSize = 32;

function SubMenuAccountList() {
  return [
    {
      id: 'Logout',
      codename: 'LOGOUT',
      openChild: false,
      icon: <Logout sx={styleIconSize(iconSize)} />,
      path: '/',
      child: null,
      datatestid: 'SubMenuList-logout',
      onClick: () => {
        store.dispatch({ type: 'user/logout' });
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
          localStorage.removeItem('dataSession');
          localStorage.removeItem('isBackOffice');
        }
      }
    }
  ];
}

export default SubMenuAccountList;
