import { Autocomplete, Box, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import PropTypes from 'prop-types';

function deleteDoubleId(tableau) {
  const idSet = new Set();
  return tableau.filter((obj) => {
    if (idSet.has(obj.id)) {
      return false;
    }
    idSet.add(obj.id);
    return true;
  });
}
export default function AutoCompleteField({ keyName, value, setModifiedItem }) {
  return (
    <Box sx={{ marginBottom: '25px' }}>
      <InputLabel htmlFor="input">{keyName}</InputLabel>
      <Autocomplete
        multiple
        limitTags={2}
        sx={{ width: 500 }}
        id={keyName}
        getOptionLabel={({ name }) => name}
        value={value.value || ''}
        options={value.options}
        isOptionEqualToValue={(option, val) => val.id === option.id}
        onChange={(e, newValue) => {
          setModifiedItem(deleteDoubleId(newValue), keyName);
        }}
        data-testid={keyName}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="standard"
          />
        )}
      />
    </Box>
  );
}

AutoCompleteField.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool]),
  setModifiedItem: PropTypes.func
};

AutoCompleteField.defaultProps = {
  keyName: '',
  value: '',
  setModifiedItem: () => {}
};
