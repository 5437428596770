import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  licenseNotif,
  requestAllNotifsPagination,
  selectTotalItems
} from '../../../redux/slices/licenseNotification';
import LicenseCollapse from '../../../components/datalist/LicenseCollapse';
import { styleTypographyUsers, styleToolbar } from '../../../assets/style/views/UserPages';
import { findAzureUsers, resetAzureUserState } from '../../../redux/slices/AzureUsersList';

export default function LicenseNotif() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const licenseNotification = useSelector(licenseNotif);
  const totalItems = useSelector(selectTotalItems);
  const licenseNotificationSorted = licenseNotification?.map((data) => {
    return {
      ...data,
      licenseNotifList: Array.from(data.licenseNotifList)
    };
  });

  useEffect(() => {
    dispatch(requestAllNotifsPagination({ page, rowsPerPage }));
    dispatch(findAzureUsers());

    return function cleanup() {
      dispatch(resetAzureUserState());
    };
  }, [page, rowsPerPage]);

  const handleChangePage = (next, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  return (
    <Grid>
      <Typography sx={styleTypographyUsers}>{t('notification')}</Typography>

      <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {licenseNotificationSorted.map((element) => {
          return <LicenseCollapse key={`${element.id}`} licenseNotificationSorted={element} />;
        })}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="items/page"
          sx={styleToolbar}
        />
      </Grid>
    </Grid>
  );
}
