import React, { useState } from 'react';

import { Grid, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  styleRowGrid,
  styleInformationNameText,
  styleEllipsis
} from '../../assets/style/components/StandardTable';
import { getDateTimeFormatted } from '../../utils/Date/DateFunction';
import identifier from '../../utils/keySelector';

export default function Mobile({ rows, columns, refColumn }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleComponent = (component, id) => {
    if (React.isValidElement(component)) {
      return React.cloneElement(component, { onClick: () => component.props.onClick(id) });
    }
    return null;
  };

  return (
    <Grid item container justifyContent="end">
      {rows.map((row, index) => (
        <Accordion
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{ width: '100%' }}
          data-testid="UserCollapseAzureAD"
          key={row[refColumn]}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{row[refColumn]}</Typography>
          </AccordionSummary>
          <Grid display="flex" flexDirection="column">
            {columns.map((column) => (
              <Grid display="flex" sx={styleRowGrid} key={column.name}>
                <Typography sx={[styleInformationNameText]} gutterBottom>
                  {t(`${column.name}`).toUpperCase()}:&#160;
                </Typography>
                {column.type === 'date' ? (
                  <Typography>{getDateTimeFormatted(row[column.key])} </Typography>
                ) : null}
                {column.type === 'component'
                  ? handleComponent(column.component, identifier(row))
                  : null}
                {column.type === 'string' ? (
                  <Typography sx={styleEllipsis}>{row[column.key]}</Typography>
                ) : null}
              </Grid>
            ))}
          </Grid>
          <AccordionDetails />
        </Accordion>
      ))}
    </Grid>
  );
}

Mobile.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element]))
  ).isRequired,
  refColumn: PropTypes.string.isRequired
};
